import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNotification } from "context";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Box, TablePagination } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { GET_PRODUCT_VARIANT_LIST } from "services/product-service";
import { productServiceClient } from "graphql/client";

// ModuleRegistry.registerModules([ClientSideRowModelModule]);

const AgGrid = ({
  columns = [],
  readMethod = {},
  createMethod = {},
  updateMethod = {},
  deleteMethod = {},
  isGraphql = true,
  queryLoading = true,
  queryError = null,
  queryData = null,
  // page = "",
  pageSize = 20,
  gridProps = {},
  defaultColDefProp = {},
  externalGridRef = null,
  allowSearch = false,
  customGridRef = null,
}) => {
  const defaultGridRef = useRef();

  const gridRef = customGridRef || defaultGridRef;

  const { setNotification } = useNotification();
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "75vh", width: "100%" }), []);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [isPivotMode, setIsPivotMode] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);
  // const [page, setPage] = useState(0);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  //   if(callback) callback({page:newPage+1,rowsPerPage});
  // };
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  //   if(callback) callback({page:1, rowsPerPage:+event.target.value});
  // };

  const onPivotModeChanged = () => {
    setIsPivotMode(gridRef.current.api.isPivotMode());
  };

  useEffect(() => {
    console.log(...[columns, "👀 [index.js:43]: columns"].reverse());
    setColumnDefs(parseColumnDefs(columns));
  }, [columns]);

  const parseColumnDefs = (columns) => {
    return columns.map((column) => ({
      headerTooltip: column.headerName,
      ...column,
    }));
  };

  useEffect(() => {
    if (isPivotMode) {
      gridRef.current.api.createPivotChart({
        chartType: "bar",
        chartContainer: document.querySelector("#chartContainer"),
      });
    }
  }, [isPivotMode]);

  useEffect(() => {
    if (queryData && JSON.stringify(queryData) !== JSON.stringify(rowData)) {
      // Update the rowData state based on the fetched data
      setRowData(queryData); // Adjust this based on your data structure
    }
  }, [queryData]);

  const defaultColDef = {
    floatingFilter: true,
    filter: "agTextColumnFilter",
    sortable: true,
    editable: true,
    filter: true,
    flex: 1,
    minWidth: 100,
  };

  // const [fetchItems, { loading, error, data }] = useLazyQuery(GET_PRODUCT_VARIANT_LIST,{
  //   client: productServiceClient,
  //   fetchPolicy: "network-only",
  // });

  const getServerSideDatasource = (server) => {
    return {
      getRows: async (params) => {
        console.log("[Datasource] - rows requested by grid: ", params.request);
        var response = await server.getData(params.request);
        // adding delay to simulate real server call
        if (response.success) {
          // call the success callback
          params.success({
            rowData: response.rows,
            rowCount: response.lastRow,
          });
        } else {
          // inform the grid request failed
          params.fail();
        }
      },
    };
  };

  // const onGridReady = useCallback(
  //   (params) => {
  //       params.api && setGridApi(params.api);
  //     if (Object.keys(readMethod).length > 0) {
  //       if (isGraphql) {
  //         const { query, input } = readMethod;
  //         query({
  //           variables: { ...input, skip: 0, limit: 10 },
  //         });
  //       } else {
  //         // TODO: implement the REST API logic
  //       }
  //     }
  //   },
  //   [readMethod, isGraphql]
  // );

  const dataServer = {
    getData: async (request) => {
      const { startRow, endRow, filterModel, sortModel } = request;
      const pageSize = endRow - startRow;
      const skip = startRow;
      let filter = {};
      const searchCols = [];
      const searchBy = [];
      console.log(filterModel, sortModel, "FILTERMODEL");
      for (let key in filterModel) {
        // console.log(key, filterModel[key]);
        const column = columnDefs.find((colDef) => colDef.field == key);
        if (
          !allowSearch ||
          column.filter == "agSetColumnFilter" ||
          column.filter == "agDateColumnFilter" ||
          column.filter == "agNumberColumnFilter"
        ) {
          filter = {
            ...filter,
            ...(column?.getFilterObj?.(filterModel[key]) || {
              [key]: filterModel[key].filter,
            }),
          };
        } else {
          searchCols.push(key);
          searchBy.push(filterModel[key].filter);
        }
      }
      console.log(
        readMethod?.inputParser({
          startRow,
          endRow,
          filter,
          sortModel,
          searchCols,
          searchBy,
        })
      );
      const data = await readMethod?.fetchFn({
        variables: readMethod?.inputParser({
          startRow,
          endRow,
          filter,
          sortModel,
          searchCols,
          searchBy,
        }),
      });
      const parsedData = readMethod?.resultsParser?.(data);
      console.log(parsedData, "parsed data");
      return {
        success: true,
        rows: parsedData?.results || [],
        lastRow: parsedData?.total || 0, // replace with the actual total number of rows
        // rows:[],
        // lastRow: 100, // replace with the actual total number of rows
      };
    },
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    if (externalGridRef) externalGridRef.current = params.api;
    var datasource = getServerSideDatasource(dataServer);
    if (!queryData)
      params?.api?.setGridOption("serverSideDatasource", datasource);
    // setGridColumnApi(params.columnApi);
  };

  const onGridSizeChanged = useCallback((params) => {
    // const gridWidth = document.querySelector(".ag-body-viewport").clientWidth;
    // let columnsToShow = [];
    // let columnsToHide = [];
    // let totalColsWidth = 0;
    // const allColumns = params.api.getColumns();
    // if (allColumns && allColumns.length > 0) {
    //   for (let column of allColumns) {
    //     totalColsWidth += column.getMinWidth();
    //     if (totalColsWidth > gridWidth) {
    //       columnsToHide.push(column.getColId());
    //     } else {
    //       columnsToShow.push(column.getColId());
    //     }
    //   }
    // }
    // params.api.setColumnsVisible(columnsToShow, true);
    // params.api.setColumnsVisible(columnsToHide, false);
    // window.setTimeout(() => {
    //   // params.api.sizeColumnsToFit();
    // }, 10);
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    // params.api.sizeColumnsToFit();
    // params.api.createPivotChart({
    //   chartType: "groupedColumn",
    //   chartContainer: document.querySelector("#myChart"),
    //   chartThemeOverrides: {
    //     common: {
    //       navigator: {
    //         enabled: true,
    //         height: 10,
    //       },
    //     },
    //   },
    // });
    // if (gridRef.current) {
    // gridRef.current.api.createPivotChart({
    //   chartType: 'bar',
    //   chartContainer: document.querySelector('#chartContainer'),
    // });
    // }
  }, []);

  const onRowDragEnd = useCallback(
    (event) => {
      updateMethod?.mutation({
        variables: {
          input: {
            id: event.node.data.id,
            displayOrder: +(+event.node.rowIndex + 1),
          },
        },
        // onCompleted: () => updateMethod?.refetch({ variables: { input: { page, limit: 10, skip: 0 } } }),
      });
    },
    [rowData]
  );

  return (
    <>
      <div style={containerStyle}>
        <div id="grid-wrapper" style={{ width: "100%", height: "100%" }}>
          <div style={gridStyle} className={"ag-theme-alpine"}>
            <AgGridReact
              // TODO: implement pagination logic based on the queryData length and page number
              ref={gridRef}
              rowData={queryData ? rowData : undefined}
              rowModelType={queryData ? "clientSide" : "serverSide"}
              columnDefs={columnDefs}
              onGridReady={onGridReady}
              defaultColDef={{ ...defaultColDef, ...defaultColDefProp }}
              onGridSizeChanged={onGridSizeChanged}
              onFirstDataRendered={onFirstDataRendered}
              rowDragMultiRow={true}
              rowDragManaged={true}
              rowSelection={"multiple"}
              animateRows={true}
              onRowDragEnd={onRowDragEnd}
              alwaysShowHorizontalScroll={true}
              suppressHorizontalScroll={false}
              suppressAutoSize={true} // Globally disable auto-sizing
              pivotMode={false}
              onColumnPivotModeChanged={onPivotModeChanged}
              rowGroupPanelShow="onlyWhenGrouping"
              pivotPanelShow="onlyWhenPivoting"
              pagination={true}
              paginationPageSizeSelector={[10, 20, 50, 100]}
              paginationPageSize={rowsPerPage}
              cacheBlockSize={rowsPerPage}
              onPaginationChanged={(params) => {
                if (params.newPageSize)
                  setRowsPerPage(gridApi?.paginationGetPageSize());
              }}
              {...gridProps}
            />
          </div>
          <div
            id="chartContainer"
            style={{
              height: 400,
              width: "100%",
              display: isPivotMode ? "block" : "none",
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default AgGrid;
