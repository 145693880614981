import { useLazyQuery, useQuery } from '@apollo/client';
import { Autocomplete, Grid, Box, TextField, Typography, Button } from '@mui/material';
import { DateTimePicker, LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers'; // Replace DatePicker with DateTimePicker
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; // or another adapter like AdapterDateFns
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { Add as AddIcon} from "@mui/icons-material";
import { dashboardServiceClient } from 'graphql/client';
import React, { useEffect, useState,createContext } from 'react';
import { BRANDS_LIST } from 'services/brand-service';
import { GET_STORES_LIST_NEW } from 'services/store-service';
import DashboardLayout from 'ui/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'ui/Navbars/DashboardNavbar';
import ReportTabs from './reportTabs';
import { GET_EVENT_REPORT } from 'services/report-service';
import { GET_SALES_REPORT } from "services/report-service";
import { GET_RETURN_AND_REFUND_REPORT } from "services/report-service";
import { GET_STOCK_REPORT } from "services/report-service";
import { GET_GMV_REPORT } from 'services/report-service';
import { LIST_BRAND_STORE } from 'services/brand-service';
import dayjs from 'dayjs';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import StatsCards from './statsCards';
import { reportServiceClient } from 'graphql/client';
import { useNotification } from "context";
import MDButton from 'components/MDButton';
import { GET_FOOTFALL_REPORT } from 'services/report-service';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import * as XLSX from "xlsx";

dayjs.extend(utc);
dayjs.extend(timezone);

export const ReportsFilter = createContext()
function Reports() {
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [selectedStore, setSelectedStore] = useState(null);
    const [startDate, setStartDate] = useState(dayjs().subtract(1, 'week'));
    const [endDate, setEndDate] = useState(dayjs());    
    const [tabValue, setTabValue] = useState(5)
    const [startDateError, setStartDateError] = useState(null);
    const [endDateError, setEndDateError] = useState(null);
    const [key,setKey]= useState(new Date())
    const { setNotification } = useNotification();
    const [reportType,setReportType]= useState("")

    const user = localStorage.getItem("userDetails") && localStorage.getItem("userDetails") !== "undefined" ? JSON.parse(localStorage.getItem("userDetails")) : {};
    const { loading, data, error, refetch } = useQuery(BRANDS_LIST, {
        client: dashboardServiceClient,
        variables: {
            take:100,
            skip: 0,
            filter:
                user && user?.role === "admin"
                    ? {
                        isDeleted: false,
                    }
                    : {
                        id: user?.userId,
                    },
        },
    });
    const {
        loading: storeLoading,
        error: storeError,
        data: storeList,
      } = useQuery(LIST_BRAND_STORE, {
        client: dashboardServiceClient,
        variables: { listBrandStoreFilter: { take: 100, skip: 0,filter:{status: ["active", "upcoming"],brand:{id:selectedBrand}} } },
        onError: () => {
            setSelectedStore(null); // Clear store selection on error
        },
      });
      const brandStoreList = storeList?.brandStores?.results?.map(item => item?.store)
      const handleDownloadTemplate = async () => {
        const token = localStorage.getItem("token");
    
        // URL construction based on environment and type of review
         let url;
          url = process.env.REACT_APP_NODE_ENV === "development"
            ? `${process.env.REACT_APP_API_BASE_URL}/report-service/export`
            : `/report-service/export`;
    
        try {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              "reportType": reportType,
              "filter": {
                  "endDate": endDate,
                  "startDate": startDate,
                  "brandId": selectedBrand,
                  "storeId": selectedStore,

              }
          }),
          
          });
          if (response.ok) {
            const blob = await response.blob();
            
            const downloadUrl = window.URL.createObjectURL(blob);
            
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = `report.xlsx`; // Use a dynamic filename based on reviewType
            
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(downloadUrl);
          } else {
            throw new Error("Failed to download template");
          }
        } catch (error) {
          console.error("Error downloading template:", error);
          setNotification({
            color: "error",
            isVisible: true,
            message: error.message || "Something went wrong",
          });
        }
      };
    
      useEffect(() => {
        if(user && user?.role !== "admin" ){
            setSelectedBrand(user?.userId);
        }
      },[user])
      const [getGmvReport, { loading: gmvLoading, data: gmvData }] = useLazyQuery(GET_GMV_REPORT, {
        client: dashboardServiceClient,
      });
      const [getSalesReport, { loading: salesLoading, data: salesData }] = useLazyQuery(GET_SALES_REPORT, {
        client: reportServiceClient,
      });
      const [getReturnRefundReport, { loading: returnLoading, data: returnData }] = useLazyQuery(GET_RETURN_AND_REFUND_REPORT, {
        client: dashboardServiceClient,
      });
      const [getStockReport, { loading: stockLoading, data: stockData }] = useLazyQuery(GET_STOCK_REPORT, {
        client: reportServiceClient,
      });
      const [getEventReport, { loading: eventLoading, data: eventData }] = useLazyQuery(GET_EVENT_REPORT, {
        client: dashboardServiceClient,
      });
      const [getFootFallReport, { loading: footfallLoading, data: footfallData }] = useLazyQuery(GET_FOOTFALL_REPORT, {
        client: dashboardServiceClient,
      });
    const handleStartDateChange = (newValue) => {
        if (newValue === null || newValue?.isAfter(dayjs())) {
            setStartDateError("Please Enter Valid Start date.");
            setStartDate(null); // Set to null if the date is invalid or cleared
        } else {
            setStartDate(dayjs(newValue).format('YYYY-MM-DDTHH:mm:ss'));
            setStartDateError(null);

            // Automatically adjust the end date to be within one month of the start date
            const newEndDate = dayjs(newValue).add(6, 'month').isAfter(dayjs())
                ? dayjs()  // If one month from startDate is in the future, set endDate to today
                : dayjs(newValue).add(6, 'month');
            setEndDate(newEndDate?.format('YYYY-MM-DDTHH:mm:ss'));
            setEndDateError(null);
            console.log(newEndDate,"end Date>>>")

        }
    };
    const handleExportToExcel = () => {

      const flatten = (obj, parent = "", res = {}) => {
        for (let key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const propName = parent ? `${parent}.${key}` : key;
            if (typeof obj[key] === "object" && obj[key] !== null) {
              if (Array.isArray(obj[key])) {
                obj[key].forEach((item, index) => {
                  flatten(item, `${propName}[${index}]`, res);
                });
              } else {
                flatten(obj[key], propName, res);
              }
            } else {
              res[propName] = obj[key];
            }
          }
        }
        return res;
      };
      
      const flattenedData = footfallDataRows.map((item) =>
        flatten(item)
      );
      const worksheet = XLSX.utils.json_to_sheet(flattenedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
      XLSX.writeFile(workbook, "data.xlsx");
    };
    const handleEndDateChange = (newValue) => {
        if (newValue === null) {
            setEndDate(null); // Set to null if the date is cleared
            setEndDateError(null);
        }
        const maxAllowedEndDate = dayjs(startDate, 'YYYY-MM-DDTHH:mm:ss').add(6, 'month'); // Maximum allowed end date is 1 month from start date
        if (dayjs(newValue).isAfter(maxAllowedEndDate) || dayjs(newValue).isAfter(dayjs()) || dayjs(newValue).isBefore(startDate)) {
            setEndDateError("End date must be within one month of the start date and cannot be a future date.");
        } else {
            setEndDate(dayjs(newValue).format('YYYY-MM-DDTHH:mm:ss'));
            setEndDateError(null);
        }
    }; 
    useEffect(() => {
        setKey(new Date())
        if (tabValue === 0) {
          getGmvReport({
            variables: {
              filter: {
                skip: 0,
                limit: 10000,
                brand: selectedBrand,
                store: selectedStore,
                dateRangeStart: startDate,
                dateRangeEnd: endDate,
              },
            },
          });
        } else if(tabValue === 1){
          setReportType("sales-report")
        } 
        else if (tabValue === 2) {
          getReturnRefundReport({
            variables: {
              filter: {
                skip: 0,
                limit: 10000,
                brand: selectedBrand,
                store: selectedStore,
                minDateRangeStart: startDate,
                maxDateRangeEnd: endDate,
              },
            },
          });
        } else if (tabValue === 3) {
          setReportType("stock-report")
        } else if (tabValue === 4) {
          getEventReport({
            variables: {
              filter: {
                skip: 0,
                limit: 10000,
                brand: selectedBrand,
                store: selectedStore,
                dateRangeStart: startDate,
                dateRangeEnd: endDate,
              },
            },
          });
        }  else if (tabValue === 6) {
          const formattedStartDate = dayjs(startDate).tz('Asia/Kolkata').format('YYYY-MM-DDTHH:mm:ssZ')
          const formattedEndDate = dayjs(endDate).tz('Asia/Kolkata').format('YYYY-MM-DDTHH:mm:ssZ')
          getFootFallReport({
            variables: {
              take:10000,
              skip:0,
              fromDate:formattedStartDate,
              tillDate:formattedEndDate,
              storeId: selectedStore,
            },
          });
        }
      }, [tabValue, selectedBrand, selectedStore, startDate, endDate]);
    const gmvDataRows = gmvData?.getGmvReport?.data ?? [];
    const salesDataRows = salesData?.getSalesReport?.data ?? [];
    const returnRefundDataRows = returnData?.getReturnAndRefund?.data ?? [];
    const stockDataRows = stockData?.getStockReports?.getStockReports ?? [];
    const eventDataRows = eventData?.getEventReports?.data
    const footfallDataRows = footfallData?.Footfalls?.results?.footFall
    const footfallDataStats = [{ label:"Total Visitors", value:footfallData?.Footfalls?.results?.totalFootfall, fromTime:dayjs(startDate).format('DD-MM-YYYY'), toTime:dayjs(endDate).format('DD-MM-YYYY') }];
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <ReportsFilter.Provider value={{selectedBrand,selectedStore,startDate,endDate,tabValue,getStockReport,getSalesReport,key}}>
            <Grid container>
                <Grid container display={"flex"} justifyContent={"space-between"}>
                <Typography sx={{fontFamily:"montserrat", color:"#000", fontWeight:"700"}} ml={1}>Reports</Typography>
                { tabValue !== 5 && <Grid item xs={10} display="flex" gap={2}>
                   {  user && user?.role === "admin" && <Grid item xs={2}>
                        <Autocomplete
                            labelId="demo-simple-select-label"
                            label="Select Brand"
                            id="demo-simple-select"
                            name="brand"
                            loading={loading}
                            iconComponent={() => (
                                <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                            )}
                            options={data?.brandlist?.results?.length
                                ? data?.brandlist?.results.map((item) => ({
                                    label: item?.name, value: item?.id
                                }))
                                : []
                              }
                                onChange={(e, newValue) => {
                                    setSelectedBrand(newValue?.value || null);
                                    setSelectedStore(null);
                                }}
                                
                            sx={{ "& .MuiInputBase-root": { height: 40, borderRadius: "20px", px: 2, backgroundColor: "white.main" } }}
                            renderInput={(params) => <TextField {...params} label="Select Brand" />}
                        />
                    </Grid>}
                    <Grid item xs={2}>
                        <Autocomplete
                            key={selectedBrand} 
                            labelId="demo-simple-select-label"
                            label="Select Store"
                            id="demo-simple-select"
                            name="store"
                            loading={storeLoading}
                            disabled={!selectedBrand}
                            iconComponent={() => (
                                <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                            )}
                            options={brandStoreList?.length
                                ? brandStoreList.filter((item) => item?.status === "Active").map((item) => ({
                                    label: item.name, value: item.id
                                }))
                                : []
                              }
                            onChange={(e, newValue) => {
                                setSelectedStore(newValue?.value)
                                if(!selectedBrand){
                                    setSelectedStore(null)
                                }
                            }}
                            sx={{ "& .MuiInputBase-root": { height: 40, borderRadius: "20px", px: 2, backgroundColor: "white.main" } }}
                            renderInput={(params) => <TextField {...params} label="Select Store" />}
                        />
                    </Grid>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid item xs={2}>
                            <MobileDateTimePicker
                                label="Start Date & Time"
                                ampm={true} // Enable AM/PM view
                                views={['year', 'month', 'day', 'hours', 'minutes']} // Views with hours and minutes for time selection
                                viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                    seconds: renderTimeViewClock, // Clock for seconds if needed
                                }}
                                format="DD/MM/YYYY hh:mm A"
                                value={dayjs(startDate, 'YYYY-MM-DDTHH:mm:ss')}
                                onChange={handleStartDateChange}
                                maxDate={dayjs()}
                                disableFuture
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={Boolean(startDateError)}
                                        helperText={startDateError || ''}
                                    />
                                )}
                            />
                            {startDateError && (
                                <Typography variant="body2" color="error" sx={{ mt: 1, fontSize: "10px" }}>
                                    {startDateError}
                                </Typography>
                            )}
                        </Grid>

                        <Grid item xs={2}>
                            <MobileDateTimePicker
                                label="End Date & Time"
                                ampm={true} // Enable AM/PM view
                                views={['year', 'month', 'day', 'hours', 'minutes']} // Views with hours and minutes for time selection
                                viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                    seconds: renderTimeViewClock, // Clock for seconds if needed
                                }}
                                format="DD/MM/YYYY hh:mm A"
                                value={dayjs(endDate, 'YYYY-MM-DDTHH:mm:ss')}
                                minDate={dayjs(startDate, 'YYYY-MM-DDTHH:mm:ss')}
                                maxDate={dayjs(startDate, 'YYYY-MM-DDTHH:mm:ss').add(6, 'month').isAfter(dayjs())
                                    ? dayjs()
                                    : dayjs(startDate, 'YYYY-MM-DDTHH:mm:ss').add(6, 'month')}
                                disabled={!startDate}
                                onChange={handleEndDateChange}
                                disableFuture
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        error={Boolean(endDateError)}
                                        helperText={endDateError || ''}
                                    />
                                )}
                            />
                            {endDateError && (
                                <Typography variant="body2" color="error" sx={{ mt: 1, fontSize: "10px" }}>
                                    {endDateError}
                                </Typography>
                            )}
                        </Grid>

                    </LocalizationProvider>
                   {(tabValue === 1 ||tabValue === 3 || tabValue === 6) &&( <Grid item xs={2} sx={{textAlign:"end"}}>
                    <MDButton
                        type="button"
                        variant="outlined"
                        color="black"
                        sx={{
                          fontFamily: "Montserrat",
                          fontSize: "15px",
                          fontWeight: 400,
                          pb:2
                        }}
                        style={{ margin:"0px",}}
                        circular={true}
                        startIcon={<AddIcon />}
                        onClick={tabValue === 1 || tabValue === 3 ? handleDownloadTemplate : handleExportToExcel}
                      >
                        Export
                      </MDButton>
                    </Grid>)}
                </Grid>}
                </Grid>
             { (tabValue === 1  || tabValue === 6) &&( <Grid  item xs={12} display="flex" > <StatsCards tabValue={tabValue} displayData={footfallDataStats} /> </Grid>)}
                <Grid item xs={12} mt={2}>
                    <ReportTabs tabValue={tabValue} setTabValue={setTabValue}
                    gmvDataRows={gmvDataRows}
                    salesDataRows={salesDataRows}
                    returnRefundDataRows={returnRefundDataRows}
                    stockDataRows={stockDataRows}
                    eventDataRows={eventDataRows}
                    footfallDataRows={footfallDataRows} />
                </Grid>
            </Grid>
            </ReportsFilter.Provider>
        </DashboardLayout>
    )
}

export default Reports;
