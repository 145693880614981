import { Box, Icon, IconButton } from "@mui/material";
import { ReactComponent as downloadIcon } from "assets/images/downloadIcon.svg";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";

export const BulkProductStatus = {
  DRAFT: "draft",
  PENDING: "pending",
  PARSEFAILED: "parse failed",
  PARSING: "parsing",
  APPROVED: "approved",
  REJECTED: "rejected",
  DONE: "done",
};

export const VariantStatus = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  DONE: "DONE",
};

export const BulkProductTableHeaderData = [
  { field: "numericId", headerName: "Id" },
  { field: "brand.name", headerName: "Brand Name" },
  { field: "type", headerName: "Type" },
  {
    field: "node",
    headerName: "Node",
  },
  {
    field: "filename",
    headerName: "File Name",
    type: "download",
    downloadUrl: "tranformedFileUrl",
    downloadName: "filename",
    customStyle: {
      color: "blue",
    },
  },

  {
    field: "status",
    headerName: "Status",
    customValueStyle: {
      [BulkProductStatus.DRAFT]: {
        color: "#888888",
        textTransform: "uppercase",
      }, // Grey for draft
      [BulkProductStatus.PENDING]: {
        color: "#FFD700",
        textTransform: "uppercase",
      }, // Gold for pending
      [BulkProductStatus.PARSEFAILED]: {
        color: "#FF6347",
        textTransform: "uppercase",
      }, // Tomato red for parse failed
      [BulkProductStatus.PARSING]: {
        color: "#6495ED",
        textTransform: "uppercase",
      }, // Cornflower blue for parsing
      [BulkProductStatus.APPROVED]: {
        color: "#32CD32",
        textTransform: "uppercase",
      }, // Lime green for approved
      [BulkProductStatus.REJECTED]: {
        color: "#FF4500",
        textTransform: "uppercase",
      }, // Orange red for rejected
      [BulkProductStatus.DONE]: {
        color: "#008000",
        textTransform: "uppercase",
      }, // Green for done
    },
  },
  { field: "message", headerName: "Message" },
  {
    field: "createdByUser.name",
    headerName: "Created By",
  },
  { field: "updated_at", headerName: "Update On", type: "date" },
  {
    field: "action-download",
    downloadUrl: "tranformedFileUrl",
    downloadName: "filename",
    headerName: "Transformed File",
  },
];

export const getBulkProductTableColumnDefs = (setChangeStatusRow) => [
  { field: "numericId", headerName: "Id", filter: false },
  {
    field: "brandName",
    filter: false,
    headerName: "Brand Name",
    valueGetter: (params) => params.data?.brand?.name,
  },
  {
    field: "type",
    headerName: "Type",
    filter: false,
  },
  {
    field: "node",
    headerName: "Node",
    filter: false,
  },
  {
    field: "filename",
    headerName: "File Name",
    filter: false,
    cellRenderer: (params) => {
      const url = params.data?.tranformedFileUrl;
      const name = params.value;
      return (
        <a href={url} download={name} style={{ color: "blue" }}>
          {name}
        </a>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    filter: false,
    cellRenderer: (params) => {
      const value = params.value;
      const customValueStyle = {
        DRAFT: { color: "#888888", textTransform: "uppercase" },
        PENDING: { color: "#FFD700", textTransform: "uppercase" },
        PARSEFAILED: { color: "#FF6347", textTransform: "uppercase" },
        PARSING: { color: "#6495ED", textTransform: "uppercase" },
        APPROVED: { color: "#32CD32", textTransform: "uppercase" },
        REJECTED: { color: "#FF4500", textTransform: "uppercase" },
        DONE: { color: "#008000", textTransform: "uppercase" },
      };
      return (
        <span style={customValueStyle[value?.toUpperCase()]}>{value}</span>
      );
    },
  },
  { field: "message", headerName: "Message", filter: false },
  {
    field: "createdByUser",
    headerName: "Created By",
    filter: false,
    valueGetter: (params) => {
      return (
        params.data?.createdByUser?.name || params.data?.createdByUser?.username
      );
    },
  },
  {
    field: "updated_at",
    headerName: "Update On",
    filter: false,
    valueFormatter: (params) =>
      params.value ? new Date(params.value).toLocaleDateString() : "",
  },
  {
    field: "action-download",
    headerName: "Transformed File",
    filter: false,
    cellRenderer: (params) => {
      const url = params.data?.tranformedFileUrl;
      const name = params.data?.filename;
      const user =
        localStorage.getItem("userDetails") &&
        localStorage.getItem("userDetails") !== "undefined"
          ? JSON.parse(localStorage.getItem("userDetails"))
          : {};
      return (
        // <a href={url} download={name} style={{ color: 'blue' }}>
        //   Download
        // </a>
        <Box display="flex">
          <IconButton
            aria-label="download"
            download={name}
            LinkComponent={"a"}
            href={url}
          >
            <Icon component={downloadIcon} fontSize="small" />
          </IconButton>
          {/* for the time being this button is not dynamic for the action and is for a specific table only */}
          {user?.role === "admin" && (
            <IconButton
              aria-label="change-status"
              onClick={() => {
                setChangeStatusRow({
                  status: params.data?.status,
                  id: params.data?.id,
                });
              }}
            >
              <Icon component={ChangeCircleIcon} fontSize="medium" />
            </IconButton>
          )}
        </Box>
      );
    },
  },
];

export const getSuperAdminBulkProductTableColumnDefs = [
  { field: "numericId", headerName: "Id", filter: false },
  {
    field: "filename",
    headerName: "File Name",
    filter: false,
    cellRenderer: (params) => {
      const url = params.data?.url;
      const name = params.value;
      return (
        <a href={url} download={name} style={{ color: "blue" }}>
          {name}
        </a>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    filter: false,
    cellRenderer: (params) => {
      const value = params.value;
      const customValueStyle = {
        PARSEFAILED: { color: "#FF6347", textTransform: "uppercase" },
        PARSING: { color: "#6495ED", textTransform: "uppercase" },
        APPROVED: { color: "#32CD32", textTransform: "uppercase" },
        REJECTED: { color: "#FF4500", textTransform: "uppercase" },
        DONE: { color: "#008000", textTransform: "uppercase" },
      };
      return (
        <span style={customValueStyle[value?.toUpperCase()]}>{value}</span>
      );
    },
  },
  { field: "message", headerName: "Message", filter: false },
  {
    field: "createdByUser",
    headerName: "Created By",
    filter: false,
    valueGetter: (params) => {
      return (
        params.data.createdByUser.name || params.data?.createdByUser?.username
      );
    },
  },
  {
    field: "updated_at",
    headerName: "Update On",
    filter: false,
    valueFormatter: (params) =>
      params.value ? new Date(params.value).toLocaleDateString() : "",
  },
  {
    field: "action-download",
    headerName: "File",
    filter: false,
    cellRenderer: (params) => {
      const url = params.data?.url;
      const name = params.data?.filename;
      const user =
        localStorage.getItem("userDetails") &&
        localStorage.getItem("userDetails") !== "undefined"
          ? JSON.parse(localStorage.getItem("userDetails"))
          : {};
      return (
        <Box display="flex">
          <IconButton
            aria-label="download"
            download={name}
            LinkComponent={"a"}
            href={url}
          >
            <Icon component={downloadIcon} fontSize="small" />
          </IconButton>
        </Box>
      );
    },
  },
];

export const VariantTableHeaderData = [
  {
    field: "articleName",
    headerName: "Node",
    renderCell: (value) => value.replaceAll("-", "/"),
  },
  { field: "skuId", headerName: "SKU id" },
  { field: "ginesys_response.icode", headerName: "Ginesys Item Code" },
  { field: "itemName", headerName: "Item Name" },
  {
    field: "product.brand.name",
    headerName: "Brand Name",
    defaultValue: "N/A",
  },
  {
    field: "imageUrl",
    headerName: "Image",
    renderCell: (value) => {
      return (
        <img
          src={value}
          alt="variant image"
          style={{
            width: "50px",
            height: "50px",
            objectFit: "contain",
            border: "1px solid #ddd",
            borderRadius: "5px",
            boxShadow: "0px 0px 5px 0px #ddd",
          }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "https://via.placeholder.com/50";
          }}
        />
      );
    },
  },
  {
    field: "availableQty",
    headerName: "Stock",
  },
  {
    field: "status",
    headerName: "G Sync",
    renderCell: (value) => {
      if (value.includes("G_")) {
        let transformedText = value.replace("G_", "");
        transformedText = transformedText.replaceAll("_", " ");
        return transformedText;
      } else return VariantStatus.PENDING;
    },
  },
  { field: "message", headerName: "Message" },
  {
    field: "isSample",
    headerName: "Sample",
    renderCell: (value) => {
      return (
        <span
          style={{
            color: value ? "#0000FF" : "#FF0000",
          }}
        >
          {value ? "YES" : "NO"}
        </span>
      );
    },
  },

  {
    field: "status",
    headerName: "Status",
    renderCell: (value) => {
      const customValueStyle = {
        [VariantStatus.PENDING]: {
          color: "#FFD700",
          textTransform: "uppercase",
        }, // Gold for pending

        [VariantStatus.APPROVED]: {
          color: "#32CD32",
          textTransform: "uppercase",
        }, // Lime green for approved
        [VariantStatus.REJECTED]: {
          color: "#FF4500",
          textTransform: "uppercase",
        }, // Orange red for rejected
        [VariantStatus.DONE]: {
          color: "#008000",
          textTransform: "uppercase",
        }, // Green for done
      };

      if (value.includes("G_")) {
        return (
          <span style={customValueStyle[VariantStatus.APPROVED]}>
            {VariantStatus.APPROVED}
          </span>
        );
      } else {
        return <span style={customValueStyle[value]}>{value}</span>;
      }
    },
  },

  {
    field: "broadwayCreatedDate",
    headerName: "Broadway Created Date",
    type: "date",
  },
  { field: "ERPCreatedDate", headerName: "ERP Created Date", type: "date" },
];

export const VariantTableColumnDefs = [
  {
    headerName: "Node",
    field: "articleName",
    // filter: "agTextColumnFilter",
    filter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    getFilterObj: (value) => ({
      articleName: value?.filter?.replaceAll("/", "-"),
    }),
    getSortObj: (sortOrder) => ({ firstName: sortOrder }),
    cellRenderer: (params) => {
      // Use params.getValue() to retrieve the value in both pivoted and non-pivoted modes
      const value = params.getValue(params.colDef.field);
      return value ? value.replaceAll("-", "/") : "";
    },
    minWidth: 300,
    pivot: true,
  },
  {
    headerName: "SKU id",
    field: "skuId",
    // filter: "agTextColumnFilter",
    filter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    // getFilterObj:(value)=>({
    //   articleName: value?.filter?.replaceAll("/", "-")
    // }),
  },
  {
    headerName: "EAN",
    field: "barCode",
    filter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    headerName: "Ginesys Item Code",
    field: "ginesys_response.icode",
    // filter: "agTextColumnFilter",
    filter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    getFilterObj: (value) => ({
      ginesys_response: { code: value.filter },
    }),
    valueGetter: (params) => params.data?.ginesys_response?.icode,
  },
  {
    headerName: "Item Name",
    field: "itemName",
    // filter: "agTextColumnFilter",
    filter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    getFilterObj: (value) => ({
      ginesys_response: { code: value.filter },
    }),
  },
  {
    headerName: "Reference Filename",
    field: "bulkProductUpload.filename",

    filter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    getFilterObj: (value) => ({
      bulkProductUpload: { filename: value.filter },
    }),
    valueGetter: (params) =>
      params.data?.bulkProductUpload?.filename || "No File Associated",
  },
  {
    headerName: "MRP",
    field: "productPrice.mrp",

    filter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    getFilterObj: (value) => ({
      productPrice: { mrp: value.filter },
    }),
    valueGetter: (params) => params.data?.productPrice?.mrp,
  },
  {
    headerName: "Selling Price",
    field: "productPrice.msp",

    filter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    getFilterObj: (value) => ({
      productPrice: { msp: value.filter },
    }),
    valueGetter: (params) => params.data?.productPrice?.msp,
  },
  {
    headerName: "Cost price",
    field: "productPrice.listingPrice",

    filter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    getFilterObj: (value) => ({
      productPrice: { listingPrice: value.filter },
    }),
    valueGetter: (params) => params.data?.productPrice?.listingPrice,
  },
  {
    headerName: "Brand Name",
    field: "product.brand.name",
    filter: false,
    valueGetter: (params) => params.data?.product?.brand?.name || "N/A",
  },
  {
    headerName: "Image",
    field: "imageUrl",
    filter: false,
    cellRenderer: (params) => {
      const value = params.value;
      return (
        <img
          src={value}
          alt="variant image"
          style={{
            width: "50px",
            height: "50px",
            objectFit: "contain",
            border: "1px solid #ddd",
            borderRadius: "5px",
            boxShadow: "0px 0px 5px 0px #ddd",
          }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "https://via.placeholder.com/50";
          }}
        />
      );
    },
  },
  {
    headerName: "Stock",
    field: "availableQty",
    aggFunc: "count",
    enableValue: true,
    // filter: "agTextColumnFilter",
    filter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    headerName: "G Sync",
    field: "status",
    // filter: "agTextColumnFilter",
    filter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    cellRenderer: (params) => {
      const value = params.value;
      if (value?.includes("G_")) {
        return value?.replace("G_", "")?.replaceAll("_", " ");
      } else {
        return VariantStatus.PENDING;
      }
    },
  },
  {
    headerName: "Sample",
    field: "isSample",
    // filter: "agTextColumnFilter",
    filter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    cellRenderer: (params) => {
      const value = params.value;
      return (
        <span style={{ color: value ? "#0000FF" : "#FF0000" }}>
          {value ? "YES" : "NO"}
        </span>
      );
    },
  },
  {
    headerName: "Status",
    field: "status",
    // filter: "agTextColumnFilter",
    filter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
    cellRenderer: (params) => {
      const value = params.value;
      const customValueStyle = {
        PENDING: { color: "#FFD700", textTransform: "uppercase" },
        APPROVED: { color: "#32CD32", textTransform: "uppercase" },
        REJECTED: { color: "#FF4500", textTransform: "uppercase" },
        DONE: { color: "#008000", textTransform: "uppercase" },
      };

      if (value?.includes("G_")) {
        return (
          <span
            style={{
              color: customValueStyle.APPROVED.color,
              textTransform: "uppercase",
            }}
          >
            APPROVED
          </span>
        );
      } else {
        return (
          <span
            style={{
              color: customValueStyle?.[value]?.color,
              textTransform: "uppercase",
            }}
          >
            {value}
          </span>
        );
      }
    },
  },
  {
    headerName: "Message",
    field: "message",
    // filter: "agTextColumnFilter",
    filter: false,
    floatingFilterComponentParams: { suppressFilterButton: true },
  },
  {
    headerName: "Broadway Created Date",
    field: "broadwayCreatedDate",
    filter: false,
    valueFormatter: (params) =>
      params.value ? new Date(params.value).toLocaleDateString() : "",
  },
  {
    headerName: "ERP Created Date",
    field: "ERPCreatedDate",
    filter: false,
    valueFormatter: (params) =>
      params.value ? new Date(params.value).toLocaleDateString() : "",
  },
];

export const AvailableTabs = {
  singleProduct: "single",
  nodeWiseBulkProduct: "nodewise-bulk",
  bulkProduct: "bulk",
};

export const AvailableForms = AvailableTabs;
