import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import DataTable from "./DataTable";
import {
  AvailableTabs,
  BulkProductTableColumnDefs,
  BulkProductTableHeaderData,
  VariantTableColumnDefs,
  VariantTableHeaderData,
  getBulkProductTableColumnDefs,
  getSuperAdminBulkProductTableColumnDefs,
} from "./tableData";
import TableTabs from "./TableTabs";
import useTableData from "../hooks/useTableData";
import LoadingDataPlaceHolder from "./dataStatePlaceholder/LoadingDataPlaceholder";
import ErrorDataPlaceHolder from "./dataStatePlaceholder/ErrorDataPlaceholder";
import NoDataPlaceHolder from "./dataStatePlaceholder/NoDataPlaceholder";
import { productServiceClient } from "graphql/client";
import { GET_BULK_PRODUCT_UPLOAD } from "services/product-service";
import ChangeStatusModalForm from "../forms/ChangeStatusModalForm";
import { GET_PRODUCT_VARIANT_LIST } from "services/product-service";
import useProductContext from "../context/useProductContext";
import ProductFilter from "./Filters/ProductFilter";
import AgGrid from "components/MDGrid";
import { useLazyQuery } from "@apollo/client";
import { createOrderByFromOrderModel } from "layouts/cutsomer/utils";
import { GET_SUPER_ADMIN_BULK_PRODUCT } from "services/product-service";

const defaultPageSize = 1;

let abortController = new AbortController();
let signal = abortController.signal;

const getProductData = async (tab, limit, skip, filters = {}) => {
  abortController.abort("stalled data fetch");

  abortController = new AbortController();
  signal = abortController.signal;

  Object.keys(filters).forEach((key) => {
    if (filters[key] === "") {
      delete filters[key];
    }
  });

  if (tab === AvailableTabs.singleProduct) {
    const data = await productServiceClient.query({
      query: GET_PRODUCT_VARIANT_LIST,
      variables: { filter: { limit, skip, ...filters } },
      fetchPolicy: "network-only",
      context: { fetchOptions: { signal } },
    });

    const { data: variantList } = data;

    const { getVariants } = variantList;

    return {
      ...getVariants,
      variants: getVariants.variants,
    };
  } else if (tab === AvailableTabs.nodeWiseBulkProduct) {
    const data = await productServiceClient.query({
      query: GET_BULK_PRODUCT_UPLOAD,
      variables: { filter: { limit, skip, ...filters } },
      fetchPolicy: "network-only",
      context: { fetchOptions: { signal } },
    });

    const { data: bulkProductData } = data;

    const { getBulkProductUpload } = bulkProductData;

    return {
      ...getBulkProductUpload,
      bulkProduct: getBulkProductUpload.bulkProducts,
    };
  } else {
    const data = await productServiceClient.query({
      query: GET_SUPER_ADMIN_BULK_PRODUCT,
      variables: { filter: { limit, skip, ...filters } },
      fetchPolicy: "network-only",
      context: { fetchOptions: { signal } },
    });

    const { data: superAdminBulkProductData } = data;

    const { getSuperAdminBulkProduct } = superAdminBulkProductData;

    return {
      ...getSuperAdminBulkProduct,
      superAdminBulkProduct: getSuperAdminBulkProduct.data,
    };
  }
};

export default function ProductData({ gridRef }) {
  let { loading, error, data, reFetchData } = useTableData(getProductData);

  const [selectedTab, setSelectedTab] = useState(AvailableTabs.singleProduct);

  const [limit, setLimit] = useState(defaultPageSize);
  const [skip, setSkip] = useState(0);

  const [changeStatusRow, setChangeStatusRow] = useState(null);

  const { productContext, setProductContext } = useProductContext();

  const [singleProductFilters, setSingleProductFilters] = useState({
    status: "",
    brandId: "",
    categoryId: "",
  });

  const [bulkProductFilters, setBulkProductFilters] = useState({
    status: "",
    brand: "",
    filename: "",
  });

  const filteredBulkProductFilters = { ...bulkProductFilters };

  Object.keys(filteredBulkProductFilters).forEach((key) => {
    if (filteredBulkProductFilters[key] === "") {
      delete filteredBulkProductFilters[key];
    }
  });

  const filteredSingleProductFilters = {
    ...singleProductFilters,
  };

  Object.keys(filteredSingleProductFilters).forEach((key) => {
    if (filteredSingleProductFilters[key] === "") {
      delete filteredSingleProductFilters[key];
    }
  });

  useEffect(() => {
    // the if statments are written twice, to avoid unnecessary api call when the user is on different tab
    if (
      productContext.addedBulkProduct &&
      selectedTab === AvailableTabs.nodeWiseBulkProduct
    ) {
      reFetchData(selectedTab, limit, 0, bulkProductFilters);
      setSkip(0);
    }

    if (
      productContext.addedSuperAdminBulkProduct &&
      selectedTab === AvailableTabs.bulkProduct
    ) {
      reFetchData(selectedTab, limit, 0);
      setSkip(0);
    }

    if (
      productContext.addedSingleProduct &&
      selectedTab === AvailableTabs.singleProduct
    ) {
      reFetchData(selectedTab, limit, 0, singleProductFilters);
      setSkip(0);
    }

    if (productContext.addedBulkProduct || productContext.addedSingleProduct) {
      setProductContext({ addedBulkProduct: false, addedSingleProduct: false });
    }
  }, [productContext]);

  useEffect(() => {
    reFetchData(
      selectedTab,
      limit,
      skip,
      selectedTab === AvailableTabs.nodeWiseBulkProduct
        ? bulkProductFilters
        : singleProductFilters
    );
  }, [limit, skip, singleProductFilters, bulkProductFilters]);

  const [fetchVariants] = useLazyQuery(GET_PRODUCT_VARIANT_LIST, {
    client: productServiceClient,
    fetchPolicy: "network-only",
  });

  const [fetchSuperAdminBulkProduct] = useLazyQuery(
    GET_SUPER_ADMIN_BULK_PRODUCT,
    {
      client: productServiceClient,
      fetchPolicy: "network-only",
    }
  );

  const [fetchBulk] = useLazyQuery(GET_BULK_PRODUCT_UPLOAD, {
    client: productServiceClient,
    fetchPolicy: "network-only",
  });

  return (
    <Grid container mt={5} borderRadius={10}>
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: "#fafafa",
          borderRadius: "10px 10px 0px 0px",
          padding: "0.5rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TableTabs
          selectedTab={selectedTab}
          onChange={(e, newTab) => {
            setSkip(0);
            setSelectedTab(newTab);
            reFetchData(newTab, limit, 0);
          }}
        />

        {selectedTab !== AvailableTabs.bulkProduct && (
          <ProductFilter
            setSingleProductFilters={setSingleProductFilters}
            singleProductFilters={singleProductFilters}
            setBulkProductFilters={setBulkProductFilters}
            bulkProductFilters={bulkProductFilters}
            selectedTab={selectedTab}
          />
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ backgroundColor: "#ffffff", borderRadius: "0px 0px 10px 10px" }}
      >
        {loading ? (
          <LoadingDataPlaceHolder />
        ) : error ? (
          <ErrorDataPlaceHolder error={error} />
        ) : (
          // <DataTable
          //   data={
          //     selectedTab === AvailableTabs.nodeWiseBulkProduct
          //       ? data.bulkProduct
          //       : data.variants
          //   }
          // tableHeaderData={
          //   selectedTab === AvailableTabs.nodeWiseBulkProduct
          //     ? BulkProductTableHeaderData
          //     : VariantTableHeaderData
          // }
          //   total={data.total}
          //   limit={limit}
          //   setLimit={setLimit}
          //   skip={skip}
          //   setSkip={setSkip}
          //   customAction={(row) => {
          //     setChangeStatusRow(row);
          //   }}
          //   showActionsColumn={AvailableTabs.nodeWiseBulkProduct === selectedTab}
          // />
          <AgGrid
            gridProps={{
              rowHeight: 60,
              defaultColDef: {
                // pivot:true,
                enableRowGroup: true,
                enablePivot: true,
                floatingFilter: true,
                sortable: true,
                editable: true,
                filter: true,
                flex: 1,
                minWidth: 200,
              },
              pivotMode: false,
              sideBar: {
                toolPanels: ["columns", "filters"], // Add chart tool panel
              }, // Enable sidebar with columns and filters panel
              enableRangeSelection: true,
              // enableCharts:true,
            }}
            // queryData={
            //   selectedTab === AvailableTabs.nodeWiseBulkProduct
            //     ? data.bulkProduct
            //     : data.variants
            // }
            readMethod={
              selectedTab === AvailableTabs.nodeWiseBulkProduct
                ? {
                    fetchFn: fetchBulk,
                    inputParser: ({ startRow, endRow, filter, sortModel }) => ({
                      filter: {
                        skip: startRow,
                        limit: endRow - startRow,
                        ...filteredBulkProductFilters,
                      },
                    }),
                    resultsParser: (data) => ({
                      results: data?.data?.getBulkProductUpload?.bulkProducts,
                      total: data?.data?.getBulkProductUpload?.total,
                    }),
                  }
                : selectedTab === AvailableTabs.bulkProduct
                ? {
                    fetchFn: fetchSuperAdminBulkProduct,
                    inputParser: ({ startRow, endRow }) => ({
                      filter: {
                        skip: startRow,
                        limit: endRow - startRow,
                        // orderby: createOrderByFromOrderModel(
                        //   sortModel,
                        //   VariantTableColumnDefs
                        // ),
                      },
                    }),
                    resultsParser: (data) => {
                      console.log({ data });
                      return {
                        results: data.data.getSuperAdminBulkProduct.data,
                        total: data.data.getSuperAdminBulkProduct.total,
                      };
                    },
                  }
                : {
                    fetchFn: fetchVariants,
                    inputParser: ({ startRow, endRow, filter, sortModel }) => ({
                      filter: {
                        skip: startRow,
                        limit: endRow - startRow,
                        filter,
                        ...filteredSingleProductFilters,
                      },
                    }),
                    resultsParser: (data) => ({
                      results: data?.data?.getVariants?.variants,
                      total: data?.data?.getVariants?.total,
                    }),
                  }
            }
            columns={
              selectedTab === AvailableTabs.nodeWiseBulkProduct
                ? getBulkProductTableColumnDefs(setChangeStatusRow)
                : selectedTab === AvailableTabs.bulkProduct
                ? getSuperAdminBulkProductTableColumnDefs
                : VariantTableColumnDefs
            }
            defaultColDefProp={{
              floatingFilter: true,
              // filter: "agTextColumnFilter",
              filter: false,
            }}
            customGridRef={gridRef}
          />
        )}
      </Grid>

      <ChangeStatusModalForm
        initialValues={{ status: changeStatusRow?.status, message: "" }}
        open={changeStatusRow}
        onClose={() => setChangeStatusRow(null)}
        onSubmit={(status, err) => {
          if (status) {
            reFetchData(selectedTab, limit, skip);
          }
        }}
      />
    </Grid>
  );
}
