import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import FileUploader from "components/MDFileUpload";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { dashboardServiceClient } from "graphql/client";
import { useNotification } from "context";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import {
  Button,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Autocomplete,
} from "@mui/material";
import { GET_BRAND_DETAILS } from "services/brand-service";
import { CREATE_BRAND_METADATA } from "services/brand-service";
import { UPDATE_BRAND_METADATA } from "services/brand-service";

const validationSchema = yup.object().shape({
  address1: yup.string().required("Address 1 is required"),
  address2: yup.string().required("Address 2 is required"),
  addressProof: yup.string().required("Address Proof is required"),
  city: yup.string().required("City Name is required"),
  state: yup.string().required("State Name is required"),
  pincode: yup
    .string()
    .required("Pincode is required")
    .max(6, "Maximum 6 characters allowed")
    .matches(/^\d+$/, "Pincode must be a number")
    .matches(/^[1-9]{1}[0-9]{5}$/, "Invalid Pincode"),
  addressProofPath: yup.string().required("Address Proof image is required"),
  addressProofUrl: yup.string().required("Address Proof image is required"),
  serviceProvided: yup.string().required("Product/Service is required"),
  companyType: yup.string().required("Type of Company is required"),
  panCardNo: yup
    .string()
    .required("PAN Card No. is required")
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN format"),
  panCardPath: yup.string().required("Pan Card Image is required"),
  panCardUrl: yup.string().required("Pan Card Image is required"),
  gstNo: yup
    .string()
    .required("GST No. is Required")
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      "Invalid GST Number format"
    ),
  gstPath: yup.string().required("GST Proof Image is required"),
  gstUrl: yup.string().required("GST Proof Image is required"),
  tinNo: yup.string().optional("TIN No. is required"),
  tinPath: yup.string().optional("TIN Proof Image is required"),
  tinUrl: yup.string().optional("TIN Proof Image is required"),
  tdsDeductible: yup.boolean().required("TDS Deductible is required"),
  // tdsSection: yup.number().when('tdsDeductible', {
  //   is: true,
  // then: yup.number().required('This field is required'),
  // otherwise: yup.number()
  // }),
  // tdsSection: yup.number().test('isRequired', null, function (value) {
  //   if (this.parent.tdsDeductible === true) {
  //     return yup.number().required("TDS Section is required");
  //   } else {
  //     return true;
  //   }
  // }),
  isMsme: yup.boolean().optional(),
  msmeCertificatePath: yup.string().when("isMsme", {
    is: true,
    then: () => yup.string().required("MSME Certificate is required"),
    otherwise: () => yup.string().optional(),
  }),
  msmeCertificateUrl: yup.string().when("isMsme", {
    is: true,
    then: () => yup.string().required("MSME Certificate is required"),
    otherwise: () => yup.string().optional(),
  }),
});

const useMetadataMutation = (
  brandData,
  brandMetadata,
  setNotification,
  handleTabChange,
  setBrandData
) => {
  const mutationOptions = {
    client: dashboardServiceClient,
    refetchQueries: [
      {
        query: GET_BRAND_DETAILS,
        variables: { username: brandData?.username || "" },
      },
    ],
  };

  const [createBrandMetadataMutation] = useMutation(
    CREATE_BRAND_METADATA,
    mutationOptions
  );
  const [updateBrandMetadataMutation] = useMutation(
    UPDATE_BRAND_METADATA,
    mutationOptions
  );

  const handleSubmit = (values) => {
    const mutation =
      brandMetadata && brandMetadata.id
        ? updateBrandMetadataMutation
        : createBrandMetadataMutation;
    const mutationInput =
      brandMetadata && brandMetadata.id
        ? {
          metaDataInput: {
            id: brandMetadata.id,
            key: "CompanyDetails",
            value: { ...brandMetadata.value, ...values },
          },
        }
        : {
          metaDataInput: {
            brandId: brandData.id,
            key: "CompanyDetails",
            value: { ...values },
          },
        };

    mutation({ variables: mutationInput })
      .then((res) => {
        setBrandData(brandData);
        handleTabChange(null, 2);
        setNotification({
          color: "success",
          isVisible: true,
          message:
            brandMetadata && brandMetadata.id
              ? "Brand Company data updated successfully"
              : "Brand Company data created successfully",
        });
      })
      .catch((error) => {
        console.error(" Error:", error.message);
        setNotification({
          color: "error",
          isVisible: true,
          message: error.message || "Something went wrong",
        });
      });
  };

  return handleSubmit;
};

const CompanyVerificationForm = ({ brandData, handleTabChange, setBrandData, user }) => {
  const { setNotification } = useNotification();
  const [address, setAddress] = useState([]);
  const [addressLoading, setAddressLoading] = useState(false);
  const companyData =
    brandData?.brandMetaData.find((data) => data.key === "CompanyDetails") || {};
  const handleSubmit = useMetadataMutation(
    brandData,
    companyData,
    setNotification,
    handleTabChange,
    setBrandData
  );

  const handleAddressChange = async (e) => {
    setAddressLoading(true)
    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${e.target.value}&format=json&polygon_kml=1&addressdetails=1`);
      let data = await response.json();
      data = data.filter(d => d.address.country_code === "in");
      console.log(data)
      if (data.length > 0) {
        setAddress(data);
      } else {
        console.log('No results found');
      }
    } catch (err) {
      console.log('Error fetching data');
      console.error(err);
    }
    setAddressLoading(false)
  };

  return (
    <Formik
      initialValues={{
        isMsme: companyData?.value?.isMsme || false,
        msmeCertificatePath: companyData?.value?.msmeCertificatePath || companyData?.value?.msmeCertificateUrl || "",
        msmeCertificateUrl: companyData?.value?.msmeCertificateUrl || "",
        address1: companyData?.value?.address1 || "",
        address2: companyData?.value?.address2 || "",
        addressProof: companyData?.value?.addressProof || "",
        city: companyData?.value?.city || "",
        state: companyData?.value?.state || "",
        pincode: companyData?.value?.pincode || "",
        addressProofPath: companyData?.value?.addressProofPath || companyData?.value?.addressProofUrl || "",
        addressProofUrl: companyData?.value?.addressProofUrl || "",
        serviceProvided: companyData?.value?.serviceProvided || "",
        companyType: companyData?.value?.companyType || "",
        panCardNo: companyData?.value?.panCardNo || "",
        panCardPath: companyData?.value?.panCardPath || companyData?.value?.panCardUrl || "",
        panCardUrl: companyData?.value?.panCardUrl || "",
        gstNo: companyData?.value?.gstNo || "",
        gstPath: companyData?.value?.gstPath || companyData?.value?.gstUrl || "",
        gstUrl: companyData?.value?.gstUrl || "",
        tinNo: companyData?.value?.tinNo || "",
        tinPath: companyData?.value?.tinPath || companyData?.value?.tinUrl || "",
        tinUrl: companyData?.value?.tinUrl || "",
        tdsDeductible: companyData?.value?.tdsDeductible,
        tdsSection: companyData?.value?.tdsSection || "",
      }}
      validationSchema={validationSchema}
      validate={(values) => {
        const errors = {};
        if (values.tdsDeductible && !values.tdsSection) {
          errors.tdsSection = 'This field is required';
        }
        return errors;
      }}
      onSubmit={(values) => handleSubmit(values)}
    >
      {(formik, formikProps) => {
        return (
          <Form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <FormControlLabel
                    style={{ fontFamily: "Montserrat", color: "#000000" }}
                    control={
                      <Field
                        as={Checkbox}
                        checked={formik.values.isMsme}
                        name="isMsme"
                      />
                    }
                    label={
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          display: "inline",
                          fontSize: "13px",
                          fontWeight: 500,
                        }}
                      >
                        MSME Certificate if applicable
                      </Typography>
                    }
                  />
                </Grid>
                <Grid container spacing={2}>
                  {formik.values.isMsme && (
                    <>
                      <Grid
                        item
                        container
                        spacing={1}
                        alignItems="center"
                        xs={12}
                      >
                        <Grid item>
                          <Typography
                            variant="h7"
                            fontWeight="bold"
                            fontSize="15px"
                            style={{ color: "#000", fontFamily: "Montserrat" }}
                          >
                            MSME Certificate Upload
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="h7"
                            fontSize="13px"
                            style={{
                              color: "#9e9e9e",
                              fontFamily: "Montserrat",
                            }}
                          >
                            Please upload JPEG, PNG or PDF files only.
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <FileUploader
                          hasDisplayName
                          isMultiple={false}
                          isPdf={true}
                          fieldName={"MSME Certificate Upload"}
                          preLoadedFile={
                            !!formik.values.msmeCertificateUrl
                              ? [{ fileName: formik.values.msmeCertificateUrl, displayName: formik.values.msmeCertificatePath }]
                              : null
                          }
                          signedUrlCallback={(newUrl, index, publicUrl) => {
                            formik.setFieldValue("msmeCertificatePath", newUrl);
                            formik.setFieldValue("msmeCertificateUrl", publicUrl);
                          }}
                          isFieldValid={
                            formik.touched?.msmeCertificateUrl &&
                            formik.errors?.msmeCertificateUrl
                          }
                        />
                        {formik.touched.msmeCertificateUrl &&
                          formik.errors.msmeCertificateUrl && (
                            <Typography fontSize={12} color="error">
                              {formik.errors.msmeCertificateUrl}
                            </Typography>
                          )}
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ color: "#000000", fontFamily: "Montserrat" }}
                    >
                      Company Verification
                    </Typography>
                    <hr color="#EAEAEA" />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="address1"
                      label="Address 1"
                      fullWidth
                      inputProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      InputLabelProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      onBlur={(e) => { handleAddressChange(e) }}

                      error={
                        formik.touched.address1 && !!formik.errors.address1
                      }
                      helperText={
                        formik.touched.address1 ? formik.errors.address1 : ""
                      }
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <Autocomplete
                      labelId="demo-simple-select-label"
                      label="Select Address 1"
                      id="demo-simple-select"
                      name="address1"
                      loading={addressLoading}
                      value={formik.values.address1}
                      iconComponent={() => (
                        <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                      )}
                      fullWidth
                      options={address
                        ?.map((item) => (
                          { label: item?.display_name, value: item?.display_name, state: item?.address?.state, city: item?.address?.city, pincode: item?.address?.postcode }
                        ))}
                      onInputChange={(e, newValue) => {
                        handleAddressChange(e)
                      }}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          formik.setFieldValue("address1",newValue?.label ? newValue?.label : "");
                          formik.setFieldValue("pincode",newValue?.pincode ? newValue?.pincode : "");
                          formik.setFieldValue("city",newValue?.city ? newValue?.city : "");
                          formik.setFieldValue("state",newValue?.state ? newValue?.state : "");
                        }
                        else {
                          formik.setFieldValue("address1", "");
                          formik.setFieldValue("pincode", "");
                          formik.setFieldValue("city", "");
                          formik.setFieldValue("state", "");
                        }
                      }}
                      sx={{ "& .MuiInputBase-root": { height: 40, borderRadius: "7px", px: 2, backgroundColor: "white.main" } }}
                      renderInput={(params) => <TextField {...params} label="Select Address 1" fullWidth />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="address2"
                      label="Address 2"
                      fullWidth
                      inputProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      InputLabelProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      error={
                        formik.touched.address2 && !!formik.errors.address2
                      }
                      helperText={
                        formik.touched.address2 ? formik.errors.address2 : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="state"
                      label="state"
                      value={formik.values.state}
                      fullWidth
                      inputProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      InputLabelProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      error={formik.touched.state && !!formik.errors.state}
                      helperText={formik.touched.state ? formik.errors.state : ""}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      name="city"
                      label="City"
                      value={formik.values.city}
                      fullWidth
                      inputProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      InputLabelProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      error={formik.touched.city && !!formik.errors.city}
                      helperText={formik.touched.city ? formik.errors.city : ""}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      as={TextField}
                      name="pincode"
                      label="Pin Code"
                      value={formik.values.pincode}
                      fullWidth
                      inputProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      InputLabelProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      error={formik.touched.pincode && !!formik.errors.pincode}
                      helperText={
                        formik.touched.pincode ? formik.errors.pincode : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="addressProof"
                        sx={{ fontFamily: "Montserrat" }}
                      >
                        Select Address Proof Type
                      </InputLabel>
                      <Field
                        as={Select}
                        value={formik.values.addressProof}
                        label="Select Address Proof Type"
                        name="addressProof"
                        id="addressProof"
                        onChange={formik.handleChange}
                        fullWidth
                        IconComponent={() => (
                          <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                        )}
                        sx={{
                          height: "40px",
                          fontFamily: "Montserrat",
                        }}
                        error={
                          formik.touched.addressProof &&
                          !!formik.errors.addressProof
                        }
                        helperText={
                          formik.touched.addressProof
                            ? formik.errors.addressProof
                            : ""
                        }
                      >
                        <MenuItem
                          value="aadhaarCard"
                          sx={{ fontFamily: "Montserrat" }}
                        >
                          Aadhaar Card
                        </MenuItem>
                        <MenuItem
                          value="rentAggrement"
                          sx={{ fontFamily: "Montserrat" }}
                        >
                          Rent Aggrement
                        </MenuItem>
                        <MenuItem
                          value="serviceBill"
                          sx={{ fontFamily: "Montserrat" }}
                        >
                          Maintainance Bill / Electricity Bill / Water Bill
                        </MenuItem>
                        <MenuItem
                          value="gstDocument"
                          sx={{ fontFamily: "Montserrat" }}
                        >
                          GST Document
                        </MenuItem>
                      </Field>
                      {formik.touched?.addressProof &&
                        formik.errors?.addressProof && (
                          <Typography fontSize={12} color="error">
                            {formik.errors?.addressProof}
                          </Typography>
                        )}
                    </FormControl>
                  </Grid>
                  <Grid item container spacing={1} alignItems="center" xs={12}>
                    <Grid item>
                      <Typography
                        variant="h7"
                        fontWeight="bold"
                        fontSize="15px"
                        style={{ color: "#000", fontFamily: "Montserrat" }}
                      >
                        Address Proof Upload
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="h7"
                        fontSize="13px"
                        style={{ color: "#9E9E9E", fontFamily: "Montserrat" }}
                      >
                        Please upload JPEG, PNG or PDF files only.
                      </Typography>
                    </Grid>
                  </Grid>
                  {formik.values.addressProof && (
                    <Grid item xs={12}>
                      <FileUploader
                        isMultiple={false}
                        isPdf={true}
                        fieldName={"Address Proof Upload"}
                        hasDisplayName
                        preLoadedFile={
                          !!formik.values.addressProofUrl
                          ? [{fileName:formik.values.addressProofUrl, displayName:formik.values.addressProofPath}]
                          : null
                        }
                        signedUrlCallback={(newUrl, index, publicUrl) => {
                          formik.setFieldValue("addressProofPath", newUrl);
                          formik.setFieldValue("addressProofUrl", publicUrl);
                        }}
                        isFieldValid={
                          formik.touched?.addressProofUrl &&
                          formik.errors?.addressProofUrl
                        }
                      />
                      {formik.touched.addressProofUrl &&
                        formik.errors.addressProofUrl && (
                          <Typography fontSize={12} color="error">
                            {formik.errors.addressProofUrl}
                          </Typography>
                        )}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      name="serviceProvided"
                      label="Product/Service"
                      fullWidth
                      value={formik.values.serviceProvided}
                      inputProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      InputLabelProps={{
                        style: { fontFamily: "Montserrat" },
                      }}
                      error={
                        formik.touched.serviceProvided &&
                        !!formik.errors.serviceProvided
                      }
                      helperText={
                        formik.touched.serviceProvided
                          ? formik.errors.serviceProvided
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="companyType"
                        sx={{ fontFamily: "Montserrat" }}
                      >
                        Type of Company
                      </InputLabel>
                      <Field
                        as={Select}
                        value={formik.values.companyType}
                        label="Type of Company"
                        name="companyType"
                        id="companyType"
                        onChange={formik.handleChange}
                        fullWidth
                        sx={{
                          height: "40px",
                          fontFamily: "Montserrat",
                        }}
                        IconComponent={() => (
                          <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                        )}
                      >
                        <MenuItem value="llp" sx={{ fontFamily: "Montserrat" }}>
                          Limited liability Partnership (LLP)
                        </MenuItem>
                        <MenuItem
                          value="proprietorship"
                          sx={{ fontFamily: "Montserrat" }}
                        >
                          Proprietorship
                        </MenuItem>
                        <MenuItem
                          value="partnership"
                          sx={{ fontFamily: "Montserrat" }}
                        >
                          Partnership
                        </MenuItem>
                        <MenuItem
                          value="publiclimited"
                          sx={{ fontFamily: "Montserrat" }}
                        >
                          Public Limited
                        </MenuItem>
                        <MenuItem
                          value="privatelimited"
                          sx={{ fontFamily: "Montserrat" }}
                        >
                          Private Limited
                        </MenuItem>
                      </Field>
                      {formik.touched?.companyType &&
                        formik.errors?.companyType && (
                          <Typography fontSize={12} color="error">
                            {formik.errors?.companyType}
                          </Typography>
                        )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="h5"
                      gutterBottom
                      style={{ color: "#000", fontFamily: "Montserrat" }}
                    >
                      Tax Details
                    </Typography>
                    <hr color="#EAEAEA" />
                  </Grid>
                  <Grid item xs={12} gap={2} display={"flex"}>
                    <Grid item xs={6}>
                      <Field
                        as={TextField}
                        label="PAN Card No."
                        fullWidth
                        name="panCardNo"
                        value={formik.values.panCardNo}
                        onChange={formik.handleChange}
                        inputProps={{
                          style: { fontFamily: "Montserrat" },
                        }}
                        InputLabelProps={{
                          style: { fontFamily: "Montserrat" },
                        }}
                        error={
                          formik.touched.panCardNo && !!formik.errors.panCardNo
                        }
                        helperText={
                          formik.touched.panCardNo
                            ? formik.errors.panCardNo
                            : ""
                        }
                      />
                    </Grid>
                    {formik.values.panCardNo && (
                      <Grid item xs={6}>
                        <FileUploader
                          isMultiple={false}
                          fieldName={"PAN Card Upload"}
                          isPdf={true}
                          hasDisplayName
                          preLoadedFile={
                            !!formik.values.panCardUrl
                          ? [{fileName:formik.values.panCardUrl, displayName:formik.values.panCardPath}]
                          : null
                          }
                          signedUrlCallback={(newUrl, index, publicUrl) => {
                            formik.setFieldValue("panCardPath", newUrl);
                            formik.setFieldValue("panCardUrl", publicUrl);
                          }}
                          isFieldValid={
                            formik.touched?.panCardUrl &&
                            formik.errors?.panCardUrl
                          }
                        />
                        {formik.touched.panCardUrl && formik.errors.panCardUrl && (
                          <Typography fontSize={12} color="error">
                            {formik.errors.panCardUrl}
                          </Typography>
                        )}
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} gap={2} display={"flex"}>
                    <Grid item xs={6}>
                      <Field
                        as={TextField}
                        label="GST No."
                        fullWidth
                        name="gstNo"
                        value={formik.values.gstNo}
                        onChange={formik.handleChange}
                        inputProps={{
                          style: { fontFamily: "Montserrat" },
                        }}
                        InputLabelProps={{
                          style: { fontFamily: "Montserrat" },
                        }}
                        error={formik.touched.gstNo && !!formik.errors.gstNo}
                        helperText={
                          formik.touched.gstNo ? formik.errors.gstNo : ""
                        }
                      />
                    </Grid>
                    {formik.values.gstNo && (
                      <Grid item xs={6}>
                        <FileUploader
                          isMultiple={false}
                          fieldName={"GST Upload"}
                          hasDisplayName
                          isPdf={true}
                          preLoadedFile={
                            !!formik.values.gstUrl
                          ? [{fileName:formik.values.gstUrl, displayName:formik.values.gstPath}]
                          : null
                          }
                          signedUrlCallback={(newUrl, index, publicUrl) => {
                            formik.setFieldValue("gstPath", newUrl);
                            formik.setFieldValue("gstUrl", publicUrl);
                          }}
                          isFieldValid={
                            formik.touched?.gstUrl && formik.errors?.gstUrl
                          }
                        />
                        {formik.touched.gstUrl && formik.errors.gstUrl && (
                          <Typography fontSize={12} color="error">
                            {formik.errors.gstUrl}
                          </Typography>
                        )}
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} gap={2} display={"flex"}>
                    <Grid item xs={6}>
                      <Field
                        as={TextField}
                        label="TIN No."
                        fullWidth
                        name="tinNo"
                        value={formik.values.tinNo}
                        onChange={formik.handleChange}
                        inputProps={{
                          style: { fontFamily: "Montserrat" },
                        }}
                        InputLabelProps={{
                          style: { fontFamily: "Montserrat" },
                        }}
                        error={formik.touched.tinNo && !!formik.errors.tinNo}
                        helperText={
                          formik.touched.tinNo ? formik.errors.tinNo : ""
                        }
                      />
                    </Grid>
                    {formik.values.tinNo && (
                      <Grid item xs={6}>
                        <FileUploader
                          isMultiple={false}
                          isPdf={true}
                          fieldName={"TIN Upload"}
                          hasDisplayName
                          preLoadedFile={
                            !!formik.values.tinUrl
                          ? [{fileName:formik.values.tinUrl, displayName:formik.values.tinPath}]
                          : null
                          }
                          signedUrlCallback={(newUrl, index, publicUrl) => {
                            formik.setFieldValue("tinPath", newUrl);
                            formik.setFieldValue("tinUrl", publicUrl);
                          }}
                        />
                        {formik.touched.tinUrl && formik.errors.tinUrl && (
                          <Typography fontSize={12} color="error">
                            {formik.errors.tinUrl}
                          </Typography>
                        )}
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} gap={2} display={"flex"}>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel
                          id="tdsDeductible"
                          sx={{ fontFamily: "Montserrat" }}
                        >
                          TDS Deductible
                        </InputLabel>
                        <Field
                          as={Select}
                          value={formik.values.tdsDeductible}
                          label="TDS Deductible"
                          name="tdsDeductible"
                          id="tdsDeductible"
                          onChange={(e)=>{formik.handleChange(e); formik.setFieldValue("tdsSection","")}}
                          fullWidth
                          sx={{
                            height: "40px",
                            fontFamily: "Montserrat",
                          }}
                          IconComponent={() => (
                            <ArrowDropDownIcon
                              style={{ marginRight: "18px" }}
                            />
                          )}
                        >
                          <MenuItem
                            value={true}
                            sx={{ fontFamily: "Montserrat" }}
                          >
                            Yes
                          </MenuItem>
                          <MenuItem
                            value={false}
                            sx={{ fontFamily: "Montserrat" }}
                          >
                            No
                          </MenuItem>
                        </Field>
                        {formik.touched?.tdsDeductible &&
                          formik.errors?.tdsDeductible && (
                            <Typography fontSize={12} color="error">
                              {formik.errors?.tdsDeductible}
                            </Typography>
                          )}
                      </FormControl>
                    </Grid>
                    {formik.values.tdsDeductible === true ?
                      <Grid item xs={6}>
                        <Field
                          as={TextField}
                          label="TDS Section"
                          fullWidth
                          name="tdsSection"
                          value={formik.values.tdsSection}
                          onChange={formik.handleChange}
                          inputProps={{
                            style: { fontFamily: "Montserrat" },
                          }}
                          InputLabelProps={{
                            style: { fontFamily: "Montserrat" },
                          }}
                          error={
                            formik.touched.tdsSection &&
                            !!formik.errors.tdsSection
                          }
                          helperText={
                            formik.touched.tdsSection
                              ? formik.errors.tdsSection
                              : ""
                          }
                        />
                      </Grid>
                      : ""}
                  </Grid>
                  
                </Grid>
              </Grid>

              <Grid
                container
                sx={{ mt: 3 }}
                justifyContent={"flex-end"}
                gap={3}
              >
                <Button
                  sx={{
                    color: "#000000",
                    borderRadius: "50px",
                    borderColor: "#000000",
                    fontFamily: "Montserrat",
                  }}
                  variant="outlined"
                  onClick={() => {
                    formik.resetForm({
                      values: {
                        // You can set default or empty values here
                        isMsme: false,
                        msmeCertificatePath: "",
                        msmeCertificateUrl:"",
                        address1: "",
                        address2: "",
                        addressProof: "",
                        city: "",
                        state:"",
                        pincode: "",
                        addressProofPath: "",
                        addressProofUrl:"",
                        serviceProvided: "",
                        companyType: "",
                        panCardNo: "",
                        panCardPath: "",
                        panCardUrl:"",
                        gstNo: "",
                        gstPath: "",
                        gstUrl:"",
                        tinNo: "",
                        tinPath: "",
                        tinUrl:"",
                        tdsDeductible: "",
                        tdsSection: "",
                      },
                    });
                  }}
                >
                  Reset
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    marginRight: "10px",
                    backgroundColor: "#000000",
                    color: "#fff",
                    borderRadius: "50px",
                    fontFamily: "Montserrat",
                  }}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CompanyVerificationForm;
