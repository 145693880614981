import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  Checkbox,
  Autocomplete,
  FormGroup,
} from "@mui/material";
import ReactQuill from "react-quill";
import dayjs, { duration } from "dayjs";
import { useMutation, useQuery } from "@apollo/client";
import { dashboardServiceClient } from "graphql/client";
import { LIST_BRAND_PRODUCT } from "services/brand-service";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { Formik, Form, Field, FieldArray } from "formik";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as Yup from "yup";
import { InputAdornment } from "@mui/material";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import Loader from "components/MDLoader";
import { GET_EVENTS } from "services/events-service";
import { eventServiceClient } from "graphql/client";
import { CREATE_TICKET } from "services/events-service";
import { UPDATE_TICKET } from "services/events-service";
import { useNotification } from "context";
import { CREATE_OR_UPDATE_TICKET } from "services/events-service";
import MDButton from "components/MDButton";
import { GET_PRODUCT_LIST } from "services/events-service";
import { productServiceClient } from "graphql/client";
import useDebounce from "../product-category/useDebounce";

const defaultTicket = {
  id: null,
  ticketName: "",
  hexColour: "",
  status: "",
  quantity: "",
  priceType: "free",
  price: "",
  priceValue: "",
  paymentMode: "",
  priceApply: "",
  minimumBuyingLimit: 1,
  maximumBuyingLimit: 1,
  start: "",
  end: "",
  startTime: "",
  endTime: "",
  // slotType: "",
  duration: "",
  // count: "",
  productName: [],
  ticketDescription: "",
  visibility: "alwaysVisible",
  hideOnConditions: {
    closed: false,
    soldOut: false,
    yetToStart: false,
    expired: false,
  },
};

const useTicketMutation = (eventData, handleClose, setNotification) => {
  const mutationOptions = {
    client: eventServiceClient,
    refetchQueries: [
      {
        query: GET_EVENTS,
        variables: {
          eventListFilter: { take: 20, skip: 0, search: "", filter: {} },
        },
      },
    ],
    onCompleted: (res) => {
      handleClose();
      setNotification({
        color: "success",
        isVisible: true,
        message: "Operation Successful",
      });
    },
    onError: (err) => {
      console.log(err);
      setNotification({
        color: "error",
        isVisible: true,
        message: err.message || "Failed to create"
      });
    },
  };
  const [createOrUpdateTicketMutation] = useMutation(
    CREATE_OR_UPDATE_TICKET,
    mutationOptions
  );
  const handleSubmit = (values) => {
    let hideOn = [];
    values.tickets.forEach((ticket) => {
      if (ticket.visibility === "conditional") {
        let result = {};
        Object.entries(ticket.hideOnConditions).forEach(([key, value]) => {
          result[key] = value;
        });
        Object.entries(result).forEach(([key, value]) => {
          value && hideOn.push(key);
        });
      } else {
        hideOn = [ticket.visibility];
      }
    });

    const originalObject = eventData?.ticket.map((ticket) => {
      const foundTicket = values?.tickets.find((tic) => tic.id === ticket.id);
      if (foundTicket) {
        return null;
      } else {
        const tic = {
          ...ticket,
          duration: ticket?.duration?.toString() || null,
          // slotCount: +ticket.count || null,
          isDeleted: true,
          productId: ticket?.product.map((t) => t.productId) || [],
        };
        delete tic?.__typename;
        delete tic?.product;
        delete tic?.prices;
        return tic;
      }
    }).filter(i => i);

    const newCreated = values?.tickets.map((ticket) => {
        return {
          id: ticket.id,
          eventId: eventData.id,
          name: ticket.ticketName,
          color: ticket.hexColour,
          ticketStatus: ticket.status,
          quantity: eventData?.eventType !== "oneToOne"  ? +ticket.quantity || 1 : 1,
          duration: ticket?.duration?.toString() || null,
          // slotCount: +ticket.count || null,
          prices:
            ticket.priceType === "paid"
              ? {
                  id: ticket.priceId || null,
                  mode: ticket.paymentMode || 'loyalty',
                  price: +ticket.price || 0,
                  apply: ticket.priceApply || 'flat',
                  value: +ticket.priceValue || 0,
                  priceType: ticket.priceType,
                }
              : {
                id: ticket.priceId || null,
                mode: ticket.paymentMode || 'loyalty',
                price: 0,
                apply: ticket.priceApply || 'flat',
                value: +ticket.priceValue || 0,
                priceType: ticket.priceType,
              },
          minBuy: +ticket.minimumBuyingLimit,
          maxBuy: +ticket.maximumBuyingLimit,
          startDate: ticket.start,
          endDate: ticket.end,
          startTime: ticket.startTime,
          endTime: ticket.endTime,
          productId:
            ticket.productName.length > 0
              ? ticket.productName.map((item) => item.id)
              : [],
          description: ticket.ticketDescription,
          hideOn: hideOn,
        };
    });

    const payload = {
      variables: {
        request: {
          ticketInput: [ ...originalObject, ...newCreated ],
        },
      },
    };

    createOrUpdateTicketMutation(payload)
  };
  return handleSubmit;
};

const TicketForm = ({ eventData, handleClose }) => {
  const [tickets, setTickets] = useState([defaultTicket]);
  const [nowChecked, setNowChecked] = useState({});
  const [eventEndChecked, setEventEndChecked] = useState({});

  const { setNotification } = useNotification();
  const [searachValues, setSearchValues] = React.useState("");
  const debouncedSearchTerm = useDebounce(searachValues, 300);

  const handleSubmit = useTicketMutation(
    eventData,
    handleClose,
    setNotification
  );
  const {
    loading: productLoading,
    error: productError,
    data: productData,
    refetch
  } = useQuery(GET_PRODUCT_LIST, {
    client: productServiceClient,
    variables: {
        limit: 20, skip: 0,  search: debouncedSearchTerm,status:"G_SYNCED",isSample:true,
    },
  });
  const productList = productData?.getVariants?.variants?.filter(item => item?.isSample) || [];
 
  useEffect(()=>{
    if(debouncedSearchTerm){
      refetch()
    }
  },[debouncedSearchTerm])

  // if (productLoading)
  //   return (
  //     <>
  //       <Loader />
  //     </>
  //   );
  if (productError) return <p>Error: {productError.message || "error"}</p>;

  const handleCheckboxChange = (
    index,
    setFieldValue,
    field,
    isChecked,
    eventData
  ) => {
    const current = dayjs();
    const currentDate = current.format("YYYY-MM-DD");
    const currentTime = current.format("HH:mm");
    const eventEndDate = dayjs(eventData.endDate).format("YYYY-MM-DD");
    const eventEndTime = dayjs(eventData.endTime, "HH:mm").format("HH:mm");

    if (field === "start") {
      setNowChecked({ ...nowChecked, [index]: isChecked });
      if (isChecked) {
        setFieldValue(`tickets[${index}].start`, currentDate);
        setFieldValue(`tickets[${index}].startTime`, currentTime);
      } else {
        setFieldValue(`tickets[${index}].start`, "");
        setFieldValue(`tickets[${index}].startTime`, "");
      }
    } else if (field === "end") {
      setEventEndChecked({ ...eventEndChecked, [index]: isChecked });
      if (isChecked) {
        setFieldValue(`tickets[${index}].end`, eventEndDate);
        setFieldValue(`tickets[${index}].endTime`, eventEndTime);
      } else {
        setFieldValue(`tickets[${index}].end`, "");
        setFieldValue(`tickets[${index}].endTime`, "");
      }
    }
  };

  // const handleSlotChange = (index, setFieldValue, value) => {
  //   setFieldValue(`tickets[${index}].slotType`, value);
  //   if (value === "duration") {
  //     setFieldValue(`tickets[${index}].count`, "");
  //   } else if (value === "count") {
  //     setFieldValue(`tickets[${index}].duration`, "");
  //   }
  // };

  const handleDuplicateTicket = (index, values, setFieldValue) => {
    const duplicatedTicket = JSON.parse(JSON.stringify(values.tickets[index]));
    if (duplicatedTicket) {
      duplicatedTicket.id = null;
      if (duplicatedTicket.prices) {
        duplicatedTicket.prices.id = null;
      }
    }
    const newTickets = [...values.tickets, duplicatedTicket];
    setFieldValue("tickets", newTickets);
  };

  const validationSchema = (data) =>
    Yup.object({
      tickets: Yup.array()
        .of(
          Yup.object({
            ticketName: Yup.string().required("Ticket Name is required")
            .max(30,"Maximum 30 Character is Allowed"),
            hexColour: Yup.string().required("Hex Colour Code is required"),
            status: Yup.string().required("Status Type is required"),
            quantity: Yup.number().when('eventType', {
              is: (value) => data?.eventType !== 'oneToOne',
              then: () => Yup.number()
                .required('Quantity is required when the event type is One to One')
                .positive('Quantity must be a positive number')
                .integer('Quantity must be an integer'),
              otherwise: () => Yup.number().notRequired(),
            }),            
            priceType: Yup.string()
              .required("Pricing type is required")
              .oneOf(["free", "paid"], "You must select a pricing type"),
            price: Yup.number().when("priceType", {
              is: "paid",
              then: () =>
                Yup.number()
                  .required("Value is required")
                  .positive("Value must be positive")
                  .typeError("Value must be a number"),
              otherwise: () => Yup.number().notRequired(),
            }),
            paymentMode: Yup.string().when("priceType", {
              is: "paid",
              then: () => Yup.string().required("Value is required"),
              otherwise: () => Yup.string().notRequired(),
            }),
            priceApply: Yup.string().when(["priceType", "paymentMode"], {
              is: (priceType, paymentMode) =>
                priceType === "paid" && paymentMode === "loyaltyAndCash",
              then: () => Yup.string().required("Price Apply is required"),
              otherwise: () => Yup.string().notRequired(),
            }),
            priceValue: Yup.number().test('price-value-checker', 'Please enter correct value', function (value) {
              const { priceType, price, paymentMode, priceApply } = this.parent;
              if(priceType === 'paid' && paymentMode === 'loyaltyAndCash') {
                if(priceApply === 'flat') {
                  return value <= price
                } else if (priceApply === 'percentage') {
                  return value <= 100
                }
              } 
              return true
            }),
            minimumBuyingLimit: Yup.number()
              .required("Minimum Buying Limit is required")
              .positive("Minimum Buying Limit must be positive")
              .typeError("Minimum Buying Limit must be a number")
              .test(
                "is-less-than-maximumBuyingLimit",
                "It Should Be Less than Maximum Buying Limit",
                function(value){
                  const {maximumBuyingLimit} =this.parent
                  return value <= maximumBuyingLimit;
                }
              ),
            maximumBuyingLimit: Yup.number()
              .required("Maximum Buying Limit is required")
              .positive("Maximum Buying Limit must be positive")
              .typeError("Maximum Buying Limit must be a number")
              .when("eventType",{
                is: (value)=> data?.eventType !== "oneToOne",
                then: ()=> Yup.number().test(
                  "is-less-than-quantity",
                  "Maximum Buying Limit must be less than or equal to Quantity",
                  function (value) {
                    const { quantity } = this.parent;
                    return value <= quantity;
                  }
                ),
              }),
            start: Yup.date()
              .required("Start date is required"),
            end: Yup.date()
              .required("End date is required"),
            startTime: Yup.string()
              .required("Start time is required"),
            endTime: Yup.string()
              .required("End time is required"),
            productName: Yup.array(),
            ticketDescription: Yup.string().required("Description is required"),
            // slotType: Yup.string(),
            // // .required("Slot type is required"),
            // duration: Yup.number().when("slotType", {
            //   is: "duration",
            //   then: () =>
            //     Yup.number()
            //       .required("Duration is required when slot type is duration")
            //       .positive(),
            //   otherwise: () => Yup.number().notRequired(),
            // }),
            // count: Yup.number().when("slotType", {
            //   is: "count",
            //   then: () =>
            //     Yup.number()
            //       .required("Count is required when slot type is count")
            //       .positive(),
            //   otherwise: () => Yup.number().notRequired(),
            // }),
            duration:Yup.number().when("eventType", {
              is: (value) => data?.eventType === 'oneToOne',
              then: ()=> Yup.number()
                .required("Duration is required when the event type is One to One")
                .positive("Duration must be a positive number")
                .integer("Duration must be an integer"),
              otherwise:() => Yup.number().notRequired(),
            }),
          })
        )
        .required("At least one ticket is required"),
    });

  const getMinMaxTime = (
    eventEndTime = null,
    eventStartTime = null,
    timeFor = "startTime"
  ) => {
    const storeStartDayTime = eventData?.startTime;
    const storeEndDayTime = eventData?.endTime;
    if (timeFor === "startTime") {
      return {
        min: dayjs(storeStartDayTime, "HH:mm"),
        max: eventEndTime
          ? dayjs(eventEndTime, "HH:mm")
          : dayjs(storeEndDayTime, "HH:mm"),
      };
    } else {
      return {
        min: eventStartTime
          ? dayjs(eventStartTime, "HH:mm")
          : dayjs(storeStartDayTime, "HH:mm"),
        max: dayjs(storeEndDayTime, "HH:mm"),
      };
    }
  };

  const getMinMaxDate = (
    eventEndDate = null,
    eventStartDate = null,
    timeFor = "startTime"
  ) => {
    const storeStartDay = eventData?.createdAt;
    const storeEndDay = eventData?.endDate;
    if (timeFor === "startTime") {
      return {
        min: dayjs(storeStartDay, "YYYY-MM-DD"),
        max: eventEndDate
          ? dayjs(eventEndDate, "YYYY-MM-DD")
          : dayjs(storeEndDay, "YYYY-MM-DD"),
      };
    } else {
      return {
        min: eventStartDate
          ? dayjs(eventStartDate, "YYYY-MM-DD")
          : dayjs(storeStartDay, "YYYY-MM-DD"),
        max: dayjs(storeEndDay, "YYYY-MM-DD"),
      };
    }
  };

  const isSameOrAfter = (timeToCheck, compareTime) => {
    return timeToCheck.isSame(compareTime) || timeToCheck.isAfter(compareTime);
  };

  const isSameOrBefore = (timeToCheck, compareTime) => {
    return timeToCheck.isSame(compareTime) || timeToCheck.isBefore(compareTime);
  };


  return (
    <Formik
      initialValues={{
        tickets:
          eventData?.ticket && eventData?.ticket.length
            ? eventData?.ticket.map((ticket) => ({
                id: ticket?.id || null,
                ticketName: ticket?.name || "",
                hexColour: ticket?.color || "",
                status: ticket?.ticketStatus || "",
                quantity: ticket?.quantity || "",
                priceType: ticket?.prices?.priceType || "free",
                priceId: ticket?.prices?.id || null,
                price: ticket?.prices?.price || "",
                priceValue: ticket?.prices?.value || "",
                paymentMode: ticket?.prices?.mode || "",
                priceApply: ticket?.prices?.apply || "",
                minimumBuyingLimit: ticket?.minBuy || 1,
                maximumBuyingLimit: ticket?.maxBuy || 1,
                start: ticket?.startDate || "",
                end: ticket?.endDate || "",
                startTime: ticket?.startTime || "",
                endTime: ticket?.endTime || "",
                // slotType: ticket?.slotCount ? "count" : ticket?.duration ? "duration" : "",
                duration: ticket?.duration || "",
                // count: ticket?.slotCount || "",
                productName: ticket?.product?.map((product) => {
                  return ({ id: product.productId, label: product.itemName || "N/A" })
                }).filter((product) => product !== null) || [],
                ticketDescription: ticket?.description || "",
                visibility: ticket?.hideOn.includes("alwaysVisible")
                  ? "alwaysVisible"
                  : ticket?.hideOn.includes("alwaysHidden")
                  ? "alwaysHidden"
                  : "conditional",
                hideOnConditions: {
                  closed: ticket?.hideOn.includes("closed") || false,
                  soldOut: ticket?.hideOn.includes("soldOut") || false,
                  yetToStart: ticket?.hideOn.includes("yetToStart") || false,
                  expired: ticket?.hideOn.includes("expired") || false,
                },
              }))
            : [defaultTicket],
      }}
      validationSchema={validationSchema((eventData))}
      onSubmit={(values) => {
        // const hideOn = Object.entries(values.hideOnConditions).map(([key, value]) => value ? key : null).filter(item => item);
        // if(values.visibility !== 'conditional') {
        //   hideOn = [values.visibility]
        // }
        handleSubmit(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleBlur,
        setFieldTouched,
        validateField,
        isValid,
      }) => (
        <Form>
          <FieldArray name="tickets">
            {({ push, remove }) => (
              <>
                <Grid container spacing={2}>
                  {Array.isArray(values?.tickets) &&
                    values?.tickets?.map((ticket, index) => (
                      <Grid item xs={12} key={index}>
                        <Accordion defaultExpanded={ index === 0 ? true : false}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{`Ticket - ${ticket.ticketName}`}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Field
                                  as={TextField}
                                  name={`tickets[${index}].ticketName`}
                                  label="Ticket Name"
                                  fullWidth
                                  error={
                                    touched.tickets?.[index]?.ticketName &&
                                    !!errors.tickets?.[index]?.ticketName
                                  }
                                  helperText={
                                    touched.tickets?.[index]?.ticketName &&
                                    errors.tickets?.[index]?.ticketName
                                  }
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Field name="hexColour">
                                  {({ field }) => (
                                    <TextField
                                      {...field}
                                      type="text"
                                      label="Ticket Color"
                                      name={`tickets[${index}].hexColour`}
                                      variant="outlined"
                                      fullWidth
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      value={values.tickets[index].hexColour}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            <input
                                              type="color"
                                              value={
                                                values.tickets[index]
                                                  .hexColour || "#ffffff"
                                              }
                                              onChange={(e) =>
                                                setFieldValue(
                                                  `tickets[${index}].hexColour`,
                                                  e.target.value
                                                )
                                              }
                                              style={{
                                                width: "30px",
                                                height: "30px",
                                                border: "none",
                                                padding: 0,
                                                background: "none",
                                              }}
                                            />
                                          </InputAdornment>
                                        ),
                                      }}
                                      error={
                                        touched.tickets?.[index]?.hexColour &&
                                        !!errors.tickets?.[index]?.hexColour
                                      }
                                      helperText={
                                        touched.tickets?.[index]?.hexColour &&
                                        errors.tickets?.[index]?.hexColour
                                      }
                                    />
                                  )}
                                </Field>
                              </Grid>
                              <Grid item xs={6}>
                                <FormControl
                                  fullWidth
                                  error={Boolean(
                                    touched.tickets?.[index]?.status &&
                                      errors.tickets?.[index]?.status
                                  )}
                                >
                                  <InputLabel id={`status-label-${index}`}>
                                    Status
                                  </InputLabel>
                                  <Select
                                    labelId={`status-label-${index}`}
                                    label="Status"
                                    name={`tickets[${index}].status`}
                                    value={values.tickets[index].status || ""} // Ensures binding to the current form state
                                    onChange={(e) => {
                                      setFieldValue(
                                        `tickets[${index}].status`,
                                        e.target.value
                                      );
                                    }}
                                    error={Boolean(
                                      touched.tickets?.[index]?.status &&
                                        errors.tickets?.[index]?.status
                                    )}
                                    displayEmpty // Shows placeholder even when value is ''
                                    sx={{ height: 40 }}
                                  >
                                    <MenuItem value="open">Open</MenuItem>
                                    <MenuItem value="closed">Closed</MenuItem>
                                    <MenuItem value="soldout">
                                      Sold Out
                                    </MenuItem>
                                  </Select>
                                  {touched.tickets?.[index]?.status &&
                                    errors.tickets?.[index]?.status && (
                                      <FormHelperText>
                                        {errors.tickets?.[index]?.status}
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </Grid>
                              {eventData?.eventType !== "oneToOne" && (<Grid item xs={6}>
                                <Field
                                  as={TextField}
                                  name={`tickets[${index}].quantity`}
                                  label="Quantity"
                                  fullWidth
                                  error={
                                    touched.tickets?.[index]?.quantity &&
                                    !!errors.tickets?.[index]?.quantity
                                  }
                                  helperText={
                                    touched.tickets?.[index]?.quantity &&
                                    errors.tickets?.[index]?.quantity
                                  }
                                  type="number"
                                />
                              </Grid>)}
                              <Grid
                                container
                                spacing={2}
                                alignItems="center"
                                m={2}
                                p={2}
                                backgroundColor="#FAFAFA"
                                borderRadius="10px"
                              >
                                {/* <Grid item xs={12}> */}
                                <Typography
                                  variant="subtitle1"
                                  fontSize={15}
                                  fontWeight="bold"
                                >
                                  Pricing
                                </Typography>
                                {/* </Grid> */}
                                <Grid item xs={12}>
                                  <Field
                                    name={`tickets[${index}].priceType`}
                                    error={
                                      touched.tickets?.[index]?.priceType &&
                                      !!errors.tickets?.[index]?.priceType
                                    }
                                    helperText={
                                      touched.tickets?.[index]?.priceType &&
                                      errors.tickets?.[index]?.priceType
                                    }
                                  >
                                    {({ field }) => (
                                      <RadioGroup
                                        {...field}
                                        row
                                        onChange={(event) => {
                                          setFieldValue(
                                            field.name,
                                            event.target.value
                                          );
                                          if (event.target.value === "free") {
                                            setFieldValue(
                                              `tickets[${index}].price`,
                                              0
                                            );
                                            setFieldValue(
                                              `tickets[${index}].paymentMode`,
                                              ""
                                            );
                                            setFieldValue(
                                              `tickets[${index}].priceApply`,
                                              ""
                                            );
                                            setFieldValue(
                                              `tickets[${index}].priceValue`,
                                              0
                                            );
                                          }
                                        }}
                                      >
                                        <FormControlLabel
                                          value="free"
                                          control={<Radio />}
                                          label="Free"
                                        />
                                        <FormControlLabel
                                          value="paid"
                                          control={<Radio />}
                                          label="Paid"
                                        />
                                      </RadioGroup>
                                    )}
                                  </Field>
                                  <Typography color="error" fontSize={12}>
                                    {errors.tickets?.[index]?.priceType}
                                  </Typography>
                                </Grid>
                                {values.tickets?.[index]?.priceType ===
                                  "paid" && (
                                  <React.Fragment>
                                    <Grid item xs={3}>
                                      <Field
                                        as={TextField}
                                        name={`tickets[${index}].price`}
                                        label="Value"
                                        type="number"
                                        fullWidth
                                        error={
                                          touched.tickets?.[index]?.price &&
                                          !!errors.tickets?.[index]?.price
                                        }
                                        helperText={
                                          touched.tickets?.[index]?.price &&
                                          errors.tickets?.[index]?.price
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={3}>
                                      <FormControl
                                        fullWidth
                                        error={
                                          touched.tickets?.[index]
                                            ?.paymentMode &&
                                          Boolean(
                                            errors.tickets?.[index]?.paymentMode
                                          )
                                        }
                                      >
                                        <InputLabel id="demo-simple-select-label">
                                          Payment Mode
                                        </InputLabel>
                                        <Select
                                          as={Select}
                                          name={`tickets[${index}].paymentMode`}
                                          label="Payment Mode "
                                          IconComponent={() => (
                                            <ArrowDropDownIcon
                                              style={{
                                                marginRight: "18px",
                                                marginTop: "10px",
                                              }}
                                            />
                                          )}
                                          value={
                                            values?.tickets[index]?.paymentMode
                                          }
                                          onChange={(e) => {
                                            setFieldValue(
                                              `tickets[${index}].paymentMode`,
                                              e.target.value
                                            );
                                            setFieldTouched(
                                              `tickets[${index}].paymentMode`,
                                              true,
                                              false
                                            );
                                            if( e.target.value !== "loyaltyAndCash"){
                                              setFieldValue(
                                                `tickets[${index}].priceApply`,
                                                "flat"
                                              );
                                              setFieldValue(
                                                `tickets[${index}].priceValue`,
                                                0
                                              );
                                            }
                                          }}
                                          error={
                                            touched.tickets?.[index]
                                              ?.paymentMode &&
                                            Boolean(
                                              errors.tickets?.[index]
                                                ?.paymentMode
                                            )
                                          }
                                          sx={{ height: 40 }}
                                        >
                                          <MenuItem value="cash">Cash</MenuItem>
                                          <MenuItem value="loyalty">
                                            Loyalty
                                          </MenuItem>
                                          <MenuItem value="loyaltyAndCash">
                                            Loyalty + Cash
                                          </MenuItem>
                                        </Select>
                                        {touched.tickets?.[index]
                                          ?.paymentMode &&
                                        errors.tickets?.[index]?.paymentMode ? (
                                          <FormHelperText>
                                            {
                                              errors.tickets?.[index]
                                                ?.paymentMode
                                            }
                                          </FormHelperText>
                                        ) : null}
                                      </FormControl>
                                    </Grid>
                                    {values.tickets[index].paymentMode === "loyaltyAndCash" && (
                                      <>
                                       <Grid item xs={3}>
                                      <FormControl
                                        fullWidth
                                        error={
                                          touched?.tickets &&
                                          touched?.tickets.length &&
                                          touched?.tickets[index]?.priceApply &&
                                          Boolean(
                                            errors?.tickets &&
                                              errors?.tickets.length &&
                                              errors?.tickets[index]?.priceApply
                                          )
                                        }
                                      >
                                        <InputLabel id="demo-simple-select-label">
                                          Price Apply
                                        </InputLabel>
                                        <Select
                                          as={Select}
                                          name={`tickets[${index}].priceApply`}
                                          label="Price Apply "
                                          IconComponent={() => (
                                            <ArrowDropDownIcon
                                              style={{
                                                marginRight: "18px",
                                                marginTop: "10px",
                                              }}
                                            />
                                          )}
                                          onChange={(e) => {
                                            setFieldValue(
                                              `tickets[${index}].priceApply`,
                                              e.target.value
                                            );
                                            // Other logic if necessary
                                          }}
                                          value={
                                            values?.tickets[index]?.priceApply
                                          }
                                          error={
                                            touched?.tickets &&
                                            touched?.tickets.length &&
                                            touched?.tickets[index]
                                              ?.priceApply &&
                                            Boolean(
                                              errors?.tickets &&
                                                errors?.tickets.length &&
                                                errors?.tickets[index]
                                                  ?.priceApply
                                            )
                                          }
                                          sx={{ height: 40 }}
                                        >
                                          <MenuItem value="flat">Flat</MenuItem>
                                          <MenuItem value="percentage">
                                            Percentage
                                          </MenuItem>
                                        </Select>
                                        {touched?.tickets &&
                                        touched?.tickets.length &&
                                        touched?.tickets[index]?.priceApply &&
                                        errors?.tickets &&
                                        errors?.tickets.length &&
                                        errors?.tickets[index]?.priceApply ? (
                                          <FormHelperText>
                                            {errors.tickets[index].priceApply}
                                          </FormHelperText>
                                        ) : null}
                                      </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Field
                                        as={TextField}
                                        name={`tickets[${index}].priceValue`}
                                        label={
                                          values.tickets[index].priceApply ===
                                          "flat"
                                            ? "Points"
                                            : "Percent"
                                        }
                                        type="number"
                                        fullWidth
                                        error={
                                          touched.tickets?.[index]
                                            ?.priceValue &&
                                          !!errors.tickets?.[index]?.priceValue
                                        }
                                        helperText={
                                          touched.tickets?.[index]
                                            ?.priceValue &&
                                          errors.tickets?.[index]?.priceValue
                                        }
                                      />
                                    </Grid></>
                                    )}
                                  </React.Fragment>
                                )}
                              </Grid>
                              <Grid item xs={6}>
                                <Field
                                  as={TextField}
                                  name={`tickets[${index}].minimumBuyingLimit`}
                                  label="Minimum Buying Limit"
                                  fullWidth
                                  error={
                                    touched.tickets?.[index]
                                      ?.minimumBuyingLimit &&
                                    !!errors.tickets?.[index]
                                      ?.minimumBuyingLimit
                                  }
                                  helperText={
                                    touched.tickets?.[index]
                                      ?.minimumBuyingLimit &&
                                    errors.tickets?.[index]?.minimumBuyingLimit
                                  }
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Field
                                  as={TextField}
                                  name={`tickets[${index}].maximumBuyingLimit`}
                                  label="Maximum Buying Limit"
                                  fullWidth
                                  error={
                                    touched.tickets?.[index]
                                      ?.maximumBuyingLimit &&
                                    !!errors.tickets?.[index]
                                      ?.maximumBuyingLimit
                                  }
                                  helperText={
                                    touched.tickets?.[index]
                                      ?.maximumBuyingLimit &&
                                    errors.tickets?.[index]?.maximumBuyingLimit
                                  }
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                      slotProps={
                                        touched.tickets &&
                                        touched.tickets[index] &&
                                        !!touched.tickets[index].start
                                          ? {
                                              textField: {
                                                error:
                                                  touched.tickets &&
                                                  touched.tickets[index] &&
                                                  !!touched.tickets[index]
                                                    .start &&
                                                  errors.tickets &&
                                                  errors.tickets[index] &&
                                                  errors.tickets[index].start,
                                                helperText:
                                                  touched.tickets &&
                                                  touched.tickets[index] &&
                                                  touched.tickets[index]
                                                    .start &&
                                                  errors.tickets &&
                                                  errors.tickets[index] &&
                                                  errors.tickets[index].start,
                                              },
                                            }
                                          : null
                                      }
                                      format="DD/MM/YYYY"
                                      label="Start Date"
                                      name={`tickets[${index}].start`}
                                      value={
                                        values.tickets[index].start
                                          ? dayjs(values.tickets[index].start)
                                          : null
                                      }
                                      onChange={(newValue) =>
                                        setFieldValue(
                                          `tickets[${index}].start`,
                                          dayjs(newValue).format("YYYY-MM-DD")
                                        )
                                      }
                                      minDate={
                                        eventData?.startDate
                                          ? getMinMaxDate(
                                              values.tickets[index].end,
                                              values.tickets[index].start
                                            ).min
                                          : dayjs().add(0, "day")
                                      }
                                      maxDate={
                                        eventData?.endDate
                                          ? getMinMaxDate(
                                              values.tickets[index].end,
                                              values.tickets[index].start
                                            ).max
                                          : null
                                      }
                                      renderInput={(params) => (
                                        <Field
                                          as={TextField}
                                          {...params}
                                          fullWidth
                                          error={
                                            touched?.tickets[index]?.start &&
                                            Boolean(
                                              errors?.tickets[index]?.start
                                            )
                                          }
                                          helperText={
                                            touched?.tickets[index]?.start &&
                                            errors?.tickets[index]?.start
                                          }
                                        />
                                      )}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              </Grid>
                              <Grid item xs={6}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["TimePicker"]}>
                                    <TimePicker
                                      slotProps={
                                        touched.tickets &&
                                        touched.tickets[index] &&
                                        !!touched.tickets[index].startTime
                                          ? {
                                              textField: {
                                                error:
                                                  touched.tickets &&
                                                  touched.tickets[index] &&
                                                  !!touched.tickets[index]
                                                    .startTime &&
                                                  errors.tickets &&
                                                  errors.tickets[index] &&
                                                  errors.tickets[index].startTime,
                                                helperText:
                                                  touched.tickets &&
                                                  touched.tickets[index] &&
                                                  touched.tickets[index]
                                                    .startTime &&
                                                  errors.tickets &&
                                                  errors.tickets[index] &&
                                                  errors.tickets[index]
                                                    .startTime,
                                              },
                                            }
                                          : null
                                      }
                                      label="Start Time"
                                      name={`tickets[${index}].startTime`}
                                      // minTime={
                                      //   getMinMaxTime(
                                      //     values.endTime,
                                      //     values.startTime
                                      //   ).min
                                      // }
                                      // maxTime={
                                        // getMinMaxTime(
                                        //   values.endTime,
                                        //   values.startTime
                                        // ).max
                                      // }
                                      value={
                                        ticket.startTime
                                          ? dayjs(ticket.startTime, "HH:mm")
                                          : null
                                      }
                                      onChange={(newValue) => {
                                        setFieldValue(
                                          `tickets[${index}].startTime`,
                                          dayjs(newValue).format("HH:mm")
                                        );
                                      }}
                                      viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                      }}
                                      disabled={!!nowChecked[index]}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              </Grid>
                              {/* <Grid item xs={12}>
                                <Grid item xs={2}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        onChange={(e) =>
                                          handleCheckboxChange(
                                            index,
                                            setFieldValue,
                                            "start",
                                            e.target.checked,
                                            eventData
                                          )
                                        }
                                        checked={!!nowChecked[index]}
                                      />
                                    }
                                    label="Now"
                                  />
                                </Grid>
                              </Grid> */}
                              <Grid item xs={6}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                      slotProps={
                                        touched.tickets &&
                                        touched.tickets[index] &&
                                        !!touched.tickets[index].end
                                          ? {
                                              textField: {
                                                error:
                                                  touched.tickets &&
                                                  touched.tickets[index] &&
                                                  !!touched.tickets[index].end &&
                                                  errors.tickets &&
                                                  errors.tickets[index] &&
                                                  errors.tickets[index].end,
                                                helperText:
                                                  touched.tickets &&
                                                  touched.tickets[index] &&
                                                  touched.tickets[index].end &&
                                                  errors.tickets &&
                                                  errors.tickets[index] &&
                                                  errors.tickets[index].end,
                                              },
                                            }
                                          : null
                                      }
                                      label="End Date"
                                      format="DD/MM/YYYY"
                                      name={`tickets[${index}].end`}
                                      value={
                                        values.tickets[index].end
                                          ? dayjs(values.tickets[index].end)
                                          : null
                                      }
                                      onChange={(newValue) =>
                                        setFieldValue(
                                          `tickets[${index}].end`,
                                          dayjs(newValue).format("YYYY-MM-DD")
                                        )
                                      }
                                      minDate={
                                        eventData?.startDate
                                          ? getMinMaxDate(
                                              values.tickets[index].end,
                                              values.tickets[index].start,
                                              "endTime"
                                            ).min
                                          : dayjs().add(0, "day")
                                      }
                                      maxDate={
                                        eventData?.endDate
                                          ? getMinMaxDate(
                                              values.tickets[index].end,
                                              values.tickets[index].start,
                                              "endTime"
                                            ).max
                                          : null
                                      }
                                      renderInput={(params) => (
                                        <Field
                                          as={TextField}
                                          {...params}
                                          fullWidth
                                          error={
                                            touched?.tickets[index]?.end &&
                                            Boolean(errors?.tickets[index]?.end)
                                          }
                                          helperText={
                                            touched?.tickets[index]?.end &&
                                            errors?.tickets[index]?.end
                                          }
                                        />
                                      )}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              </Grid>
                              <Grid item xs={6}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DemoContainer components={["TimePicker"]}>
                                    <TimePicker
                                      slotProps={
                                        touched.tickets &&
                                        touched.tickets[index] &&
                                        !!touched.tickets[index].endTime
                                          ? {
                                              textField: {
                                                error:
                                                  touched.tickets &&
                                                  touched.tickets[index] &&
                                                  !!touched.tickets[index]
                                                    .endTime && 
                                                  errors.tickets &&
                                                  errors.tickets[index] &&
                                                  errors.tickets[index].endTime,
                                                helperText:
                                                  touched.tickets &&
                                                  touched.tickets[index] &&
                                                  touched.tickets[index]
                                                    .endTime &&
                                                  errors.tickets &&
                                                  errors.tickets[index] &&
                                                  errors.tickets[index].endTime,
                                              },
                                            }
                                          : null
                                      }
                                      label="End Time"
                                      name={`tickets[${index}].endTime`}
                                      // minTime={
                                      //   getMinMaxTime(
                                      //     values.endTime,
                                      //     values.startTime,
                                      //     "endTime"
                                      //   ).min
                                      // }
                                      // maxTime={
                                      //   getMinMaxTime(
                                      //     values.endTime,
                                      //     values.startTime,
                                      //     "endTime"
                                      //   ).max
                                      // }
                                      value={
                                        ticket.endTime
                                          ? dayjs(ticket.endTime, "HH:mm")
                                          : null
                                      }
                                      onChange={(newValue) => {
                                        setFieldValue(
                                          `tickets[${index}].endTime`,
                                          dayjs(newValue).format("HH:mm")
                                        );
                                      }}
                                      viewRenderers={{
                                        hours: renderTimeViewClock,
                                        minutes: renderTimeViewClock,
                                        seconds: renderTimeViewClock,
                                      }}
                                      disabled={!!eventEndChecked[index]}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              </Grid>
                              {/* <Grid item xs={12}>
                                <Grid item xs={2}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        onChange={(e) =>
                                          handleCheckboxChange(
                                            index,
                                            setFieldValue,
                                            `end`,
                                            e.target.checked,
                                            eventData
                                          )
                                        }
                                      />
                                    }
                                    checked={!!eventEndChecked[index]}
                                    label="Event End Date "
                                  />
                                </Grid>
                              </Grid> */}
                              {/* <Grid
                                item
                                xs={12}
                                container
                                alignItems="center"
                                spacing={2}
                              >
                                <Grid item xs={12}>
                                  <Typography
                                    variant="subtitle1"
                                    fontWeight="bold"
                                    fontSize={13}
                                  >
                                    Slots
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <FormControl
                                    component="fieldset"
                                    error={Boolean(
                                      touched.tickets?.[index]?.slotType &&
                                      errors.tickets?.[index]?.slotType
                                    )}
                                  >
                                    <RadioGroup
                                      row
                                      name={`tickets[${index}].slotType`}
                                      value={ticket.slotType || ""}
                                      onChange={(event) =>
                                        handleSlotChange(
                                          index,
                                          setFieldValue,
                                          event.target.value
                                        )
                                      }
                                    >
                                      <FormControlLabel
                                        value="duration"
                                        control={<Radio />}
                                        label="Duration"
                                      />
                                      <TextField
                                        label="Duration (in mins)"
                                        type="number"
                                        disabled={
                                          ticket.slotType !== "duration"
                                        }
                                        value={ticket.duration}
                                        onChange={(event) => {
                                          setFieldValue(
                                            `tickets[${index}].duration`,
                                            event.target.value
                                          )
                                          setFieldValue(
                                            `tickets[${index}].count`,
                                            ''
                                          )
                                        }
                                        }
                                        error={
                                          touched.tickets?.[index]?.duration &&
                                          Boolean(
                                            errors.tickets?.[index]?.duration
                                          )
                                        }
                                        helperText={
                                          touched.tickets?.[index]?.duration &&
                                          errors.tickets?.[index]?.duration
                                        }
                                      />
                                      <FormControlLabel
                                        value="count"
                                        control={<Radio />}
                                        label="Count"
                                      />
                                      <TextField
                                        label="Count"
                                        type="number"
                                        disabled={ticket.slotType !== "count"}
                                        value={ticket.count}
                                        onChange={(event) => {
                                          setFieldValue(
                                            `tickets[${index}].count`,
                                            event.target.value
                                          )
                                        }
                                        }
                                        error={
                                          touched.tickets?.[index]?.count &&
                                          Boolean(
                                            errors.tickets?.[index]?.count
                                          )
                                        }
                                        helperText={
                                          touched.tickets?.[index]?.count &&
                                          errors.tickets?.[index]?.count
                                        }
                                      />
                                    </RadioGroup>
                                    {touched.tickets?.[index]?.slotType &&
                                      errors.tickets?.[index]?.slotType && (
                                        <FormHelperText>
                                          {errors.tickets[index].slotType}
                                        </FormHelperText>
                                      )}
                                  </FormControl>
                                </Grid>
                              </Grid> */}
                              {eventData?.eventType === "oneToOne" && (
                                <Grid item xs={6}>
                                  <Field
                                    as={TextField}
                                    name={`tickets[${index}].duration`}
                                    label="Duration (In Minutes)"
                                    fullWidth
                                    type="number"
                                    error={
                                      touched.tickets?.[index]?.duration &&
                                      !!errors.tickets?.[index]?.duration
                                    }
                                    helperText={
                                      touched.tickets?.[index]?.duration &&
                                      errors.tickets?.[index]?.duration
                                    }
                                  />
                                </Grid>
                              )}

                              <Grid item xs={12}>
                                <Autocomplete
                                  multiple
                                  id={`values-autocomplete-${index}`}
                                  value={values.tickets[index].productName}
                                  options={
                                    productList.map(
                                      (item) => ({
                                        id: item.id,
                                        label: item.itemName,
                                      })
                                    )
                                  } // Changed this to brands
                                  getOptionLabel={(option) =>
                                    option.label || option.itemName ||  "Unknown"
                                  }
                                  onChange={(event, newValue) => {
                                    setFieldValue(
                                      `tickets[${index}].productName`,
                                      newValue.map((item) => ({
                                        id: item.id, 
                                        label: item.label
                                      }))
                                    );
                                    setFieldTouched(
                                      `tickets[${index}].productName`,false,true);
                                  }}
                                  onInputChange={(event, newValue) => {
                                    if ((event && event?.type === "reset") || newValue === "") {
                                      setSearchValues(""); // Clear search when input is cleared
                                    } else if (event && event.type === "change") {
                                      setSearchValues(newValue);
                                    }
                                  }}
                                  onBlur={() => {
                                    setSearchValues("");
                                  }}
                                  loading={productLoading}
                                  loadingText="Loading..."
                                  getOptionDisabled={(option) =>
                                      values.tickets[index].productName.some((product) => product.id === option.id)
                                    }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      name={`tickets[${index}].productName`}
                                      label="Product Name"
                                      placeholder="Select"
                                      helperText={
                                        touched.tickets?.[index]?.productName
                                          ? errors.tickets?.[index]?.productName
                                          : ""
                                      }
                                      error={
                                        touched.tickets?.[index]?.productName &&
                                        Boolean(
                                          errors.tickets?.[index]?.productName
                                        )
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                  Ticket Description
                                </Typography>
                                <ReactQuill
                                  theme="snow"
                                  value={ticket.ticketDescription}
                                  onChange={(content) => {
                                    setFieldValue(
                                      `tickets[${index}].ticketDescription`,
                                      content
                                    );
                                  }}
                                />
                                {touched.tickets?.[index]?.ticketDescription &&
                                  errors.tickets?.[index]
                                    ?.ticketDescription && (
                                    <Typography fontSize={12} color="error">
                                      {errors.tickets[index].ticketDescription}
                                    </Typography>
                                  )}
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="h6">
                                  Ticket Visibility in Mobile
                                </Typography>
                                <RadioGroup
                                  name={`tickets[${index}].visibility`}
                                  value={ticket.visibility}
                                  onChange={(event) => {
                                    setFieldValue(
                                      `tickets[${index}].visibility`,
                                      event.target.value
                                    );
                                    // If "alwaysHidden" or "alwaysVisible" is selected, clear hideOnConditions
                                    if (
                                      event.target.value === "alwaysHidden" ||
                                      event.target.value === "alwaysVisible"
                                    ) {
                                      setFieldValue(
                                        `tickets[${index}].hideOnConditions`,
                                        {
                                          closed: false,
                                          soldOut: false,
                                          yetToStart: false,
                                          expired: false,
                                        }
                                      );
                                    }
                                  }}
                                  row
                                >
                                  <FormControlLabel
                                    value="alwaysVisible"
                                    control={<Radio />}
                                    label="Always Visible"
                                  />
                                  <FormControlLabel
                                    value="alwaysHidden"
                                    control={<Radio />}
                                    label="Always Hidden"
                                  />
                                  <FormControlLabel
                                    value="conditional"
                                    control={<Radio />}
                                    label="Hidden When (You must choose at least one option)"
                                  />
                                </RadioGroup>
                                {ticket.visibility === "conditional" && (
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            values.tickets[index]
                                              .hideOnConditions.soldOut
                                          }
                                          onChange={(e) => {
                                            setFieldValue(
                                              `tickets[${index}].hideOnConditions.soldOut`,
                                              e.target.checked
                                            );
                                          }}
                                          name={`tickets[${index}].hideOnConditions.soldOut`}
                                        />
                                      }
                                      label="Ticket sales are sold out or marked as sold out"
                                    />

                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            values.tickets[index]
                                              .hideOnConditions.closed
                                          }
                                          onChange={(e) => {
                                            setFieldValue(
                                              `tickets[${index}].hideOnConditions.closed`,
                                              e.target.checked
                                            );
                                          }}
                                          name={`tickets[${index}].hideOnConditions.closed`}
                                        />
                                      }
                                      label="Ticket class status is closed"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            values.tickets[index]
                                              .hideOnConditions.yetToStart
                                          }
                                          onChange={(e) => {
                                            setFieldValue(
                                              `tickets[${index}].hideOnConditions.yetToStart`,
                                              e.target.checked
                                            );
                                          }}
                                          name={`tickets[${index}].hideOnConditions.yetToStart`}
                                        />
                                      }
                                      label="Ticket sales are yet to start"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            values.tickets[index]
                                              .hideOnConditions.expired
                                          }
                                          onChange={(e) => {
                                            setFieldValue(
                                              `tickets[${index}].hideOnConditions.expired`,
                                              e.target.checked
                                            );
                                          }}
                                          name={`tickets[${index}].hideOnConditions.expired`}
                                        />
                                      }
                                      label="Ticket sales period has expired"
                                    />
                                  </FormGroup>
                                )}
                              </Grid>

                              <Grid item xs={12} display="flex">
                                <Grid item xs={6}>
                                  <Button
                                    variant="outlined"
                                    onClick={() =>
                                      handleDuplicateTicket(
                                        index,
                                        values,
                                        setFieldValue
                                      )
                                    }
                                    className="duplicateBtnStyle"
                                  >
                                    Duplicate
                                  </Button>
                                </Grid>
                                {values?.tickets.length > 1 && (
                                  <Grid item xs={6}>
                                    <Button
                                      variant="outlined"
                                      onClick={() => remove(index)}
                                      className="duplicateBtnStyle"
                                    >
                                      Delete
                                    </Button>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    ))}
                  <Grid item xs={12}>
                    <Button
                      type="button"
                      variant="contained"
                      className="addBtnStyle"
                      onClick={() => push({ ...defaultTicket })}
                    >
                      + Add Another
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </FieldArray>
          <Grid container spacing={1}>
            <Grid container xs={12} sx={{ mt: 4, ml: 2, pb: 2 }} flexDirection={"row-reverse"} gap={3}>
              <MDButton
                type="submit"
                variant="contained"
                circular={true}
                color="black"
              >
                Save
              </MDButton>
              <MDButton onClick={handleClose} circular={true} variant="outlined" color="black">
                Cancel
              </MDButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default TicketForm;
