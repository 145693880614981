import React from 'react';
import { Tooltip } from '@mui/material'; 
import { styled } from '@mui/material/styles'; 
import dayjs from 'dayjs'; 

// Function to format date using dayjs
const formatDate = (dateString) => {
  return dayjs(dateString).format('D  MMM  YYYY'); 
};

// Custom styled Tooltip component with white background
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: '#fff', 
    color: '#000',          
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    fontSize: '14px',        
  },
});

// Custom cell renderer for inward details
const InwardDetailRenderer = (params) => {
  const inwardDetails = params.data.inwardDetail || [];
  const firstDetail = inwardDetails[0]; // Show the first item by default

  // Check if more details exist
  const hasMore = inwardDetails.length > 1;

  return (
    <div>
      <span>
        {firstDetail
          ? `${formatDate(firstDetail.date)} - ${firstDetail.totalQuantity}`
          : '-'}
      </span>
      {hasMore && (
        <CustomTooltip
          title={
            <div>
              {inwardDetails.map((detail, index) => (
                <div key={index} style={{ marginBottom: '5px' }}>
                  {formatDate(detail.date)}: {detail.totalQuantity}
                </div>
              ))}
            </div>
          }
          arrow
        >
          <a href="#" style={{ marginLeft: '5px', color: '#1976d2' }}>
            View more
          </a>
        </CustomTooltip>
      )}
    </div>
  );
};
 export const  headerSalesReport = [
    { headerName: "Vendor", field: "vendor" },
    { headerName: "Brand", field: "brand" },
    { headerName: "Store", field: "store" },
    { headerName: "Item ID", field: "itemId" },
    { headerName: "Product ID", field: "productId" },
    { headerName: "Product Name", field: "productName" },
    { headerName: "Product Variant(Size)", field: "productVariant" },
    { headerName: "Division", field: "division" },
    { headerName: "Section", field: "section" },
    { headerName: "Department", field: "department" },
    { headerName: "Node", field: "node" },
    { headerName: "Order Date", field: "billDate" },
    { headerName: "Bill Number.", field: "billNumber" },
    { headerName: "MRP", field: "mrp" },
    { headerName: "RSP", field: "rsp" },
    { headerName: "Quantity", field: "quantity" },
    { headerName: "Total Selling Price", field: "totalSellingPrice" },
    { headerName: "promotion", field: "promotion" },
    { headerName: "Coupons", field: "coupons" },
    { headerName: "Rewards", field: "rewards" },
    { headerName: "Tax Percent", field: "taxPercent" },
    { headerName: "Tax Amount", field: "taxAmount" },
    { headerName: "Amount Collected", field: "amountCollected" },
    { headerName: "Order Quantity", field: "orderQuantity" },
    { headerName: "Promotion Coupon Rewards", field: "promotions" },
  ];

  export const  headerStockReport = [
    { headerName: "Vendor", field: "vendor" },
    { headerName: "Brand", field: "brand" },
    { headerName: "Store", field: "store" },
    { headerName: "Item ID", field: "itemId" },
    { headerName: "Bracode", field: "barcode" },
    { headerName: "Product ID", field: "productId" },
    { headerName: "Product Name", field: "productName" },
    { headerName: "Node", field: "node" },
    { headerName: "Inward Quantity", field: "inwardQuantity" },
    {
      headerName: "Inward Detail",
      field: "inwardDetail",
      idth: 700,
      cellRenderer: InwardDetailRenderer, 
    },    { headerName: "Available Quantity", field: "availableQuantity" },

  ];

  export const  headerGMVREPORT = [
    { headerName: "Date", field: "date" },
    { headerName: "Brand", field: "brandName" , enableValue:true},
    { headerName: "Sales By MRP", field: "salesByMrp", enableValue:true },
    { headerName: "Selling Price", field: "sellingPrice" },
    { headerName: "Promotion Discount(%)", field: "promotionDiscount" },
    { headerName: "Coupon Discount", field: "couponDiscount" },
    { headerName: "Reward-Burn", field: "rewardsBurn" },
    { headerName: "Amount Collected", field: "amountCollected" },
  ];

  export const  headerEventReport = [
    { headerName: "Event Name", field: "eventName" },
    { headerName: "Event Date", field: "eventDate" },
    { headerName: "Enent Time", field: "eventTime" },
    { headerName: "Ticket Type", field: "ticketType" },
    { headerName: "Category", field: "category" },
    { headerName: "Ticket Sold", field: "ticketsSold" },
    { headerName: "Ticket Amount", field: "ticketAmount" },
    { headerName: "Rewards Burn", field: "rewardsBurn" },
    { headerName: "No. of Participant", field: "numberOfParticipants" },
    { headerName: "Samples Distributed (if applicable)", field: "samplesDistributed" },

  ];

  export const  headerReturnAndRefund = [
    { headerName: "Product ID", field: "productId" },
    { headerName: "Sales Order ID", field: "salesOrderID" },
    { headerName: "Product Name", field: "productName" },
    { headerName: "Amount", field: "amount" },
    { headerName: "Refund Date", field: "refundDate" },
    { headerName: "Refund Status", field: "refundStatus" },
    { headerName: "Returned Reason", field: "returnReason" },

  ];

  export const  headerFootfallReport = [
    { headerName: "Store Name", field: "store.name" },
    { headerName: "Date", field: "date",valueGetter: (params) =>
    params?.data?.date ? dayjs(params?.data?.date).format("DD/MM/YYYY") : "N/A",  },
    { headerName: "From time", field: "fromTime",valueGetter: (params) =>
    params?.data?.date ? dayjs(params?.data?.date).format("HH:MM A") : "N/A",   },
    { headerName: "To Time", field: "tillTime",valueGetter: (params) =>
    params?.data?.date ? dayjs(params?.data?.date).format("HH:MM A") : "N/A",    },
    { headerName: "Total Visitors", field: "count" },

  ];