import { gql } from "@apollo/client";

export const GET_GMV_REPORT = gql`
query GetGmvReport ($filter: GetGMVReportFilter!) {
    getGmvReport(filter: $filter) {
        total
        data {
            id
            date
            brand
            brandName
            salesByMrp
            sellingPrice
            promotionDiscount
            couponDiscount
            rewardsBurn
            amountCollected
        }
    }
}`;

export const GET_SALES_REPORT = gql`query GetSalesReports(
  $take: Int
  $skip: Int,
  $startDate: DateTime
  $endDate: DateTime
  $searchCols: [String!]
  $searchBy: [String!]
  $filter: GetSalesReportFilter
) {
  getSalesReports(
      take: $take
    skip: $skip
    startDate: $startDate
    endDate: $endDate
    searchCols: $searchCols
    searchBy: $searchBy
    filter: $filter
  ) {
      total
      totalPages
      currentPage
      results {
          vendor
          brandId
          brand
          store
          itemId
          productId
          productName
          variantId
          division
          section
          department
          node
          barcode
          billDate
          billNumber
          mrp
          rsp
          quantity
          totalSellingPrice
          promotion
          coupons
          rewards
          taxPercent
          taxAmount
          amountCollected
      }
  }
}`;

export const GET_SALES_QUANTITY = gql`
query GetSalesSummary($startDate:DateTime , $endDate:DateTime, $filter:GetSalesReportFilter) {
  getSalesSummary(startDate: $startDate, endDate: $endDate, filter: $filter) {
      totalOrders
      totalQtySold
      totalSales
      avgOrderValue
      basketSize
      footfall
      creditNoteSale
  }
}
`

  export const GET_RETURN_AND_REFUND_REPORT = gql`query GetReturnAndRefund($filter: GetReturnAndRefundFilter!){
    getReturnAndRefund(filter: $filter){
    total
    data {
        id
        productID
        productName
        amount
        refundStatus
        refundDate
        returnedReason
        salesOrderID
    }
}
}`;


  export const GET_STOCK_REPORT = gql`query GetStockReports(
    $take: Int
    $skip: Int,
    $startDate: DateTime
    $endDate: DateTime
    $searchCols: [String!]
    $searchBy: [String!]
    $filter: GetStockReportFilter
  ) {
    getStockReports(
      take: $take
      skip: $skip
      startDate: $startDate
      endDate: $endDate
      searchCols: $searchCols
      searchBy: $searchBy
      filter: $filter
    ) {
      total
      totalPages
      currentPage
      results {
        vendor
        brandId
        brand
        store
        division
        section
        department
        variantId
        itemId
        barcode
        productId
        productName
        node
        inwardQuantity
        availableQuantity
        inwardDetail {
          date
          totalQuantity
          availableQuantity
        }
      }
    }
  }  
  `;


export const GET_EVENT_REPORT = 
gql`query GetEventReports ($filter : GetEventsReportFilter!){
  getEventReports(filter: $filter) {
    total
    data {
        id
        eventName
        eventDate
        eventTime
        ticketType
        category
        ticketsSold
        ticketAmount
        rewardsBurn
        numberOfParticipants
        samplesDistributed
    }
}
}`;

export const GET_FOOTFALL_REPORT = gql`query Footfalls(
  $take: Int
  $skip: Int
  $fromDate: DateTime!
  $tillDate: DateTime!
  $storeId: String
) {
  Footfalls(
      listFootfallFilter: {
          take: $take
          skip: $skip
          fromDate: $fromDate
          tillDate: $tillDate
          storeId: $storeId
      }
  ) {
      total
      results {
          totalFootfall
          footFall {
              date
              fromTime
              tillTime
              count
              imageUrl
              createdAt
              updatedAt
              store {
                id
                name
            }
          }
      }
  }
}
`;