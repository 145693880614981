import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Autocomplete, Box, FormHelperText } from "@mui/material";
import {
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  MobileDateTimePicker,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import { GET_STORES_LIST } from "services/store-service";
import { vmServiceClient } from "graphql/client";
import { useMutation, useQuery } from "@apollo/client";
import ReactQuill from "react-quill";
import { useNotification } from "context";
import "react-quill/dist/quill.snow.css"; // import styles
import MDButton from "components/MDButton";
import Loader from "components/MDLoader";
import { CREATE_DEVICE } from "services/advertisment-service";
import { UPDATE_DEVICE } from "services/advertisment-service";
import { dashboardServiceClient } from "graphql/client";
import { LIST_ALL_DEVICE } from "services/advertisment-service";
import { getFlatIdMap } from "./utils";
import { GET_STORES_LIST_NEW } from "services/store-service";

export const validationSchema = Yup.object({
  deviceName: Yup.string()
    .required("Device Name Name is required")
    .max(50, "Maximum 50 characters allowed"),
  deviceType: Yup.string().required("Device Type is required"),
  screenSize: Yup.string().required("Screen Size is required"),
  resolutionWidth: Yup.number()
    .typeError("Should be  a number")
    .max(9999, "Maximum 4 digits allowed"),
  resolutionHeight: Yup.number()
    .typeError("Should be  a number")
    .max(9999, "Maximum 4 digits allowed"),
  orientation: Yup.string().required("Orientation is required"),
  brandName: Yup.string().required("Brand Name is required"),
  manufacturer: Yup.string()
    .required("Manufacturer is required")
    .max(20, "Maximum 20 characters allowed"),
  purchasedDate: Yup.date().required("Purchase date required"),
  acquireDate: Yup.date().required("Acquired date required"),
  warrantyStartDate: Yup.date().required("Warranty Start date required"),
  warrantyEndDate: Yup.date().required("Warranty End date required"),
  serialNumber: Yup.string()
    .required("Serial Number is required")
    .max(20, "Maximum 20 Character Allowed"),
  softwareVersion: Yup.string()
    .required("Software Version is required")
    .max(20, "Maximum 20 Character Allowed"),
  barcode: Yup.string().required("Barcode is required"),
  configurationDetail: Yup.string().required(
    "Configuration Detail is required"
  ),
  supportContactInformation: Yup.string().required(
    "Support Contact Information is required"
  ),
  // storeId: Yup.string().required("Store is required"),
  // // section: Yup.string().required("Section is required"),
  // // subSection: Yup.string().required("Sub-Section is required"),
  // locationId: Yup.string().required("Location is required"),
  // subLocationId: Yup.string().required("Sub Location is required"),
  // assetTypeCode: Yup.string().required("Asset type code is required"),
  // subLocationTypeId: Yup.string().required("Sub Location Type is required"),
  isShared: Yup.boolean(),
  isActive: Yup.boolean(),
  // locationId: Yup.string().required("Location ID is required"),
  // isDeleted: Yup.boolean(),
});

const useMasterDeviceMutation = (
  deviceData,
  handleClose,
  setNotification,
  storeList,
  callback = null
) => {
  const mutationOptions = {
    client: vmServiceClient,
    // refetchQueries: [{
    //     query: LIST_ALL_DEVICE, variables: {
    //         "page": 1,
    //         "limit": 20,
    //         "sortBy": "updatedAt",
    //         "order": "DESC",
    //     },
    //     fetchPolicy: "network-only",
    // }],
  };
  console.log(deviceData, "deviceData");
  const [createDeviceMutation] = useMutation(CREATE_DEVICE, mutationOptions);
  const [updateDeviceMutation] = useMutation(UPDATE_DEVICE, mutationOptions);

  const handleSubmit = (values) => {
    const payload = {
      deviceName: values.deviceName,
      deviceType: values.deviceType,
      screenSize: values.screenSize,
      screenResolution: `${values.resolutionWidth} x ${values.resolutionHeight}`,
      brandName: values.brandName,
      purchaseDate: values.purchasedDate,
      acquireDate: values.acquireDate,
      warrentyStartDate: values.warrantyStartDate,
      warrentyEndDate: values.warrantyEndDate,
      serialNumber: values.serialNumber,
      manufacturer: values.manufacturer,
      // status: values,
      orientation: values.orientation,
      softwareFirmwareVersion: values.softwareVersion,
      configurationDetails: values.configurationDetail,
      assetTagBarcode: values.barcode,
      supportContact: values.supportContactInformation,
      // storeId: values.storeId,
      // section: values.section,
      // subSection: values.subSection,
      // locationId: values.locationId,
      // subLocationId: values.subLocationId,
      // subLocationTypeId: values.subLocationTypeId,
      // assetTypeCode:values.assetTypeCode,
      // location: values.location,
      // locationId: values.locationId,
      isActive: values.isActive || false,
      isShared: values.isShared,
    };

    const mutation =
      deviceData && deviceData.uuid
        ? updateDeviceMutation
        : createDeviceMutation;
    const mutationInput =
      deviceData && deviceData.uuid
        ? { input: { uuid: deviceData.uuid, ...payload } }
        : { input: payload };

    mutation({ variables: mutationInput })
      .then((res) => {
        console.log(
          res.data[deviceData && deviceData.uuid ? "update" : "create"]
        );

        handleClose();
        setNotification({
          color: "success",
          isVisible: true,
          message:
            deviceData && deviceData?.uuid
              ? "Device  updated successfully"
              : "Device  created successfully",
        });
        if (callback) callback();
      })
      .catch((error) => {
        console.error("Error:", error.message);
        setNotification({
          color: "error",
          isVisible: true,
          message: error.message || "Something went wrong",
        });
      });
  };

  return handleSubmit;
};

const DeviceForm = ({ deviceData, user, handleClose, callback }) => {
  const { setNotification } = useNotification();
  const screenSizes = [
    { value: "TEN", display: "10" },
    { value: "THIRTYTWO", display: "32" },
    { value: "FOURTY", display: "40" },
    { value: "FOURTYTHREE", display: "43" },
    { value: "FIFTY", display: "50" },
    { value: "FIFTYFIVE", display: "55" },
    { value: "SEVENTYFIVE", display: "75" },
    { value: "FOURTYTHREEINT", display: "43 INT" },
    { value: "SIXTYFIVEINT", display: "65 INT" },
    { value: "FIFTYVW", display: "50 VW" },
  ];
  const screenSizeLookup = {
    10: "TEN",
    32: "THIRTYTWO",
    40: "FOURTY",
    43: "FOURTYTHREE",
    50: "FIFTY",
    55: "FIFTYFIVE",
    75: "SEVENTYFIVE",
    "43 INT": "FOURTYTHREEINT",
    "65 INT": "SIXTYFIVEINT",
    "50 VW": "FIFTYVW",
  };

  const [resolutionWidth, resolutionHeight] = deviceData?.screenResolution
    ? deviceData.screenResolution.split("X").map(Number)
    : [null, null];

  const {
    loading: storeLoading,
    error: storeError,
    data: storeList,
  } = useQuery(GET_STORES_LIST_NEW, {
    client: dashboardServiceClient,
    variables: { input: { take: 100, skip: 0 } },
  });
  const storeIdMap = React.useMemo(
    () =>
      getFlatIdMap(
        storeList?.stores?.results?.filter((store) => !store.isDeleted) || []
      ),
    [storeList]
  );
  // const {
  //     selectedStore,
  //     setSelectedStore,
  //     locations,
  //     selectedLocation,
  //     setSelectedLocation,
  //     subLocations,
  //     selectedSubLocation,
  //     setSelectedSubLocation,
  //     assetTypeBatchCodes,
  //     selectedAssetTypeBatchCode,
  //     setSelectedAssetTypeBatchCode,
  //     assetTypeCodes,
  //     selectedAssetTypeCode,
  //     setSelectedAssetTypeCode,
  //     storeLoading,
  //     storeError,
  //     storeList,
  //     loading
  // } = useStoreSelection({storeId:deviceData?.storeId, locationId:deviceData?.locationId, subLocationId:deviceData?.subLocationId, subLocationTypeId:deviceData?.subLocationTypeId, assetTypeCode:deviceData?.assetTypeCode});
  // const {
  //     getStoreDropDown,
  //     getLocationDropDown,
  //     getSubLocationDropDown,
  //     getSubLocationTypeDropDown,
  //     getAssetTypeDropDown,
  //     storeLoading,
  //     storeError,
  //     storeList,
  //     loading
  // } = useStoreForm({data: deviceData})
  const handleSubmit = useMasterDeviceMutation(
    deviceData,
    handleClose,
    setNotification,
    [],
    callback
  );
  // if (storeLoading || loading) return <><Loader /></>;
  // if (storeError) return <p>Error: {storeError.message}</p>;
  return (
    <Formik
      initialValues={{
        deviceName: deviceData?.deviceName || "",
        deviceType: deviceData?.deviceType || "",
        screenSize: deviceData?.screenSize
          ? screenSizeLookup[deviceData.screenSize] || deviceData.screenSize
          : "",
        resolutionWidth: resolutionWidth || "",
        resolutionHeight: resolutionHeight || "",
        orientation: deviceData?.orientation || "",
        brandName: deviceData?.brandName || "",
        manufacturer: deviceData?.manufacturer || "",
        purchasedDate: deviceData?.purchaseDate
          ? dayjs(deviceData.purchaseDate)
          : null,
        acquireDate: deviceData?.acquireDate
          ? dayjs(deviceData.acquireDate)
          : null,
        warrantyStartDate: deviceData?.warrentyStartDate
          ? dayjs(deviceData.warrentyStartDate)
          : null,
        warrantyEndDate: deviceData?.warrentyEndDate
          ? dayjs(deviceData.warrentyEndDate)
          : null,
        serialNumber: deviceData?.serialNumber || "",
        softwareVersion: deviceData?.softwareFirmwareVersion || "",
        barcode: deviceData?.assetTagBarcode || "",
        configurationDetail: deviceData?.configurationDetails || "",
        supportContactInformation: deviceData?.supportContact || "",
        // storeId: deviceData?.storeId || "",
        // locationId: deviceData?.locationId || "",
        // subLocationId: deviceData?.subLocationId || "",
        // subLocationTypeId: deviceData?.subLocationTypeId || "",
        // assetTypeCode:deviceData?.assetTypeCode || "",
        isActive: deviceData?.isActive || false,
        isShared: deviceData?.isShared || false,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values);
      }}
    >
      {({
        values,
        setFieldValue,
        setFieldTouched,
        errors,
        touched,
        validateField,
        isSubmitting,
        handleChange,
        handleBlur,
      }) => {
        return (
          <Form>
            {console.log(errors, values, "errors")}
            <Grid container spacing={2} p={2}>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="deviceName"
                  label="Device Name"
                  fullWidth
                  error={touched.deviceName && !!errors.deviceName}
                  helperText={touched.deviceName && errors.deviceName}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Device Type</InputLabel>
                  <Field
                    as={Select}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="deviceType"
                    label="Device Type"
                    IconComponent={() => (
                      <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                    )}
                    sx={{ height: 40 }}
                    value={values.deviceType}
                    onChange={(e) => {
                      setFieldValue("deviceType", e.target.value);
                      setFieldTouched(`deviceType`, true, false);
                      validateField(`deviceType`, e.target.value);
                    }}
                    helperText={touched.deviceType && errors.deviceType}
                    error={touched.deviceType && Boolean(errors.deviceType)}
                  >
                    <MenuItem key="LED_TV" value="LED_TV">
                      LED TV
                    </MenuItem>
                    ,
                    <MenuItem key="IPS_PANEL" value="IPS_PANEL">
                      IPS PANEL
                    </MenuItem>
                    ,
                  </Field>
                  {touched.deviceType && errors.deviceType && (
                    <Typography fontSize={12} color="error">
                      {errors.deviceType}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel>Screen Size</InputLabel>
                  <Field
                    as={Select}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="screenSize"
                    label="Screen Size"
                    IconComponent={() => (
                      <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                    )}
                    sx={{ height: 40 }}
                    value={values?.screenSize}
                    onChange={(e) => {
                      setFieldValue("screenSize", e.target.value);
                      setFieldTouched(`screenSize`, true, false);
                      validateField(`screenSize`, e.target.value);
                    }}
                    helperText={touched.screenSize && errors.screenSize}
                    error={touched.screenSize && Boolean(errors.screenSize)}
                  >
                    {screenSizes.map((size) => (
                      <MenuItem key={size.value} value={size.value}>
                        {size.display}
                      </MenuItem>
                    ))}
                  </Field>
                  {touched.screenSize && errors.screenSize && (
                    <Typography fontSize={12} color="error">
                      {errors.screenSize}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <Field
                  as={TextField}
                  name="resolutionWidth"
                  label="Resolution Width"
                  fullWidth
                  error={touched.resolutionWidth && !!errors.resolutionWidth}
                  helperText={touched.resolutionWidth && errors.resolutionWidth}
                />
              </Grid>
              <Grid item xs={0.5} mt={1}>
                <Typography>x</Typography>
              </Grid>
              <Grid item xs={2}>
                <Field
                  as={TextField}
                  name="resolutionHeight"
                  label="Resolution Height"
                  fullWidth
                  error={touched.resolutionHeight && !!errors.resolutionHeight}
                  helperText={
                    touched.resolutionHeight && errors.resolutionHeight
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel>Orientation</InputLabel>
                  <Field
                    as={Select}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="orientation"
                    label="Orientation"
                    IconComponent={() => (
                      <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                    )}
                    sx={{ height: 40 }}
                    value={values.orientation}
                    onChange={(e) => {
                      setFieldValue("orientation", e.target.value);
                      setFieldTouched(`orientation`, true, false);
                      validateField(`orientation`, e.target.value);
                    }}
                    helperText={touched.orientation && errors.orientation}
                    error={touched.orientation && Boolean(errors.orientation)}
                  >
                    <MenuItem key="HORIZONTAL" value="HORIZONTAL">
                      Horizontal
                    </MenuItem>
                    <MenuItem key="VERTICAL" value="VERTICAL">
                      Vertical
                    </MenuItem>
                  </Field>
                  {touched.orientation && errors.orientation && (
                    <Typography fontSize={12} color="error">
                      {errors.orientation}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="brandName"
                  label="Brand Name"
                  fullWidth
                  error={touched.brandName && !!errors.brandName}
                  helperText={touched.brandName && errors.brandName}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="manufacturer"
                  label="Manufacturer"
                  fullWidth
                  error={touched.manufacturer && !!errors.manufacturer}
                  helperText={touched.manufacturer && errors.manufacturer}
                />
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      slotProps={
                        errors.purchasedDate
                          ? {
                              textField: {
                                error:
                                  !!touched.purchasedDate &&
                                  errors.purchasedDate,
                                helperText: errors.purchasedDate,
                              },
                            }
                          : null
                      }
                      label="Purchase Date"
                      format="DD/MM/YYYY"
                      name="purchasedDate"
                      value={
                        values.purchasedDate
                          ? dayjs(values.purchasedDate)
                          : null
                      }
                      onChange={(newValue) =>
                        setFieldValue(
                          "purchasedDate",
                          dayjs(newValue).format("YYYY-MM-DD")
                        )
                      }
                      // disabled={!values.storeId}
                      renderInput={(params) => (
                        <Field
                          as={TextField}
                          {...params}
                          fullWidth
                          error={
                            touched?.purchasedDate &&
                            Boolean(errors?.purchasedDate)
                          }
                          helperText={
                            touched?.purchasedDate && errors?.purchasedDate
                          }
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      slotProps={
                        errors.acquireDate
                          ? {
                              textField: {
                                error:
                                  !!touched.acquireDate && errors.acquireDate,
                                helperText: errors.acquireDate,
                              },
                            }
                          : null
                      }
                      label="Acquired Date"
                      format="DD/MM/YYYY"
                      name="acquireDate"
                      value={
                        values.acquireDate ? dayjs(values.acquireDate) : null
                      }
                      onChange={(newValue) =>
                        setFieldValue(
                          "acquireDate",
                          dayjs(newValue).format("YYYY-MM-DD")
                        )
                      }
                      renderInput={(params) => (
                        <Field
                          as={TextField}
                          {...params}
                          fullWidth
                          error={
                            touched?.acquireDate && Boolean(errors?.acquireDate)
                          }
                          helperText={
                            touched?.acquireDate && errors?.acquireDate
                          }
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      slotProps={
                        errors.warrantyStartDate
                          ? {
                              textField: {
                                error:
                                  !!touched.warrantyStartDate &&
                                  errors.warrantyStartDate,
                                helperText: errors.warrantyStartDate,
                              },
                            }
                          : null
                      }
                      label="Warranty Start Date"
                      format="DD/MM/YYYY"
                      name="warrantyStartDate"
                      value={
                        values.warrantyStartDate
                          ? dayjs(values.warrantyStartDate)
                          : null
                      }
                      onChange={(newValue) =>
                        setFieldValue(
                          "warrantyStartDate",
                          dayjs(newValue).format("YYYY-MM-DD")
                        )
                      }
                      renderInput={(params) => (
                        <Field
                          as={TextField}
                          {...params}
                          fullWidth
                          error={
                            touched?.warrantyStartDate &&
                            Boolean(errors?.warrantyStartDate)
                          }
                          helperText={
                            touched?.warrantyStartDate &&
                            errors?.warrantyStartDate
                          }
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      slotProps={
                        errors.warrantyEndDate
                          ? {
                              textField: {
                                error:
                                  !!touched.warrantyEndDate &&
                                  errors.warrantyEndDate,
                                helperText: errors.warrantyEndDate,
                              },
                            }
                          : null
                      }
                      format="DD/MM/YYYY"
                      label="Warranty End Date"
                      name="warrantyEndDate"
                      value={
                        values.warrantyEndDate
                          ? dayjs(values.warrantyEndDate)
                          : null
                      }
                      onChange={(newValue) =>
                        setFieldValue(
                          "warrantyEndDate",
                          dayjs(newValue).format("YYYY-MM-DD")
                        )
                      }
                      renderInput={(params) => (
                        <Field
                          as={TextField}
                          {...params}
                          fullWidth
                          error={
                            touched?.warrantyEndDate &&
                            Boolean(errors?.warrantyEndDate)
                          }
                          helperText={
                            touched?.warrantyEndDate && errors?.warrantyEndDate
                          }
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="serialNumber"
                  label="Serial Number"
                  fullWidth
                  error={touched.serialNumber && !!errors.serialNumber}
                  helperText={touched.serialNumber && errors.serialNumber}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="softwareVersion"
                  label="Software Version"
                  fullWidth
                  error={touched.softwareVersion && !!errors.softwareVersion}
                  helperText={touched.softwareVersion && errors.softwareVersion}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="barcode"
                  label="Asset Tag/Barcode"
                  fullWidth
                  error={touched.barcode && !!errors.barcode}
                  helperText={touched.barcode && errors.barcode}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Configuration Detail</Typography>
                <ReactQuill
                  theme="snow"
                  value={values.configurationDetail}
                  onChange={(content) => {
                    setFieldValue("configurationDetail", content);
                  }}
                />
                {touched?.configurationDetail && errors?.configurationDetail && (
                  <Typography fontSize={12} color="error">
                    {errors.configurationDetail}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="supportContactInformation"
                  label="Support Contact Information"
                  fullWidth
                  error={
                    touched.supportContactInformation &&
                    !!errors.supportContactInformation
                  }
                  helperText={
                    touched.supportContactInformation &&
                    errors.supportContactInformation
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="store"
                  label="Store"
                  value={storeIdMap[deviceData?.storeId]?.name}
                  disabled
                  sx={{ backgroundColor: "#F0F2F5" }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="location"
                  label="Location"
                  value={storeIdMap[deviceData?.locationId]?.locationName}
                  disabled
                  sx={{ backgroundColor: "#F0F2F5" }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="subLocation"
                  label="Sub Location"
                  value={storeIdMap[deviceData?.subLocationId]?.name}
                  disabled
                  sx={{ backgroundColor: "#F0F2F5" }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="subLocationType"
                  label="Asset type batch code"
                  value={storeIdMap[deviceData?.subLocationTypeId]?.batchCode}
                  disabled
                  sx={{ backgroundColor: "#F0F2F5" }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="assetTypeCode"
                  label="Asset Type Code"
                  value={storeIdMap[deviceData?.assetTypeCode]?.code}
                  disabled
                  sx={{ backgroundColor: "#F0F2F5" }}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="fixture"
                  label="Fixture"
                  value={deviceData?.fixture?.code}
                  disabled
                  sx={{ backgroundColor: "#F0F2F5" }}
                  fullWidth
                />
              </Grid>

              <Grid item container spacing={2} alignItems="center" xs={12}>
                <Grid item>
                  <Typography variant="h7" fontWeight="bold" fontSize="13px">
                    Activate
                  </Typography>
                </Grid>
                <Grid item>
                  <Switch
                    checked={values.isActive}
                    onChange={(event) =>
                      setFieldValue("isActive", event.target.checked)
                    }
                    name="isActive"
                    color="primary"
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2} alignItems="center" xs={12}>
                <Grid item>
                  <Typography variant="h7" fontWeight="bold" fontSize="13px">
                    Shared
                  </Typography>
                </Grid>
                <Grid item>
                  <Switch
                    checked={values.isShared}
                    onChange={(event) =>
                      setFieldValue("isShared", event.target.checked)
                    }
                    name="isShared"
                    color="primary"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid
                  container
                  xs={12}
                  sx={{ mt: 4, ml: 2, pb: 2 }}
                  flexDirection={"row-reverse"}
                  gap={3}
                >
                  <MDButton
                    type="submit"
                    variant="contained"
                    circular={true}
                    color="black"
                  >
                    Save
                  </MDButton>
                  <MDButton
                    onClick={handleClose}
                    circular={true}
                    variant="outlined"
                    color="black"
                  >
                    Cancel
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DeviceForm;
