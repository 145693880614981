import React, { useContext,useEffect } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { GET_SALES_QUANTITY } from 'services/report-service';
import { reportServiceClient } from 'graphql/client';
import { ReportsFilter } from '.';
import { useLazyQuery } from '@apollo/client';


const getColor = (label) => {
    switch (label) {
        case "Total No of Orders":
            return "#9c27b0"; // Purple
        case "Total Quantity Sold":
            return "#f44336"; // Red
        case "Total Sales":
            return "#ff9800"; // Orange
        case "Average Order Value":
            return "#4caf50"; // Green
        case "Basket Size":
            return "#2196f3"; // Blue
        case "Footfall":
            return "#4caf50"; // Green
        case "Credit Note Sale":
            return "#9c27b0"; // Purple
        default:
            return "#000"; // Default color if label is unknown
    }
};

// Stateless component for rendering individual statistic card
const StatCard = ({ label, value, fromTime, toTime, tabValue }) => {
    const color = getColor(label); // Get color dynamically based on label

    return (
        <Grid item xs={12} sm={6} md={4} lg={2}>
            <Box
                sx={{
                    p: 2,
                    textAlign: 'center',
                    backgroundColor: '#f9f9f9',
                    borderRadius: 2,
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.05)',
                    height: '60px',
                    width: tabValue===1 ? "140px" : "400px",
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    // ml:"10px",
                    mt:"10px",
                }}
            >
                <Typography fontSize="12px" fontFamily="Montserrat">
                    {label} {fromTime ? "(From: "+fromTime+" - To: "+toTime+")" : ""}
                </Typography>
                <Typography fontSize="12px" fontFamily="Montserrat" sx={{ color: color, fontWeight: 600 }}>
                    {value}
                </Typography>
            </Box>
        </Grid>
    );
};

// Main component to render all statistic cards
const StatsCards = ({ displayData}) => {
    const { getStockReport, tabValue, startDate, endDate, selectedBrand, selectedStore } = useContext(ReportsFilter)

    const [getSalesQuantity, { loading: salesLoading, data: salesData }] = useLazyQuery(GET_SALES_QUANTITY, {
        client: reportServiceClient,
    });
    useEffect(() => {
        if (tabValue === 1) {
            getSalesQuantity({
                variables: {
                    startDate: startDate,
                    endDate: endDate,
                    filter: {
                        storeId: selectedStore,
                        brandId: selectedBrand,
                    }
                }
            })
        }
    }, [tabValue, startDate, endDate, selectedBrand, selectedStore, getSalesQuantity])
    const statsData = salesData?.getSalesSummary ? [
        { label: "Total No of Orders", value: salesData?.getSalesSummary.totalOrders || 0 },
        { label: "Total Quantity Sold", value: salesData?.getSalesSummary.totalQtySold || 0 },
        { label: "Total Sales", value: salesData?.getSalesSummary.totalSales || 0 },
        { label: "Average Order Value", value: salesData?.getSalesSummary.avgOrderValue || 0 },
        { label: "Basket Size", value: salesData?.getSalesSummary.basketSize || 0 },
        { label: "Footfall", value: salesData?.getSalesSummary.footfall || 0 },
        { label: "Credit Note Sale", value: salesData?.getSalesSummary.creditNoteSale || 0 },
    ] : [
        // If no data yet, use default/fallback values
        { label: "Total No of Orders", value: 0 },
        { label: "Total Quantity Sold", value: 0 },
        { label: "Total Sales", value: 0 },
        { label: "Average Order Value", value: 0 },
        { label: "Basket Size", value: 0 },
        { label: "Footfall", value: 0 },
        { label: "Credit Note Sale", value: 0 },
    ];
    console.log(salesData,"sales")
    return (
        <>
            {console.log(displayData)}
        {
            tabValue === 1 ? statsData.map(({ label, value }, index) => (
                    <StatCard key={index} label={label} value={value} tabValue={tabValue} />
                    )) : displayData.map(({ label, value, fromTime, toTime }, index) => (
                <StatCard key={index} label={label} value={value} tabValue={tabValue} fromTime={fromTime} toTime={toTime} />
            ))
        }
        </>

    )

}
export default StatsCards;
