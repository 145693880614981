import React, { useContext, useEffect, useState } from 'react'
import AdsTable from '../adsTable';
import ImageSelect from '../imageMaster/imageSelect';
import { NewImageButton } from '../imageMaster';
import { ReactComponent as previewIcon } from "assets/images/previewIcon.svg";
import { ReactComponent as commentIcon } from "assets/images/commentIcon.svg";
import { ReactComponent as downloadIcon } from "assets/images/downloadIcon.svg";
import { ReactComponent as deleteIcon } from "assets/images/deleteIcon.svg";
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, FormControl, Icon, IconButton, MenuItem, Select, TablePagination, Typography } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { vmServiceClient } from 'graphql/client';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { KeyboardArrowDown } from '@mui/icons-material';
import { DropDownSelectionContext } from '..';
import { LIST_MAP_FIXTURE_IMAGE_SCHEDULES } from 'services/advertisment-service';
import { UPDATE_MAP_FIXTURE_IMAGE_SCHEDULE } from 'services/advertisment-service';
import { REMOVE_MAP_FIXTURE_IMAGE_SCHEDULE } from 'services/advertisment-service';
import { UPDATE_IMAGE_SCHEDULE } from 'services/advertisment-service';
import { LIST_IMAGE_SCHEDULES } from 'services/advertisment-service';
import { CREATE_IMAGE_SCHEDULE } from 'services/advertisment-service';
import { REMOVE_IMAGE_SCHEDULE } from 'services/advertisment-service';
import { GET_ASSET_MAPPING_LIST_NEW } from 'services/brand-service';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MAP_FIXTURE_BRAND_IMAGE } from 'services/advertisment-service';
import { CREATE_MAP_FIXTURE_BRAND_IMAGE } from 'services/advertisment-service';
import { UPDATE_MAP_FIXTURE_BRAND_IMAGE } from 'services/advertisment-service';
import { REMOVE_MAP_FIXTURE_BRAND_IMAGE } from 'services/advertisment-service';
import useStoreSelection from 'layouts/device-Master/storeSelectionHook';
import { useNotification } from 'context';

const FixtureImageMapTable = ({fixtureId, brandFixtureDevice, selectedBrandId, user, Brand, brandId}) =>{
    const { setNotification } = useNotification();
    const [key,setKey] = useState(brandFixtureDevice?.uuid + Date.now())
    const { data:fixtureBrandImageData } = useQuery(MAP_FIXTURE_BRAND_IMAGE, {
        client: vmServiceClient,
        variables: {
            page: 1,
            limit: 100,
            relations: ["imageMaster", "mapDeviceFixtureBrand"],
            filter: {
                mapDeviceFixtureBrand:{
                    uuid: brandFixtureDevice?.uuid
                },
                fixtureId
            }
        }
    });

    const fixtureBrandImageMutationOptions = {
        refetchQueries: [
            {
              query: MAP_FIXTURE_BRAND_IMAGE,
              variables: {
                "page": 1,
                "limit": 100,
                relations: ["imageMaster", "mapDeviceFixtureBrand"],
                filter: {
                    mapDeviceFixtureBrand:{
                        uuid: brandFixtureDevice?.uuid
                    },
                    fixtureId
                }
              },
            },
          ],
    }
    const [createFixtureBrandImageMutation] = useMutation(CREATE_MAP_FIXTURE_BRAND_IMAGE,{
        client:vmServiceClient,
        ...fixtureBrandImageMutationOptions
    })
    const [updateFixtureBrandImageMutation] = useMutation(UPDATE_MAP_FIXTURE_BRAND_IMAGE,{
        client:vmServiceClient,
        ...fixtureBrandImageMutationOptions
    })
    const [removeFixtureBrandImageMutation] = useMutation(REMOVE_MAP_FIXTURE_BRAND_IMAGE,{
        client:vmServiceClient,
        ...fixtureBrandImageMutationOptions
    })
    const columns = [
        { field: "uuid", headerName: "ID", width: 130, flex: 1 },
        {
            field: "brandId",
            headerName: "Brand Name",
            width: 130,
            flex: 1,
            renderCell: (params) => {
              const brand = Brand?.find((brand) => brand.id === params?.row?.imageMaster?.[0]?.brandId);
              return (
                <span>{brand?.name||'Uknown Brand'}</span>
              );
            },
          },
          {
            field: "name",
            headerName: "Name",
            width: 130,
            flex: 1,
            renderCell: (params) => {
                return (
                  <span>{params?.row?.imageMaster?.[0]?.name||'Uknown Image'}</span>
                );
              },
        },
        {
        field: 'startDateTime',
        headerName: 'From time',
        width: 250,
        headerAlign:'center',
        align:'center',
        renderCell:(params)=> user && (user?.role=='admin'||true)? //remove this true to allow only admin to edit dates
        <LocalizationProvider  dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker sx={{"& fieldset": { p: 0, border:0 },"& input":{border:1, borderColor:'#eaeaea', cursor:'pointer'}}} onAccept={(value)=>handleDateTimeEdit({fromTime:value},params.row)}
                value={dayjs(params?.row?.fromTime)} format='DD/MM/YYYY hh:mm a ' />
        </LocalizationProvider> : 
        <>{dayjs(params?.row?.fromTime).format('DD/MM/YYYY hh:mm a')}</>
        },
        {
        field: 'endDateTime',
        headerName: 'To time',
        width: 250,
        headerAlign:'center',
        align:'center',
        renderCell:(params)=> user && (user?.role=='admin'||true)? //remove this true to allow only admin to edit dates
        <LocalizationProvider  dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker sx={{"& fieldset": { p: 0, border:0 },"& input":{border:1, borderColor:'#eaeaea', cursor:'pointer'}}} onAccept={(value)=>handleDateTimeEdit({toTime:value},params.row)}
                value={dayjs(params?.row?.toTime)} format='DD/MM/YYYY hh:mm a' />
        </LocalizationProvider> :
        <>{dayjs(params?.row?.toTime).format('DD/MM/YYYY hh:mm a ')}</>
        },
        
        {
        field: 'status',
        headerName: 'Status',
        width: 200,
        renderCell: (params) => {
            if (user && user?.role !== "admin") {
                return (
                <Chip
                    label={params.row.isActive? 'Active':'Inactive'}
                    color={
                    params.row.isActive
                        ? "success"
                        : "error"
                    }
                    size="small"
                />
                );
            }
    
            return (
                <FormControl fullWidth >
                <Select 
                    onChange={(e) =>
                    handleStatusChange(params.row.uuid, e.target.value)
                    }
                    defaultValue={params.row.isActive}
                    value={params.row.isActive}
                    style={{ width: "100%", padding: 10, borderRadius: 6,height:32, }}
                    id="status-select"
                    IconComponent={()=><KeyboardArrowDown sx={{fontSize:24, width:24, height:24}} />}
                    
                >
                    <MenuItem value={true}>ACTIVE</MenuItem>
                    <MenuItem value={false}>INACTIVE</MenuItem>
                </Select>
                </FormControl>
            );
            },
        },
        {
        field: 'actions',
        headerName: 'Actions',
        width: 200,
        renderCell: (params) => (
            <Box sx={{ "& button": { mr: 1 } }}>
            <IconButton
                aria-label="edit"
                onClick={() => handleEditClick(params?.row)}
            >
                <Icon component={previewIcon} fontSize="small" />
            </IconButton>
            <IconButton aria-label="save" disabled sx={{opacity: 0.5}} onClick={() => alert("Clicked")}>
                <Icon component={commentIcon} fontSize="small" />
            </IconButton>
            <IconButton
                aria-label="download"
                onClick={() =>
                handleDownloadClick(params?.row)
                }
            >
                <Icon component={downloadIcon} fontSize="small" />
            </IconButton>
            <IconButton
                aria-label="delete"
                onClick={() => handleDeleteClick(params?.row)}
            >
                <Icon component={deleteIcon} fontSize="small" />
            </IconButton>
            </Box>
        ),
        }
    ];


    const handleDateTimeEdit =(payload, row) => {
        updateFixtureBrandImageMutation({variables:{
            input:{
                uuid:row?.uuid,
                mapDeviceFixtureBrandId:row?.mapDeviceFixtureBrand?.uuid,
                ...payload
            }
        },onError:(err)=>{
            setNotification({
              color: "error",
              isVisible: true,
              message: `${err}`,
            });
            setKey(brandFixtureDevice?.uuid + Date.now())
        }})
    }

    const handleStatusChange = (uuid, isActive) => {
        updateFixtureBrandImageMutation({variables:{
            input:{
                uuid,
                isActive
            }
        }})
    }

    const handleEditClick = (row) => {
        const newTab = window.open("", "_blank");
        newTab.document.body.innerHTML = `
            <img src="${row?.imageMaster?.[0]?.publicUrl}" />
        `;
        newTab.focus();
    }
    const handleDownloadClick = (row) => {
        const link = document.createElement("a");
        link.href = row?.imageMaster?.[0]?.publicUrl;
        link.download = row?.imageMaster?.[0]?.name; // You can set the default file name for the download here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleDeleteClick = (row)=>{
        removeFixtureBrandImageMutation({variables:{id:row?.uuid}})
    }

    const Footer = ()=>{
        const [isSubmitting, setIsSubmitting] = useState(false);
        const [resetUploader, setResetUploader] = useState(false);
        const handleAddImage = (image)=>{
            console.log(image)
            createFixtureBrandImageMutation({
                variables:{
                input:{
                    fromTime: dayjs(brandFixtureDevice?.start),
                    toTime: dayjs(brandFixtureDevice?.end),
                    imageIds: [image?.uuid],
                    mapDeviceFixtureBrandId: brandFixtureDevice?.uuid,
                    fixtureId: fixtureId,
                    isActive: false
                }
            }})
            // createFixtureImage({variables:{input:{
            //     fixtureId:fixture?.uuid,
            //     imageMasterId:image?.uuid,
            //     name:`FIM-${Date.now()}`,
            //     fromTime: dayjs(),
            //     toTime: dayjs(),
            // }}})
        }
        return (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 1,px:4 }}>
                <Box sx={{display:'flex',alignItems:'center'}}>
                    <ImageSelect brandId={brandId} onSubmit={handleAddImage} images={fixtureBrandImageData?.findAllMapFixtureBrandImages?.results?.map(fbidata=>fbidata?.imageMaster?.[0]?.uuid)}/>
                    <NewImageButton keyPrefix={brandFixtureDevice?.uuid+fixtureId} user={user} callback={handleAddImage} selectedBrandId={brandId} resetUploader={resetUploader} setResetUploader={setResetUploader} isSubmitting={isSubmitting} setIsSubmitting={setIsSubmitting} />
                </Box>
            </Box>
        )
    }

    return <AdsTable
        key={key}
        columns={columns}
        footerCTA={<Footer />}
        rows={fixtureBrandImageData?.findAllMapFixtureBrandImages?.results || []}
        totalRows={fixtureBrandImageData?.findAllMapFixtureBrandImages?.total || 0}
        showPagination={false}
    />
}
export default function FixturesPage() {
    const { selectedBrandId, user, Brand, selectedStoreId } = useContext(DropDownSelectionContext);

    const {loading, error, data:brandDeviceFixtureData } = useQuery(GET_ASSET_MAPPING_LIST_NEW, {
        client: vmServiceClient,
        variables: {
            page: 1,
            limit: 100,
            relations: ["fixtures"],
            filter: {
                brandId: selectedBrandId,
                isDeleted: false,
                fixtures:{
                    storeId: selectedStoreId,
                    isDeleted: false
                }
            }
        },
        fetchPolicy: "network-only",
    })
    
    const FixtureMediaCard = ({fixtureMedia, rows, columns, page, rowsPerPage})=>{
        const [mediaRows,setMediaRows] = useState(rows.slice(page*rowsPerPage,page+1*rowsPerPage));
        const handlePagination = ({page, rowsPerPage}) => {
            setMediaRows(rows.slice(page*rowsPerPage,page+1*rowsPerPage));
            // refetch({
            //   "page": page,
            //   "limit": rowsPerPage,
            //   "sortBy": "updatedAt",
            //   "order": "DESC",
            //   "relations": ["playlist"],
            //   "searchCols": ["brandId"],
            //   "searchBy": user && user?.role!== "admin" ? [selectedBrandId] : []
            // });
        }

        return (
            <Box sx={{borderRadius:2,my:2, overflow:'hidden', boxShadow:"0 2px 0 0 rgb(200,200,200)", border:1, borderColor:'#FAFAFA'}}>
                <Box sx={{bgcolor:'#EFF4F9', py:1, px:4, display:'flex',fontSize:14, justifyContent:'space-between', alignItems:'center'}}>
                    <Box sx={{fontWeight:600 }}>{fixtureMedia.fixture.code} - {fixtureMedia.fixture.fixtureType}, {fixtureMedia.fixture.fixtureSize}</Box>
                {/* {true && <Button sx={{textDecoration:'underline'}}>View Screenshots</Button>} */}
                </Box>
                <Box>
                    <AdsTable columns={columns} footerCTA={<Footer fixtureMedia={fixtureMedia} />} rows={mediaRows}
                    rowTotal={rows.length} showPagination={false}  />
                </Box>
            </Box>
        )
        
    }
    const {storeIdMap} = useStoreSelection();

    return (
        <Box sx={{
            width: "100%",
            minHeight:'70vh',
            py:2,
            display:'flex',
            flexDirection:'column',
            
          }}>
            {/* <Box sx={{flex:1}}>
              {fixtureImageScheduleMappedData?.findAllMapFixtureImageSchedules?.results?.map(fixtureImageScheduleMap=>(
                <FixtureMediaCard
                    fixtureMedia={fixtureImageScheduleMap}
                    columns={columns}
                    rows={fixtureImageScheduleMap?.imageSchedules?.map(ims=>({...ims,parent:fixtureImageScheduleMap}))}
                    page={0}
                    rowsPerPage={100}
                />
              ))}
            </Box>
            <Box sx={{display:'flex', mt:3, mb:0,  justifyContent:'flex-end'}}>
                <TablePagination
                  count={fixtureImageScheduleMappedData?.findAllMapFixtureImageSchedules?.total} 
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  page={page}
                  rowsPerPage={rowsPerPage}
              ></TablePagination>
            </Box> */}
            {brandDeviceFixtureData?.findAllMapDeviceFixtureBrands?.results
            ?.filter(brandDeviceFixture=>brandDeviceFixture?.fixtures && brandDeviceFixture.fixtures?.length>0)
            ?.map(brandDeviceFixture=>{
                const brand = Brand?.find(brand=>brand?.id==brandDeviceFixture?.brandId)
                const store = storeIdMap[brandDeviceFixture?.fixtures?.[0]?.storeId]
                const location = storeIdMap?.[brandDeviceFixture?.fixtures?.[0]?.locationId]
                const sublocation = storeIdMap?.[brandDeviceFixture?.fixtures?.[0]?.subLocationId]
                const assetType = storeIdMap?.[brandDeviceFixture?.fixtures?.[0]?.subLocationTypeId]
                return (
                  <Accordion>
                    <AccordionSummary
                      sx={{ backgroundColor: "#EFF4F9" }}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel1-content-${brandDeviceFixture.code}`}
                      id={`panel1-header-${brandDeviceFixture.code}`}
                    >
                      <Typography>{`${brand?.name}${
                        store?.name ? " -> " + store.name : ""
                      }${location?.name ? " -> " + location.name : ""}${
                        sublocation?.name ? " -> " + sublocation.name : ""
                      }${assetType?.name ? " -> " + assetType.name : ""} : Start Date - ${dayjs(brandDeviceFixture?.start)?.format(
                        "DD-MM-YYYY"
                      )} | End Date ${dayjs(brandDeviceFixture?.end)?.format(
                        "DD-MM-YYYY"
                      )}`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {brandDeviceFixture?.fixtures?.map((fixture) => (
                        <Box
                          sx={{
                            borderRadius: 2,
                            my: 2,
                            overflow: "hidden",
                            boxShadow: "0 2px 0 0 rgb(200,200,200)",
                            border: 1,
                            borderColor: "#FAFAFA",
                          }}
                        >
                          <Box
                            sx={{
                              bgcolor: "#EFF4F9",
                              py: 1,
                              px: 4,
                              display: "flex",
                              fontSize: 14,
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Box sx={{ fontWeight: 600 }}>
                              {fixture.code} - {fixture.fixtureType},{" "}
                              {fixture.fixtureSize}
                            </Box>
                            {false && (
                              <Button sx={{ textDecoration: "underline" }}>
                                View Screenshots
                              </Button>
                            )}
                          </Box>
                          <Box sx={{ overflowX: "auto" }}>
                            <FixtureImageMapTable
                              user={user}
                              selectedBrandId={selectedBrandId}
                              Brand={Brand}
                              fixtureId={fixture?.uuid}
                              brandFixtureDevice={brandDeviceFixture}
                              brandId={brandDeviceFixture?.brandId}
                            />
                            {/* <PlaylistTable key={key} 
                                    columns={columns}
                                    handleNewPlaylist={handleNewPlaylist}
                                    handleAddPlaylist={handleAddPlaylist}
                                    parent={deviceVideoScheduleMap}
                                    rows={deviceVideoScheduleMap?.videoSchedules}
                                    rowTotal={10} refetch={()=>{}}
                                    selectedBrandId={selectedBrandId}
                                    videoTableColumns={videoTableColumns}
                                    /> */}
                          </Box>
                        </Box>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                );
            })
            }
        </Box>
    )

}
