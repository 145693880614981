import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, CardHeader, Chip, FormControl, Icon, IconButton, Link, MenuItem, Select, TablePagination, Typography } from '@mui/material'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import PlaylistTable from '../playlistMaster/playlistTable';
import { CalendarMonth, CalendarToday, KeyboardArrowDown } from '@mui/icons-material';
import { ReactComponent as previewIcon } from "assets/images/previewIcon.svg";
import { ReactComponent as commentIcon } from "assets/images/commentIcon.svg";
import { ReactComponent as downloadIcon } from "assets/images/downloadIcon.svg";
import { ReactComponent as deleteIcon } from "assets/images/deleteIcon.svg";
import { sumDurations, zipFiles } from '../utils';
import dayjs from 'dayjs';
import { DatePicker, DateTimePicker, LocalizationProvider, MobileDateTimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { vmServiceClient } from 'graphql/client';
import { LIST_ALL_PLAYLISTS } from 'services/advertisment-service';
import { GET_ASSET_MAPPING_LIST_NEW } from 'services/brand-service';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MAP_DEVICE_BRAND_PLAYLIST } from 'services/advertisment-service';
import { DropDownSelectionContext } from '..';
import { CREATE_MAP_DEVICE_BRAND_PLAYLIST } from 'services/advertisment-service';
import { UPDATE_MAP_DEVICE_BRAND_PLAYLIST } from 'services/advertisment-service';
import { REMOVE_MAP_DEVICE_BRAND_PLAYLIST } from 'services/advertisment-service';
import { useNotification } from 'context';
import { CREATE_PLAYLIST } from 'services/advertisment-service';
import useStoreSelection from 'layouts/device-Master/storeSelectionHook';

const DeviceMapTable = ({device, brand, brandFixtureDevice, selectedBrandId, user}) => {
    const { setNotification } = useNotification();
    const [key,setKey] = useState(brandFixtureDevice?.uuid + Date.now())
    const { data:deviceBrandPlaylistData,refetch:refetchBrandPlaylist } = useQuery(MAP_DEVICE_BRAND_PLAYLIST, {
        client: vmServiceClient,
        variables: {
            page: 1,
            limit: 100,
            relations: ["mapDeviceFixtureBrands","mapDeviceBrandPlaylistVideos"],
            filter: {
                mapDeviceFixtureBrands:{
                    uuid: brandFixtureDevice?.uuid
                },
                deviceId:device?.uuid
            }
        }
    });

    const capitalizeFirstLetter = (str) => {
      if (str.length === 0) return str;
      return str.charAt(0).toUpperCase() + str.slice(1);
  }

    const deviceBrandPlaylistMutationOptions = {
        refetchQueries: [
            {
              query: MAP_DEVICE_BRAND_PLAYLIST,
              variables: {
                "page": 1,
                "limit": 100,
                relations: ["mapDeviceFixtureBrands","mapDeviceBrandPlaylistVideos"],
                filter: {
                    mapDeviceFixtureBrands:{
                        uuid: brandFixtureDevice?.uuid
                    },
                    deviceId: device?.uuid
                }
              },
            },
          ],
    }
    const [createDeviceBrandPlaylistMutation] = useMutation(CREATE_MAP_DEVICE_BRAND_PLAYLIST,{
        client:vmServiceClient,
        ...deviceBrandPlaylistMutationOptions
    })
    const [updateDeviceBrandPlaylistMutation] = useMutation(UPDATE_MAP_DEVICE_BRAND_PLAYLIST,{
        client:vmServiceClient,
        ...deviceBrandPlaylistMutationOptions
    })
    const [removeDeviceBrandPlaylistMutation] = useMutation(REMOVE_MAP_DEVICE_BRAND_PLAYLIST,{
        client:vmServiceClient,
        ...deviceBrandPlaylistMutationOptions
    })
    const columns = [
        {
            field: 'icon',
            width: 50,
            headerName:''
        },
        {
        field: 'name',
        headerName: 'Name',
        width: 350,
        renderCell:(params)=>{
          return `${device?.code}_${device?.deviceType}_${brand?.name}_${params?.row?.playlists?.[0]?.name}`
        }
        },
        {
        field: 'videosCount',
        headerName: 'Videos',
        width: 150,
        align:'right',
        renderCell:(params)=>params?.row?.mapDeviceBrandPlaylistVideos?.length || 0
        },
        {
        field: 'startDateTime',
        headerName: 'From time',
        width: 250,
        renderCell:(params)=> 
          user && user?.role !== "admin" && false //remove this false to allow only admin to edit dates
          ? dayjs(params?.row?.fromTime).format('DD/MM/YYYY hh:mm a ')
          : <LocalizationProvider  dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker sx={{"& fieldset": { p: 0, border:0 }}} onAccept={(value)=>handleDateTimeEdit({fromTime:value},params?.row)}
                value={dayjs(params?.row?.fromTime)} format='DD/MM/YYYY hh:mm a ' />
            </LocalizationProvider>
        },
        {
        field: 'endDateTime',
        headerName: 'To time',
        width: 250,
        renderCell:(params)=> user && user?.role !== "admin" && false  //remove this false to allow only admin to edit dates
        ? dayjs(params?.row?.toTime).format('DD/MM/YYYY hh:mm a ')
        : <LocalizationProvider  dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker sx={{"& fieldset": { p: 0, border:0 }}} onAccept={(value)=>handleDateTimeEdit({toTime:value},params?.row)}
                value={dayjs(params?.row?.toTime)} format='DD/MM/YYYY hh:mm a ' />
        </LocalizationProvider>
        },
        {
        field: 'duration',
        headerName: 'Duration',
        width: 100,
        align:'right',
        renderCell: (params) => {
            // console.log(getPlaylistRowData[params.index],params.index, "PARAMS");
            return sumDurations(params?.row?.mapDeviceBrandPlaylistVideos?.map(ov=>ov?.video?.duration)) || 0
            // return 0;
        }
        }, {
        field: 'status',
        headerName: 'Status',
        width: 200,
        renderCell: (params) => {
            if (user && user?.role !== "admin") {
                return (
                <Chip
                    label={params.row.isActive? 'Active':'Inactive'}
                    color={
                    params.row.isActive
                        ? "success"
                        : "error"
                    }
                    size="small"
                />
                );
            }
    
            return (
                <FormControl fullWidth >
                <Select 
                    onChange={(e) =>
                    handleStatusChange(params.row.uuid, e.target.value)
                    }
                    defaultValue={params.row.isActive}
                    value={params.row.isActive}
                    style={{ width: "100%", padding: 10, borderRadius: 6,height:32, }}
                    id="status-select"
                    IconComponent={()=><KeyboardArrowDown sx={{fontSize:24, width:24, height:24}} />}
                    
                >
                    <MenuItem value={true}>ACTIVE</MenuItem>
                    <MenuItem value={false}>INACTIVE</MenuItem>
                </Select>
                </FormControl>
            );
            },
        }, {
            field: 'actions',
            headerName: 'Actions',
            width: 250,
            renderCell: (params) => (
                <Box sx={{ "& button": { mr: 1 } }}>
                <IconButton
                    aria-label="edit"
                    onClick={() => handlePlaylistEditClick(params.row?.mapDeviceBrandPlaylistVideos?.map(plv=>plv.video))}
                >
                    <Icon component={previewIcon} fontSize="small" />
                </IconButton>
                <IconButton aria-label="save" disabled sx={{opacity: 0.25}} onClick={() => alert("Clicked")}>
                    <Icon component={commentIcon} fontSize="small" />
                </IconButton>
                <IconButton
                    aria-label="download"
                    onClick={() =>
                    handlePlaylistDownloadClick(params.row)
                    }
                >
                    <Icon component={downloadIcon} fontSize="small" />
                </IconButton>
                <IconButton
                    aria-label="delete"
                    onClick={() => handlePlaylistDeleteClick(params.row, params.parent)}
                >
                    <Icon component={deleteIcon} fontSize="small" />
                </IconButton>
                </Box>
            ),
        }];
      
        const videoTableColumns = [
          { field: 'dragHandle', headerName: '', width: 50, },
          {
              field: 'name',
              headerName: 'Name',
              width: 350,
          },
          {
              width: 650,
          },
          {
              field: 'duration',
              headerName: 'Duration',
              width: 100,
              align:'right',
          },
          {
              field: 'status',
              headerName: 'Status',
              width: 200,
              renderCell: (params) => {
                  return (
                      <Chip
                      label={params.value}
                      color={
                          params.value === "APPROVED"
                          ? "success"
                          : params.value === "PENDING"
                              ? "warning"
                              : "error"
                      }
                      size="small"
                      />
                  );
              },
          },
          {
              field: 'actions',
              headerName: 'Actions',
              width: 250,
              renderCell: (params, parent) => (
                  <Box sx={{ "& button": { mr: 1 } }}>
                  <IconButton
                      aria-label="edit"
                      onClick={() => handleVideoEditClick(params.row.publicUrl)}
                  >
                      <Icon component={previewIcon} fontSize="small" />
                  </IconButton>
                  <IconButton aria-label="save" disabled sx={{opacity: 0.25}} onClick={() => alert("Clicked")}>
                      <Icon component={commentIcon} fontSize="small" />
                  </IconButton>
                  <IconButton
                      aria-label="download"
                      onClick={() =>
                      handleVideoDownloadClick(params.row.publicUrl, params.row.name)
                      }
                  >
                      <Icon component={downloadIcon} fontSize="small" />
                  </IconButton>
                  <IconButton
                      aria-label="delete"
                      onClick={() => handleVideoDeleteClick(params?.parent, params?.row?.uuid)}
                  >
                      <Icon component={deleteIcon} fontSize="small" />
                  </IconButton>
                  </Box>
              ),
          }
        ];
      
        const handleVideoDownloadClick = (videoUrl, videoName) => {
          // This creates a new anchor element and triggers a download
          const link = document.createElement("a");
          link.href = videoUrl;
          link.download = videoName; // You can set the default file name for the download here
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        };

        const handleVideoDeleteClick = (mapDeviceBrandPlaylist, videoId)=>{
            updateDeviceBrandPlaylistMutation({variables:{
                input:{
                    uuid: mapDeviceBrandPlaylist?.uuid,
                    videos: mapDeviceBrandPlaylist?.mapDeviceBrandPlaylistVideos?.map(ov=>ov?.video?.uuid)
                                                    ?.filter(id=>id != videoId)
                }
            }})
        }

        const handleStatusChange = (uuid, isActive) => {
            updateDeviceBrandPlaylistMutation({variables:{
                input:{
                    uuid,
                    isActive
                }
            }})
        }
        
        
        const handlePlaylistEditClick = (videos) => {
          const newTab = window.open("", "_blank");
          newTab.document.body.innerHTML = `
              <div">
              <video id="videoPlayer" controls autoplay style="width: 100vw; height: 80vh;">
                  <source src="${videos[0].publicUrl}" type="video/mp4">
                  Your browser does not support the video tag.
              </video>
              <div id="videoList" style=" display:flex; padding:16px; align-items:center; width: 100vw; padding-left: 20px; height:auto; flex-wrap:wrap; overflow:auto">
                  ${videos.map((video, index) => `
                  <h4 id="videoItem${index}" style="margin:0 20px; cursor: pointer;">
                      ${video.name}
                  </h4>`).join('=>')}
              </div>
              </div>
          `;
  
          const videoPlayer = newTab.document.getElementById("videoPlayer");
          let currentIndex = 0;
  
          const updateHighlight = (index) => {
              videos.forEach((_, i) => {
              const videoItem = newTab.document.getElementById(`videoItem${i}`);
              videoItem.style.fontWeight = i === index ? 'bold' : 'normal';
              videoItem.style.color = i === index ? 'blue' : 'black';
              });
          };
  
          const playVideo = (index) => {
              if (index < videos.length) {
              videoPlayer.src = videos[index].publicUrl;
              videoPlayer.play();
              currentIndex = index;
              updateHighlight(currentIndex);
              }
          };
  
          videoPlayer.onended = () => {
              currentIndex++;
              playVideo(currentIndex);
          };
  
          videos.forEach((_, index) => {
              newTab.document.getElementById(`videoItem${index}`).onclick = () => {
              playVideo(index);
              };
          });
  
          // Initialize with the first video highlighted
          updateHighlight(currentIndex);
  
          newTab.focus();
        }
        const handlePlaylistDownloadClick = (playlistRow) => {
          zipFiles(
            playlistRow?.playlists?.[0]?.name,
            playlistRow?.mapDeviceBrandPlaylistVideos
            ?.map((plv) => ({
              url: plv?.video?.publicUrl,
              name: plv?.video?.name,
              order: plv?.sortOrder || plv?.order,
              status: plv?.video?.status,
            }))
            ?.filter(video=>video.status=='APPROVED')
          );
        }
        const handlePlaylistDeleteClick = (row) => {
            removeDeviceBrandPlaylistMutation({variables:{
                id:row?.uuid
            }})
        }
      
        const handleDateTimeEdit =(payload, row) => {
            updateDeviceBrandPlaylistMutation({variables:{
                input:{
                    uuid:row?.uuid,
                    mapDeviceFixtureBrandId:row?.mapDeviceFixtureBrands?.uuid,
                    ...payload
                }
            },onError:(err)=>{
              setNotification({
                color: "error",
                isVisible: true,
                message: `${capitalizeFirstLetter(err.message)}`,
              });
              setKey(brandFixtureDevice?.uuid + Date.now())
            }})
        }
        const [createPlaylist] = useMutation(
            CREATE_PLAYLIST,
            {
                client: vmServiceClient,
                fetchPolicy: "network-only",
                refetchQueries: [
                    {
                        query: LIST_ALL_PLAYLISTS,
                        variables: {
                            page: 1,
                            limit: 100,
                            sortBy: "updatedAt",
                            order: "DESC",
                            relations: ["orderedVideos"],
                        },
                    },
                ],
            }
        );
        const handleNewPlaylist = async ({ name,selectedVideos, brandId }) => {
          // if (isSubmitting) return; // Prevent multiple submissions
          // setIsSubmitting(true);
      
          const payload = {
            brandId: selectedBrandId,
            name: name,
            videos:selectedVideos,
          };
          console.log(payload, "payload")
          const createPlaylistResult = await createPlaylist({
              variables: {
                  input: {
                      // startDateTime: playlist.startTime,
                      // endDateTime: playlist.endTime,
                      // deviceIds: [], // Populate as necessary
                      name: payload.name,
                      brandId,
                      status: "PENDING",
                      VideoIds: payload.videos ? payload.videos.map(video=>video.uuid):[], // Populate as necessary
                      // duration:payload.duration || 0
                  },
              },
          })
          console.log(createPlaylistResult, "CREATING PLAYLIST");
          createDeviceBrandPlaylistMutation({variables:{
                input:{
                    fromTime: dayjs(brandFixtureDevice?.start),
                    toTime: dayjs(brandFixtureDevice?.end),
                    playlistId: createPlaylistResult?.data?.createPlaylist?.uuid,
                    mapDeviceFixtureBrandId: brandFixtureDevice?.uuid,
                    deviceId: device?.uuid,
                    isActive: false
                }
            }})
        };
        const handleAddPlaylist = (selectedPlaylist) => {
            createDeviceBrandPlaylistMutation({variables:{
                input:{
                    fromTime: dayjs(brandFixtureDevice?.start),
                    toTime: dayjs(brandFixtureDevice?.end),
                    playlistId: selectedPlaylist?.uuid,
                    mapDeviceFixtureBrandId: brandFixtureDevice?.uuid,
                    deviceId: device?.uuid,
                    isActive: false,
                    videos:[]
                },
            }, onCompleted:(data)=>{
                console.log(data, "ADDED PLAYLIST");
                // setIsSubmitting(false);
                // refetch();
            },onError:(error)=>{
                console.error(error.message);
                setNotification({
                    color: "error",
                    isVisible: true,
                    message: error.message || "Something went wrong",
                });
            }})
          // if (isSubmitting) return; // Prevent multiple submissions
          // setIsSubmitting(true);
      
          // const payload = {
          // //   brandId: selectedBrandId,
          //     // name: name,
          //     playlistVideos: dev ? selectedVideos.map(video=>video.uuid):[] ,
          //     // duration: sumDurations(selectedVideos.map(video=>video.duration)),
          // };
          // console.log(payload, "payload");
        };
      
        const handleAddVideo = (video, row)=>{
          console.log(row, video)
          updateDeviceBrandPlaylistMutation({variables:{
            input:{
                uuid:row?.uuid,
                videos: [...row?.mapDeviceBrandPlaylistVideos?.map(ov=>ov?.video?.uuid), video?.uuid]
            }
          }})
        }
      const handleVideoRearrange = (videoList, row)=>{
          const videos = videoList.map(video=>video.uuid);
          // console.log("PLAYLIST", videos)
          updateDeviceBrandPlaylistMutation({variables:{
            input:{
                uuid:row?.uuid,
                videos:videos
            }
          }})
          
      }
    return (
        <PlaylistTable
            key={key}
            columns={columns}
            handleNewPlaylist={handleNewPlaylist}
            handleAddPlaylist={handleAddPlaylist}
            handleVideoRearrange={handleVideoRearrange}
            handleAddVideo={handleAddVideo}
            rows={deviceBrandPlaylistData?.findAllMapDeviceBrandPlaylists?.results || []}
            rowTotal={deviceBrandPlaylistData?.findAllMapDeviceBrandPlaylists?.total || 0} refetch={()=>{}}
            selectedBrandId={selectedBrandId}
            videoTableColumns={videoTableColumns}
            videoListKey='mapDeviceBrandPlaylistVideos'
            getPlaylistUuids={(rows)=>rows?.map(row=>row?.playlists?.[0]?.uuid)}
        />
    )
}

export default function AssignedDevicesPage() {
  const { selectedBrandId, user, Brand, selectedStoreId } = useContext(DropDownSelectionContext);


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2);
  const [key, setKey] = useState(Date.now());
  const handleChangePage = (event, newPage) => {
      setPage(newPage);
      console.log(newPage,"NEWPAGE");
  };
  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
  };

const {loading, error, data:brandDeviceFixtureData } = useQuery(GET_ASSET_MAPPING_LIST_NEW, {
    client: vmServiceClient,
    variables: {
        page: 1,
        limit: 100,
        relations: ["fixtures","fixtures.devices"],
        filter: {
            brandId: selectedBrandId,
            fixtures:{
                storeId: selectedStoreId,
            }
        }
    },
    fetchPolicy: "network-only",
})

const {storeIdMap} = useStoreSelection();
  
  return (
    <Box sx={{
        width: "100%",
        minHeight:'70vh',
        py:2,
        display:'flex',
        flexDirection:'column',
        
      }}>
        {/* <Box sx={{flex:1}}>
          {loading?<Loader />:
          deviceVideoScheduleMappedData?.findAllMapDeviceVideoSchedules?.results?.map((deviceVideoScheduleMap,idx)=>(
            <Box sx={{borderRadius:2,my:2, overflow:'hidden', boxShadow:"0 2px 0 0 rgb(200,200,200)", border:1, borderColor:'#FAFAFA'}}>
              <Box sx={{bgcolor:'#EFF4F9', py:1, px:4, display:'flex',fontSize:14, justifyContent:'space-between', alignItems:'center'}}>
                <Box sx={{fontWeight:600 }}>
                  Screen {page*rowsPerPage+idx+1} - {deviceVideoScheduleMap?.device?.isShared?'Shared':'Dedicated'} - {deviceVideoScheduleMap?.device?.deviceType}, {deviceVideoScheduleMap?.device?.screenResolution}, {deviceVideoScheduleMap?.device?.orientation=='HORIZONTAL'?'16:9':'9:16'}, ID - {deviceVideoScheduleMap?.device?.code}
                </Box>
                {false && <Button sx={{textDecoration:'underline'}}>View Screenshots</Button>}
              </Box>
              <Box sx={{overflowX:'auto'}}>
                <PlaylistTable key={key} 
                  columns={columns}
                  handleNewPlaylist={handleNewPlaylist}
                  handleAddPlaylist={handleAddPlaylist}
                  parent={deviceVideoScheduleMap}
                  rows={deviceVideoScheduleMap?.videoSchedules}
                  rowTotal={10} refetch={()=>{}}
                  selectedBrandId={selectedBrandId}
                  videoTableColumns={videoTableColumns}
                />
              </Box>
            </Box>
          ))}
        </Box>
        <Box sx={{display:'flex', mt:3, mb:0,  justifyContent:'flex-end'}}>
        {!loading && <TablePagination
              count={deviceVideoScheduleMappedData?.findAllMapDeviceVideoSchedules?.total} 
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
          ></TablePagination>
          }
        </Box> */}
        {brandDeviceFixtureData?.findAllMapDeviceFixtureBrands?.results
            ?.map(brandDeviceFixture=>{
                const brand = Brand?.find(brand=>brand?.id==brandDeviceFixture?.brandId)
                const store = storeIdMap?.[brandDeviceFixture?.fixtures?.[0]?.storeId]
                const location = storeIdMap?.[brandDeviceFixture?.fixtures?.[0]?.locationId]
                const sublocation = storeIdMap?.[brandDeviceFixture?.fixtures?.[0]?.subLocationId]
                const assetType = storeIdMap?.[brandDeviceFixture?.fixtures?.[0]?.subLocationTypeId]
                
                return (
                  <Accordion>
                    <AccordionSummary
                      sx={{ backgroundColor: "#EFF4F9" }}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel1-content-${brandDeviceFixture.code}`}
                      id={`panel1-header-${brandDeviceFixture.code}`}
                    >
                      <Typography>{`${brand?.name}${
                        store?.name ? " -> " + store.name : ""
                      }${location?.name ? " -> " + location.name : ""}${
                        sublocation?.name ? " -> " + sublocation.name : ""
                      }${assetType?.name ? " -> " + assetType.name : ""} : Start Date - ${dayjs(
                        brandDeviceFixture?.start
                      )?.format("DD-MM-YYYY")} | End Date - ${dayjs(
                        brandDeviceFixture?.end
                      )?.format("DD-MM-YYYY")}`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {brandDeviceFixture?.fixtures
                        ?.flatMap((fixture) => fixture?.devices)
                        .map((device) => {
                          return (
                            <Box
                              sx={{
                                borderRadius: 2,
                                my: 2,
                                overflow: "hidden",
                                boxShadow: "0 2px 0 0 rgb(200,200,200)",
                                border: 1,
                                borderColor: "#FAFAFA",
                              }}
                            >
                              <Box
                                sx={{
                                  bgcolor: "#EFF4F9",
                                  py: 1,
                                  px: 4,
                                  display: "flex",
                                  fontSize: 14,
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Box sx={{ fontWeight: 600 }}>
                                  {device?.code},{" "}
                                  {device?.isShared ? "Shared" : "Dedicated"} -{" "}
                                  {device?.deviceType},{" "}
                                  {device?.screenResolution},{" "}
                                  {device?.orientation == "HORIZONTAL"
                                    ? "16:9"
                                    : "9:16"}
                                </Box>
                                {false && (
                                  <Button sx={{ textDecoration: "underline" }}>
                                    View Screenshots
                                  </Button>
                                )}
                              </Box>
                              <Box sx={{ overflowX: "auto" }}>
                                <DeviceMapTable
                                  selectedBrandId={selectedBrandId}
                                  device={device}
                                  brand={brand}
                                  brandFixtureDevice={brandDeviceFixture}
                                  user={user}
                                />
                                {/* <PlaylistTable key={key} 
                                        columns={columns}
                                        handleNewPlaylist={handleNewPlaylist}
                                        handleAddPlaylist={handleAddPlaylist}
                                        parent={deviceVideoScheduleMap}
                                        rows={deviceVideoScheduleMap?.videoSchedules}
                                        rowTotal={10} refetch={()=>{}}
                                        selectedBrandId={selectedBrandId}
                                        videoTableColumns={videoTableColumns}
                                        /> */}
                              </Box>
                            </Box>
                          );
                        })}
                    </AccordionDetails>
                  </Accordion>
                );
            })}
    </Box>
    
  )
}
