import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import {
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Typography,
    Switch,
    Autocomplete,
    Chip,
    FormHelperText,
} from "@mui/material";
import * as Yup from "yup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { dashboardServiceClient, vmServiceClient } from "graphql/client";
import Loader from "components/MDLoader";
import { useNotification } from "context";
import MDButton from "components/MDButton";
import {
    GET_DEVICES_FOR_MAPPING,
    GET_FIXTURES_FOR_MAPPING,
    GET_ASSET_MAPPING_LIST,
    UPDATE_ASSET_MAPPING,
    CREATE_ASSET_MAPPING,
} from "services/brand-service";
import { LIST_BRAND_STORE } from "services/brand-service";

const validationSchema  = (storeDates) =>Yup.object({
    storeId: Yup.string().required("Store Name is required"),
    location: Yup.string().required("Location is required"),
    subLocation: Yup.string().required("Sub Location is required"),
    type: Yup.string().required("Type is required"),
    batchId: Yup.string().required("Batch Id is required"),
    isShared: Yup.boolean(),
    device: Yup.array(),
    fixture: Yup.array().required("Fixture Is Required").min(1,"Minimum One Fixture is required"),
    startDate: Yup.date()
    .required("Start date is required")
    .test(
      "startDate",
      `Start date must be on or after ${storeDates.minDate ? storeDates.minDate.format('DD-MM-YYYY') : dayjs().format('DD-MM-YYYY')} and not in the past`,
      (value) => {
        const today = dayjs().startOf('day');
        const isValid = dayjs(value).isSameOrAfter(today, 'day') && dayjs(value).isSameOrAfter(storeDates.minDate || today, 'day');
        return isValid;
      }
    ),
  endDate: Yup.date()
    .required("End date is required")
    .min(
      Yup.ref("startDate"),
      "End date must be at least one day after the start date"
    )
    .max(
      storeDates.maxDate || dayjs().add(1, "year"),
      `End date must be on or before ${storeDates.maxDate ? storeDates.maxDate.format('DD-MM-YYYY') : dayjs().add(1, 'year').format('DD-MM-YYYY')}`
    ),
});
const useAssetMappingMutation = (
    assetData,
    handleClose,
    setNotification,
    brandData,
) => {
    const mutationOptions = {
        client: vmServiceClient,
        refetchQueries: [
            {
                query: GET_ASSET_MAPPING_LIST,
                variables: { page: 1, limit: 20, sortBy: "updatedAt", order: "DESC", relations: ["devices", "fixtures","fixtures.mapDeviceFixtureBrand" ], filter: { brandId: brandData?.id } },
                fetchPolicy: "network-only",
            },
        ],
    };
    const [createMapDeviceFixtureBrand] = useMutation(
        CREATE_ASSET_MAPPING,
        mutationOptions
    );
    const [updateMapDeviceFixtureBrand] = useMutation(
        UPDATE_ASSET_MAPPING,
        mutationOptions
    );

    const handleSubmit = (values,setSubmitting) => {
        const payload = {
            brandId: brandData?.id,
            brandStoreId:values?.brandStoreId,
            deviceIds: (typeof values.device === 'string' && values.device === "") ? [] : Array.isArray(values.device) ? values.device.map((item) => item?.uuid) : [],
            fixtureIds: (typeof values.fixture === 'string' && values.fixture === "") ? [] : Array.isArray(values.fixture) ? values.fixture.map((item) => item?.uuid) : [],
            start: values?.startDate,
            end: values?.endDate,
        };

        const mutation =
            assetData && assetData?.uuid
                ? updateMapDeviceFixtureBrand
                : createMapDeviceFixtureBrand;
        const mutationInput =
            assetData && assetData?.uuid
                ? { input: { uuid: assetData?.uuid, ...payload } }
                : { input: payload };

                mutation({
                    variables: mutationInput,
                    onCompleted: (res) => {
                        handleClose();
                        setNotification({
                            color: "success",
                            isVisible: true,
                            message: assetData && assetData?.uuid
                                ? "Updated Mapping Successfully"
                                : "Created Mapping Successfully",
                        });
                    },
                    onError: (error) => {
                        console.error("Error:", error.message);
                        setNotification({
                            color: "error",
                            isVisible: true,
                            message: error.message || "Something went wrong",
                        });
                    },
                }).finally(() => {
                    setSubmitting(false);
                });
    };
    return handleSubmit;
};

const Dropdown = ({ label, value, options, onChange, error, helperText, disabled, name }) => {
    return (
        <FormControl fullWidth error={Boolean(error)}>
            <InputLabel>{label}</InputLabel>
            <Select
                label={label}
                value={value}
                onChange={onChange}
                IconComponent={() => <ArrowDropDownIcon style={{ marginRight: "18px" }} />}
                disabled={disabled}
                name={name}
                sx={{ height: 40 }}
            >
                {options?.map((option) => (
                    <MenuItem key={option?.value} value={option?.value}>
                        {option?.label}
                    </MenuItem>
                ))}
            </Select>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};
function AssetMappingForm({ assetData, handleClose, brandData }) {
    const [activeStoresList, setActiveStoresList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [subLocationList, setSubLocationList] = useState([]);
    const [assetTypeList, setAssetTypeList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [codeList, setCodeList] = useState([]);
    const { setNotification } = useNotification();

    const [selectedStore, setSelectedStore] = useState("");
    const [selectedLocations, setSelectedLocations] = useState("");
    const [selectedSubLoc, setSelectedSubLoc] = useState("");
    const [selectedType, setSelectedType] = useState("");
    const [selecetedBatchId, setSelectedSelectedBatchId] = useState("");
    const [isSharedAsset, setIsSharedAsset] = useState(false);
    const [storeDates, setStoreDates] = useState({ minDate: null, maxDate: null });

    const {
        loading: storeLoading,
        error: storeError,
        data: storeList,
      } = useQuery(LIST_BRAND_STORE, {
        client: dashboardServiceClient,
        variables: { listBrandStoreFilter: { take: 100, skip: 0,filter:{status: ["active", "upcoming"],brand:{id:brandData.id}} } },
      });

    const getAssetValue = (assetData, key) => {
        return (
            assetData?.devices?.[0]?.store?.[key] || assetData?.fixtures?.[0]?.store?.[key] || ""
        );
    };
    const {
        loading: deviceLoading,
        error: deviceError,
        data: deviceList,
        refetch: deviceRefetch,
    } = useQuery(GET_DEVICES_FOR_MAPPING, {
        client: vmServiceClient,
        variables: {
            page: 1,
            limit: 100,
            filter: {
                isShared: isSharedAsset,
                storeId: selectedStore || "",
                locationId: selectedLocations || "",
                subLocationId: selectedSubLoc || "",
                subLocationTypeId: selectedType || "",
                assetTypeCode: selecetedBatchId || "",
                isActive:true
            },
        },
    });

    const {
        loading: fixtureLoading,
        error: fixtureError,
        data: fixtureList,
        refetch: fixtureRefetch,
    } = useQuery(GET_FIXTURES_FOR_MAPPING, {
        client: vmServiceClient,
        variables: {
            page: 1,
            limit: 100,
            filter: {
                isShared: isSharedAsset,
                storeId: selectedStore || "",
                locationId: selectedLocations || "",
                subLocationId: selectedSubLoc || "",
                subLocationTypeId: selectedType || "",
                assetTypeCode: selecetedBatchId || "",
            },
        },
    });
    useEffect(() => {
        deviceRefetch({
            variables: {
                variables: {
                    page: 1,
                    limit: 100,
                    filter: {
                        isShared: isSharedAsset,
                        storeId: selectedStore || "",
                        locationId: selectedLocations || "",
                        subLocationId: selectedSubLoc || "",
                        subLocationTypeId: selectedType || "",
                        assetTypeCode: selecetedBatchId || "",
                        isActive:true
                    },
                },
            },
        });
        fixtureRefetch({
            variables: {
                variables: {
                    page: 1,
                    limit: 100,
                    filter: {
                        isShared: isSharedAsset,
                        storeId: selectedStore || "",
                        locationId: selectedLocations || "",
                        subLocationId: selectedSubLoc || "",
                        subLocationTypeId: selectedType || "",
                        assetTypeCode: selecetedBatchId || "",
                    },
                },
            },
        });
    }, [selecetedBatchId, isSharedAsset]);

    const handleStoreChange = (selectedStore, setFieldValue, setFieldTouched) => {
        setFieldValue("storeId", selectedStore.store.id);
        setSelectedStore(selectedStore.store.id);
        setFieldValue("brandStoreId", selectedStore?.id);
        setFieldTouched(`storeId`, false, true);
        setSelectedLocations("");
        setSelectedSubLoc("");
        setSelectedType("");
        setSelectedSelectedBatchId("");
        resetFields(setFieldValue, ["location", "subLocation", "type", "batchId","device","fixture"]);
        if (selectedStore) {
            setStoreDates({
              minDate: dayjs(selectedStore.start),
              maxDate: dayjs(selectedStore.end),
            });
          } else {
            setStoreDates({ minDate: null, maxDate: null });
          }
    };

    const handleLocationsChange = (
        location,
        setFieldValue,
        setFieldTouched,
        validateField
    ) => {
        setFieldValue("location", location);
        validateField(`location`, location);
        setSelectedLocations(location);
        setFieldTouched(`location`, false, true);
        setSelectedSubLoc("");
        setSelectedType("");
        setSelectedSelectedBatchId("");
        resetFields(setFieldValue, ["subLocation","type", "batchId","device","fixture"]);
    };

    const handleSubLocationChange = (
        subLoc,
        setFieldValue,
        setFieldTouched,
        validateField
    ) => {
        setFieldValue("subLocation", subLoc);
        setFieldTouched(`subLocation`, false, true);
        setSelectedSubLoc(subLoc);
        setSelectedType("");
        setSelectedSelectedBatchId("");
        resetFields(setFieldValue, ["type", "batchId","device","fixture"]);
    };
    const handleTypeChange = (
        type,
        setFieldValue,
        setFieldTouched,
        validateField
    ) => {
        setFieldValue("type", type);
        setFieldTouched(`type`, false, true);
        setSelectedType(type);
        setSelectedSelectedBatchId("");
        resetFields(setFieldValue, [ "batchId","device","fixture"]);
    };
    const handleBatchChange = (
        batch,
        setFieldValue,
        setFieldTouched,
        validateField
    ) => {
        setFieldValue("batchId", batch);
        setFieldTouched(`batchId`, false, true);
        setSelectedSelectedBatchId(batch);
        setFieldValue("device", "");
        setFieldValue("fixture", "");
        resetFields(setFieldValue, ["device","fixture"]);
    };
    const resetFields = (setFieldValue, fields) => {
        fields.forEach((field) => setFieldValue(field, ""));
    };
    const handleSubmit = useAssetMappingMutation(
        assetData,
        handleClose,
        setNotification,
        brandData,
        selectedStore,
        selectedLocations,
        selectedSubLoc,
        selectedType
    );
    const brandStoreList = storeList?.brandStores?.results?.map(item => item) // This is for dropdown options
    useEffect(() => {
        if (storeList) {
            const activeStores = storeList?.brandStores?.results?.map(item => item?.store)?.filter(
                (item) => item.status === "Active"
            );
            setActiveStoresList(activeStores);
        }
    }, [storeList, selectedStore]);

    useEffect(() => {
        if (selectedStore) {
            const locations = activeStoresList?.find(
                (store) => store?.id === selectedStore
            )?.storeLocation?.filter((loc)=> !loc.isDeleted)
            setLocationList(locations);
        }
    }, [selectedStore, storeList, activeStoresList]);

    useEffect(() => {
        if (selectedLocations) {
            const subLocations = locationList?.find(
                (loc) => loc.id === selectedLocations
            )?.subLocations;
            setSubLocationList(subLocations);
        }
    }, [selectedStore, selectedLocations, storeList, locationList]);

    useEffect(() => {
        if (selectedSubLoc) {
            const types = subLocationList?.find(
                (subLoc) => subLoc.id === selectedSubLoc
            )?.assetTypes;
            setAssetTypeList(types)
            const uniqueBatchCodes = [];
            const uniqueAssetTypes = [];
            types?.forEach(asset => {
                if (!uniqueBatchCodes.includes(asset.batchCode)) {
                    uniqueBatchCodes.push(asset.batchCode);
                    uniqueAssetTypes.push(asset);
                }
            });
            setTypeList(uniqueAssetTypes);
        }
    }, [selectedSubLoc, subLocationList]);
    useEffect(() => {
        if (selectedType) {
            const codes = assetTypeList?.filter(type => type?.batchCode === selectedType)
            setCodeList(codes)
        }
    }, [ assetTypeList, selectedType]);
    useEffect(() => {
        if (assetData) {
            setSelectedStore(getAssetValue(assetData, "id"));
            setSelectedLocations(getAssetValue(assetData, "location")?.id || "");
            setSelectedSubLoc(getAssetValue(assetData, "subLocation")?.id || "");
            setSelectedType(getAssetValue(assetData, "assetType")?.id || "");
            setSelectedSelectedBatchId(getAssetValue(assetData, "assetType")?.code || "");
            setIsSharedAsset(
                assetData?.devices?.length ? assetData?.devices[0]?.isShared : assetData?.fixtures?.length ? assetData?.fixtures[0]?.isShared : false
            );
        }
    }, []);
    // below useeefect is to make sure that on edit form opening i get the date validation
    useEffect(() => {
        if (storeList && getAssetValue(assetData, "id")) {
          const selectedStore = storeList?.brandStores?.results.find(
            (item) =>  item.store.id === getAssetValue(assetData, "id") &&  item.id === assetData.brandStore?.id
          );
          console.log(selectedStore,"selectedStore")
          if (selectedStore && selectedStore?.id) {
            setStoreDates({
              minDate: dayjs(selectedStore.start),
              maxDate: dayjs(selectedStore.end),
            });
          }
        }
      }, [storeList, assetData]);
    if (storeLoading)
        return (
            <>
                <Loader />
            </>
        );
    if (storeError) return <p>Error: {storeError.message}</p>;
    if (deviceError) return <p>Error: {deviceError.message}</p>;
    if (fixtureError) return <p>Error: {fixtureError.message}</p>;
    return (
        <Formik
            initialValues={{
                brandStoreId:assetData?.brandStore?.id || "",
                storeId: getAssetValue(assetData, "id"),
                location: getAssetValue(assetData, "location")?.id || "",
                subLocation: getAssetValue(assetData, "subLocation")?.id || "",
                type: getAssetValue(assetData, "assetType")?.batchCode || "",
                batchId: getAssetValue(assetData, "assetType")?.code || "",
                isShared:
                    assetData?.devices && assetData?.devices?.length
                        ? assetData?.devices[0]?.isShared
                        : assetData?.fixtures && assetData?.fixtures.length
                            ? assetData?.fixtures[0]?.isShared
                            : false,
                // device: assetData && assetData?.devices && assetData.devices.length && assetData?.devices.map((item) => ({ uuid: item.uuid, label: item.deviceName })) || [],
                fixture: assetData && assetData?.fixtures && assetData.fixtures.length && assetData?.fixtures?.map((item) => ({ uuid: item.uuid, label: item.code,devices: item.devices || [], })) || [],
                startDate: assetData?.start || "",
                endDate: assetData?.end || "",
            }}
            validationSchema={validationSchema(storeDates)}
            onSubmit={(values,{setSubmitting}) => {
                console.log(values);
                handleSubmit(values,setSubmitting);
            }}
        >
            {({
                values,
                setFieldValue,
                setFieldTouched,
                validateField,
                errors,
                touched,
                isValid,
                isSubmitting,
                handleChange,
            }) => (
                <Form>
                    <Grid container spacing={3} alignItems="center" p={2}>
                        <Grid item xs={12}>
                            <FormControl
                                fullWidth
                                error={touched.storeId && Boolean(errors.storeId)}
                            >
                                <InputLabel id="demo-simple-select-label">
                                    Store Name
                                </InputLabel>
                                <Select
                                    as={Select}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="storeId"
                                    label="Store Name"
                                    IconComponent={() => (
                                        <ArrowDropDownIcon
                                            style={{ marginRight: "18px", marginTop: "10px" }}
                                        />
                                    )}
                                    value={brandStoreList.find(store => store?.id === values?.brandStoreId) || ""}
                                    onChange={(e) => {
                                        handleStoreChange(e.target.value, setFieldValue, setFieldTouched)
                                    }
                                    }
                                    error={touched.storeId && Boolean(errors.storeId)}
                                    sx={{ height: 40 }}
                                >
                                    {brandStoreList.map((brandStore) => (
                                        <MenuItem key={brandStore.id} value={brandStore}>
                                             {`${brandStore.store.name} - ${dayjs(brandStore.start).format('DD-MM-YYYY')} to ${dayjs(brandStore.end).format('DD-MM-YYYY')}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {touched.storeId && errors.storeId ? (
                                    <FormHelperText>{errors.storeId}</FormHelperText>
                                ) : null}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Dropdown
                                label="Location"
                                name="location"
                                disabled={!values.storeId}
                                value={values.location}
                                onChange={(e) =>
                                    handleLocationsChange(e.target.value, setFieldValue, setFieldTouched, validateField)
                                }
                                options={locationList?.map((loc) => ({ value: loc.id, label: loc.division?.title}))}
                                error={touched.location && errors.location}
                                helperText={touched.location && errors.location}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Dropdown
                                name="subLocation"
                                label="Sub -Location"
                                value={values.subLocation}
                                disabled={!values.location}
                                onChange={(e) => handleSubLocationChange(e.target.value,setFieldValue,setFieldTouched,validateField)}
                                options={subLocationList?.map((subLoc) => ({ value: subLoc.id, label: subLoc.name }))}
                                error={touched.subLocation && errors.subLocation}
                                helperText={touched.subLocation && errors.subLocation}
                            />
                            </Grid>
                        <Grid item xs={12}>
                            <Dropdown
                                name="type"
                                label="Type"
                                value={values.type}
                                onChange={(e) =>
                                    handleTypeChange(
                                        e.target.value,
                                        setFieldValue,
                                        setFieldTouched,
                                        validateField
                                    )
                                }
                                error={touched.type && errors.type}
                                helperText={touched.type && errors.type}
                                disabled={!values.subLocation}
                                options={typeList.map((type) => ({ value: type.batchCode, label: type.batchCode }))}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Dropdown
                                name="batchId"
                                label="Batch ID"
                                value={values.batchId}
                                onChange={(e) =>
                                    handleBatchChange(
                                        e.target.value,
                                        setFieldValue,
                                        setFieldTouched,
                                        validateField
                                    )
                                }
                                error={touched.batchId && errors.batchId}
                                helperText={touched.batchId && errors.batchId}
                                disabled={!values.type}
                                options={codeList?.filter(code => !code.isDeleted)?.map((code) => ({ value: code.code, label: code.code }))}
                            />
                        </Grid>
                        <Grid item container spacing={2} alignItems="center" xs={12}>
                            <Grid item>
                                <Typography variant="h7" fontWeight="bold" fontSize="13px">
                                    Shared Assets
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Switch
                                    checked={values.isShared}
                                    onChange={(event) => {
                                        setFieldValue("isShared", event.target.checked);
                                        setIsSharedAsset(event.target.checked);
                                        setFieldValue("device", "");
                                        setFieldValue("fixture", "");
                                    }}
                                    name="isShared"
                                    color="primary"
                                />
                            </Grid>
                        </Grid>
                        {/* Removing Devices AutoComplete since not needed  */}
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                id="tags-standard"
                                name="fixture"
                                value={values.fixture || []}
                                options={
                                    fixtureList?.findAllFixtures?.results?.map((item) => ({
                                        uuid: item.uuid,
                                        label: item.code,
                                        devices: item.devices || [],
                                    })) || []
                                }
                                getOptionLabel={(option) => option?.label || option?.code || "Unknown"}
                                isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
                                disabled={!values.batchId}
                                loading={fixtureLoading}
                                onChange={(event, newValue) => {
                                    setFieldValue("fixture", newValue || []);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Select Fixture Assets"
                                        helperText={touched.fixture ? errors.fixture : ""}
                                        error={touched.fixture && Boolean(errors.fixture)}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                        slotProps={
                                            errors.startDate
                                                ? {
                                                    textField: {
                                                        error: !!touched.startDate && errors.startDate,
                                                        helperText: errors.startDate,
                                                    },
                                                }
                                                : null
                                        }
                                        format="DD/MM/YYYY"
                                        label="Start Date"
                                        name="startDate"
                                        minDate={storeDates.minDate || dayjs().startOf('day')}
                                        maxDate={storeDates.maxDate}
                                        value={values.startDate ? dayjs(values.startDate) : null}
                                        onChange={(newValue) =>
                                            setFieldValue(
                                                "startDate",
                                                dayjs(newValue).format("YYYY-MM-DD")
                                            )
                                        }
                                        renderInput={(params) => (
                                            <Field
                                                as={TextField}
                                                {...params}
                                                fullWidth
                                                error={touched?.startDate && Boolean(errors?.startDate)}
                                                helperText={touched?.startDate && errors?.startDate}
                                            />
                                        )}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker"]}>
                                    <DatePicker
                                        slotProps={
                                            errors.endDate
                                                ? {
                                                    textField: {
                                                        error: !!touched.endDate && errors.endDate,
                                                        helperText: errors.endDate,
                                                    },
                                                }
                                                : null
                                        }
                                        format="DD/MM/YYYY"
                                        label="End Date"
                                        name="endDate"
                                        minDate={dayjs(values.startDate)}
                                        maxDate={storeDates.maxDate}
                                        value={values.endDate ? dayjs(values.endDate) : null}
                                        onChange={(newValue) =>
                                            setFieldValue(
                                                "endDate",
                                                dayjs(newValue).format("YYYY-MM-DD")
                                            )
                                        }
                                        renderInput={(params) => (
                                            <Field
                                                as={TextField}
                                                {...params}
                                                fullWidth
                                                error={touched?.endDate && Boolean(errors?.endDate)}
                                                helperText={touched?.endDate && errors?.endDate}
                                            />
                                        )}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                                Device List
                            </Typography>
                            <div style={{ maxHeight: '150px', overflowY: 'auto', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }}>
                                {values.fixture.length > 0 ? (
                                    values.fixture.flatMap(fixture => fixture.devices || [])
                                        .length > 0 ? (
                                        values.fixture.flatMap(fixture => fixture.devices || []).map(device => (
                                            <Chip
                                                key={device.uuid}
                                                label={device.deviceName}
                                                style={{ margin: '4px' }}
                                            />
                                        ))
                                    ) : (
                                        <Typography variant="body2" color="textSecondary">
                                            No devices available for selected fixtures.
                                        </Typography>
                                    )
                                ) : (
                                    <Typography variant="body2" color="textSecondary">
                                        No fixtures selected.
                                    </Typography>
                                )}
                            </div>
                        </Grid>

                        <Grid container flexDirection={"row-reverse"} xs={12} sx={{ mt: 2 }} gap={2}>
                            <MDButton
                                type="submit"
                                variant="contained"
                                circular={true}
                                color="black"
                                disabled={isSubmitting}
                            >
                                Save
                            </MDButton>
                            <MDButton
                                color="black"
                                variant={"outlined"}
                                circular={true}
                                style={{ backgroundColor: "#FFFFFF" }}
                                onClick={handleClose}
                            >
                                Cancel
                            </MDButton>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}

export default AssetMappingForm;
