import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Icon,
  Box,
  IconButton,
  CircularProgress,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import addIcon from "../../../../assets/images/add-icon.svg";
import galleryIcon from "../../../../assets/images/gallery-icon.svg";
import deleteIcon from "../../../../assets/images/deleteIcon.svg";
import downloadIcon from "../../../../assets/images/downloadIcon.svg";
import { useLazyQuery, useQuery } from "@apollo/client";
import { BRANDS_LIST } from "services/brand-service";
import { dashboardServiceClient } from "graphql/client";
import { AvailableForms } from "../../productTable/tableData";
import axios from "axios";
import { useNotification } from "context";
import useProductContext from "../../context/useProductContext";
import MDButton from "components/MDButton";
import BulkProductPreviewModal from "./BulkProductPreviewModal";

const validationSchema = Yup.object().shape({
  fileUpload: Yup.mixed()
    .required("File is required")
    .test("fileFormat", "Only Excel and CSV files are allowed", (data) => {
      const value = data.file;
      return (
        value &&
        (value.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          value.type === "application/vnd.ms-excel" ||
          value.type === "text/csv")
      );
    }),
});

const initialValues = {
  fileUpload: null,
};

function CustomErrorTextField({ children, sx, ...props }) {
  return (
    <Typography
      sx={{
        color: "#F44335",
        marginTop: "3px",
        marginRight: "14px",
        marginBottom: "0",
        marginLeft: "14px",
        fontSize: "0.75rem",
        fontWeight: "300",
        lineHeight: "1.25",
        letterSpacing: "0.03333em",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
}

export default function AddSuperAdminBulkProduct({ setForm }) {
  const formRef = useRef(null);

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [downloadingTemplate, setDownloadingTemplated] = useState(false);

  const [uploadedFile, setUploadedFile] = useState(null);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  const user =
    localStorage.getItem("userDetails") &&
    localStorage.getItem("userDetails") !== "undefined"
      ? JSON.parse(localStorage.getItem("userDetails"))
      : {};

  const isAdmin = user?.role === "admin";

  const { setNotification } = useNotification();

  const handleSuperAdminTemplate = async () => {
    const tocken = localStorage.getItem("token");
    const url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? `${process.env.REACT_APP_API_BASE_URL}/product-service/super-admin-bulk-product`
        : `/product-service/super-admin-bulk-product`;

    setDownloadingTemplated(true);

    setNotification({
      color: "info",
      isVisible: true,
      message:
        "Downloading started in background, please do not close or refresh this page",
    });

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${tocken}`,
        },
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `super-bulk-product.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error("Failed to download template");
      }

      setNotification({
        color: "success",
        isVisible: true,
        message: "Downloading completed for template",
      });
    } catch (error) {
      console.error(error);
      setNotification({
        color: "error",
        isVisible: true,
        message: "Failed to download template",
      });
    }
    setDownloadingTemplated(false);
  };

  const formSubmitHandler = async (values, { setSubmitting }) => {
    setUploadedFile(values.fileUpload);
    setOpenPreviewModal(true);
  };

  return (
    <>
      <BulkProductPreviewModal
        open={openPreviewModal}
        setForm={setForm}
        setOpen={setOpenPreviewModal}
        uploadedFile={uploadedFile}
      />
      <Grid
        container
        sx={{
          pb: 2,
          pt: 2,
          pl: "10px",
          pr: "10px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: "20px",
            overflowY: "auto",
          }}
        >
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={formSubmitHandler}
            innerRef={formRef}
          >
            {({
              errors,
              values,
              setFieldValue,
              isSubmitting,
              handleSubmit,
            }) => {
              return (
                <Form
                  id={AvailableForms.nodeWiseBulkProduct}
                  onSubmit={handleSubmit}
                >
                  <Grid container spacing={2}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Button
                          startIcon={
                            <img src={downloadIcon} alignItems={"center"} />
                          }
                          component={"label"}
                          disabled={isSubmitting || downloadingTemplate}
                          onClick={handleSuperAdminTemplate}
                        >
                          {downloadingTemplate ? (
                            "loading..."
                          ) : (
                            <Typography
                              sx={{
                                fontWeight: "500",
                                fontSize: "14px",
                                color: "#000000",
                              }}
                            >
                              Ginesys Product Template
                            </Typography>
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="fileUpload">
                        {({ field, form }) => (
                          <Grid
                            container
                            justifyContent={"space-between"}
                            padding={"10px"}
                            sx={{
                              backgroundColor: "#F0F2F5",
                              borderRadius: "10px",
                            }}
                          >
                            <Grid item display={"flex"} alignItems={"center"}>
                              <Icon
                                component={() => <img src={galleryIcon} />}
                                variant="img"
                              />
                              <Typography
                                variant="h6"
                                sx={{
                                  marginLeft: "10px",
                                }}
                              >
                                Upload Excel file
                                <Typography
                                  display={"inline"}
                                  variant="h6"
                                  sx={{
                                    fontWeight: "500",
                                    opacity: "0.5",
                                  }}
                                >
                                  (Maximum 1 excel can be uploaded)
                                </Typography>
                              </Typography>
                            </Grid>
                            <Grid item display={"flex"}>
                              <Button
                                startIcon={
                                  <img src={addIcon} alignItems={"center"} />
                                }
                                component={"label"}
                                htmlFor={"product-excel"}
                                disabled={isSubmitting}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: "600",
                                    fontSize: "16px",
                                    color: "#E93165",
                                  }}
                                >
                                  CHOOSE FILE
                                </Typography>
                              </Button>
                            </Grid>

                            <input
                              type="file"
                              hidden
                              style={{
                                display: "none",
                              }}
                              accept=".xlsx, .xlx, .csv"
                              multiple
                              id="product-excel"
                              onChange={(event) => {
                                const allowedFileExt = ["xlsx", "xlx", "csv"];
                                const newFiles = Array.from(event.target.files)
                                  .map((file) => {
                                    const ext = file.name.split(".").pop();
                                    if (!allowedFileExt.includes(ext)) {
                                      setFieldError(
                                        field.name,
                                        "Only CSV or Excel files are allowed"
                                      );
                                      return null;
                                    }

                                    const mbToBytes = 1024 * 1024;

                                    if (file.size > 5 * mbToBytes) {
                                      setFieldError(
                                        field.name,
                                        "File size should not exceed 5MB"
                                      );
                                      return null;
                                    }

                                    return {
                                      file,
                                      filename: file.name,
                                    };
                                  })
                                  .filter((file) => file !== null);

                                setFieldValue(field.name, newFiles[0]);

                                event.target.value = "";
                              }}
                            />
                            {errors.fileUpload && (
                              <CustomErrorTextField
                                color="error"
                                sx={{ mt: 1 }}
                              >
                                {errors.fileUpload}
                              </CustomErrorTextField>
                            )}
                            <Box
                              sx={{
                                mt: 2,
                                display: "flex",
                                gap: 2,
                                width: "100%",
                              }}
                            >
                              {values.fileUpload && (
                                <Box
                                  sx={{
                                    width: 100,
                                    height: 100,
                                    objectFit: "cover",
                                    position: "relative",
                                    backgroundImage: `url(https://images.unsplash.com/photo-1658203897339-0b8c64a42fba?q=80&w=3262&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`,
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    border: "1px solid #F0F2F5",
                                    borderRadius: "12px",
                                  }}
                                >
                                  <IconButton
                                    sx={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      transform: "translate(-50%,-50%)",
                                    }}
                                    onClick={() => {
                                      setFieldValue(field.name, null);
                                    }}
                                  >
                                    <img src={deleteIcon} />
                                  </IconButton>
                                </Box>
                              )}
                            </Box>
                          </Grid>
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        gap={2}
        sx={{ pr: 2, pb: 2, justifyContent: "flex-end", display: "flex" }}
      >
        <MDButton
          variant="outlined"
          color={"black"}
          circular={true}
          onClick={() => {
            setForm(false);
          }}
        >
          Cancel
        </MDButton>
        <MDButton
          variant="contained"
          color={"black"}
          circular={true}
          onClick={() => {
            formRef.current.submitForm();
          }}
          disabled={isSubmittingForm}
        >
          {isSubmittingForm ? "Loading..." : "Save"}
        </MDButton>
      </Grid>
    </>
  );
}
