import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import { Grid, Icon, IconButton, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GET_TRANSACTION_ORDER } from "services/order-service";
import { orderServiceClient } from "graphql/client";
import Loader from "components/MDLoader";
import { SearchContext, FilterContext } from "context";
import { useContext } from 'react';
import AgGrid from "components/MDGrid";
import MDButton from "components/MDButton";
import { Add as AddIcon } from "@mui/icons-material";
import * as XLSX from "xlsx";
import { GET_ORDER_LISTING } from "services/order-service";
import { ReactComponent as previewIcon } from "assets/images/previewIcon.svg";
import dayjs from "dayjs";

const columns = [
  {
    field: "id",
    headerName: "Order ID",
    width: 130,
    valueGetter: (params) => params?.data?.posBill?.billingDetails?.POSBILL?.[0]?.BillId,
  },
  {
    field: "orderNumber",
    headerName: "POS Order ID",
    width: 130,
    valueGetter: (params) => params?.data?.posBill?.billingDetails?.POSBILL?.[0]?.BillNo,
  },
  {
    field: "id",
    headerName: "Customer  ID",
    width: 130,
    valueGetter: (params) => params?.data?.customer?.id,
  },
  {
    field: "customerMobile",
    headerName: "Customer  Mobile",
    width: 130,
    valueGetter: (params) => params?.data?.customer?.phone,
  },
  {
    field: "email",
    headerName: "Email",
    width: 200,
    valueGetter: (params) => params?.data?.customer?.email,
  },
  {
    field: "status",
    headerName: "Status",
    width: 130,
    cellRenderer: (params) => (
      <Chip
        sx={{
          backgroundColor: params?.data?.status === "CREATED" ? "#8feba8" : "#ebaba9",
          color: params?.data?.status === "CREATED" ? "green" : "red"
        }}
        color={params?.data?.status === 'CREATED' ? 'success' : 'error'}
        label={params?.data?.status}
        variant="contained"
      />
    )
  },
  {
    field: "totalPrice",
    headerName: "Grand Total",
    width: 200,
    valueGetter: (params) => params?.data?.posBill?.billingDetails?.POSBILL?.[0]?.GrossAmt,
  },
  {
    field: "paymentMode",
    headerName: "Payment Mode",
    width: 200,
    valueGetter: (params) => params?.data?.posBill?.billingDetails?.POSBillMOP?.[0]?.MOPName,
  },
  {
    field: "genisysOrderId",
    headerName: "Ginesys Order ID",
    width: 200,
    valueGetter: (params) => params?.data?.posBill?.billingDetails?.POSBILL?.[0]?.BillGUID,
  },
  {
    field: "orderDate",
    headerName: "Order Date",
    width: 200,
    valueGetter: (params) => dayjs(params?.data?.posBill?.billingDetails?.POSBILL?.[0]?.BillDate)?.format("DD-MM-YYYY HH:mm a"),
  },
  {
    field: "email",
    headerName: "D1 - Item Promotion",
    width: 200,
    valueGetter: (params) => params?.data?.posBill?.billingDetails?.POSBILL?.[0]?.MPromoAmount,
  },
  {
    field: "email",
    headerName: "D2 - Cart Promotion",
    width: 200,
    valueGetter: (params) => params?.data?.posBill?.billingDetails?.POSBILL?.[0]?.MDiscountAmt,
  },
  {
    field: "email",
    headerName: "D3 - Coupon Discount",
    width: 200,
    valueGetter: (params) => params?.data?.posBill?.billingDetails?.POSBILL?.[0]?.MCouponAmount,
  },
  {
    field: "email",
    headerName: "D4 - Loyalty Discount",
    width: 200,
    valueGetter: (params) => params?.data?.posBill?.billingDetails?.POSBILL?.[0]?.LPDiscountAmt,
  },
  {
    field: "email",
    headerName: "Loyalty Points Earned",
    width: 200,
    valueGetter: (params) => params?.data?.posBill?.billingDetails?.POSBILL?.[0]?.LPPointsEarned,
  },
  {
    field: "email",
    headerName: "Actions",
    width: 200,
    cellRenderer: (params) => {
      return (
        <IconButton aria-label="preview" >
          <Icon component={previewIcon} fontSize="small" />
        </IconButton>
      )
    }
  }
];

function OrderLandingPage({ onRowClick, selectedBrand }) {
  const [rowCount, setRowCount] = React.useState(0);
  const { searchKeyword } = useContext(SearchContext);
  const user = JSON.parse(localStorage.getItem("userDetails") || '{}');
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });

  React.useEffect(() => {
    console.log(selectedBrand);
    if (selectedBrand) {
      refetch();
    }
  }, [selectedBrand]);

  const handleExportToExcel = () => {

    const flatten = (obj, parent = "", res = {}) => {
      for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const propName = parent ? `${parent}.${key}` : key;
          if (typeof obj[key] === "object" && obj[key] !== null) {
            if (Array.isArray(obj[key])) {
              obj[key].forEach((item, index) => {
                flatten(item, `${propName}[${index}]`, res);
              });
            } else {
              flatten(obj[key], propName, res);
            }
          } else {
            res[propName] = obj[key];
          }
        }
      }
      return res;
    };

    const flattenedData = data?.ordersGet?.results?.map((item) =>
      flatten(item)
    );
    const worksheet = XLSX.utils.json_to_sheet(flattenedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, "data.xlsx");
  };

  const { loading, error, data, refetch } = useQuery(GET_ORDER_LISTING, {
    client: orderServiceClient,
    variables: {
      take: 10000,
      skip: 0,
      filter: {
        brandId: selectedBrand
      }
    },
    fetchPolicy: 'network-only', // Ignore cache for this query
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setRowCount(data?.ordersGet?.results.length);
    },
  });

  React.useEffect(() => {
    if (data?.ordersGet?.total !== undefined) {
      setRowCount(data.ordersGet.results.length);
    }
  }, [data?.ordersGet?.results?.length]);
  console.log(data)
  if (loading) return <Loader />;
  if (error) return <p>Error: {error.message}</p>;

  const handleChangePage = (event, newPage) => {
    setPaginationModel((prevModel) => ({
      ...prevModel,
      page: newPage,
    }));
  };

  return (
    <Grid container spacing={1} mt={2} justifyContent={"space-between"}>
      <Typography sx={{ fontFamily: "montserrat", color: "#000", fontWeight: "700" }} ml={1}>Orders</Typography>
      <MDButton
        type="button"
        variant="outlined"
        color="black"
        sx={{
          fontFamily: "Montserrat",
          fontSize: "15px",
          fontWeight: 400,
          ml: 2
        }}
        circular={true}
        startIcon={<AddIcon />}
        onClick={() => {
          handleExportToExcel();
        }}
      >
        Export
      </MDButton>
      <Grid item xs={12} style={{ backgroundColor: "white" }}>
        <div className="ag-theme-alpine" style={{ height: '90vh', width: '100%' }}>
          <AgGrid
            gridProps={{
              rowHeight: 60,
              defaultColDef: {
                editable: false,
                filter: true,
                flex: 1,
                minWidth: 200,
              },
              pivotMode: false,
              sideBar: {
                toolPanels: ['columns', 'filters'],
              },
              enableRangeSelection: true,
              onRowClicked: onRowClick, // Use onRowClicked event
            }}
            queryData={data?.ordersGet?.results}
            columns={columns}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default OrderLandingPage;
