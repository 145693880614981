import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ReportTables from "./reportTables";
import {
  headerEventReport,
  headerGMVREPORT,
  headerReturnAndRefund,
  headerSalesReport,
  headerStockReport,
  headerFootfallReport,
} from "./header";
import ExcelToAgGrid from "./visualisation";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ReportTabs({
  tabValue,
  setTabValue,
  gmvDataRows,
  salesDataRows,
  returnRefundDataRows,
  stockDataRows,
  eventDataRows,
  footfallDataRows
}) {
  const handleChange = (event, newValue) => {
    setTabValue(5 || newValue);
  };

  return (
    <>
      <Tabs
        sx={{ px: 2 }}
        value={tabValue}
        onChange={handleChange}
        aria-label="ant example"
      >
        {/* <Tab label="GMV Reports" {...a11yProps(0)} />
        <Tab label="Sales Reports" {...a11yProps(1)} />
        <Tab label="Return and Refund Status" {...a11yProps(2)} />
        <Tab label="Stock Report" {...a11yProps(3)} />
        <Tab label="Event Report" {...a11yProps(4)} /> */}
        <Tab label="Summary Report" {...a11yProps(5)} />
        {/* <Tab label="Footfall" {...a11yProps(6)} /> */}
      </Tabs>
      {/* <CustomTabPanel value={tabValue} index={0}>
        <ReportTables rowData={gmvDataRows} columnDefs={headerGMVREPORT} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <ReportTables rowData={salesDataRows} columnDefs={headerSalesReport} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        <ReportTables
          rowData={returnRefundDataRows}
          columnDefs={headerReturnAndRefund}
        />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={3}>
        <ReportTables rowData={stockDataRows} columnDefs={headerStockReport} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={4}>
        <ReportTables rowData={eventDataRows} columnDefs={headerEventReport} />
      </CustomTabPanel> */}
      <CustomTabPanel value={tabValue} index={5}>
        <ExcelToAgGrid />
      </CustomTabPanel>
      {/* <CustomTabPanel value={tabValue} index={6}>
        <ReportTables rowData={footfallDataRows} columnDefs={headerFootfallReport} />
      </CustomTabPanel> */}
    </>
  );
}
