export const getFlatIdMap = (obj) => {
    const indexMap = {};

    function traverse(obj, parentId) {
        if (Array.isArray(obj)) {
            obj.forEach(item => traverse(item, parentId));
        } else if (typeof obj === 'object' && obj !== null) {
            if ('id' in obj) {
                indexMap[obj.id] = {...obj, parentId};
            }else if ('uuid' in obj) {
                indexMap[obj.uuid] = obj;
            }else if ('batchCode' in obj) {
                indexMap[obj.batchCode] = obj;
            }else if ('code' in obj) {
                indexMap[obj.code] = obj;
            }
            
            Object.values(obj).forEach(value => traverse(value, obj.id));
        }
    }

    traverse(obj, null);
    return indexMap;
}